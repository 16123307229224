var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operadoras"},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"700","outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"text-h5 grey--text"},[_vm._v("Operadoras")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-h5 grey--text",attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1),_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"9"}},[_c('v-text-field',{staticClass:"mx-4 ",attrs:{"label":"Buscar","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4 mt-4",attrs:{"fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.showModal({})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Adicionar")])])],1)],1),_c('v-simple-table',{attrs:{"height":"200","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Nome ")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v(" CNPJ ")]),_c('th',{staticClass:"text-left",attrs:{"width":"15%"}},[_vm._v(" Registro ANS ")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v(" Ações ")])])]),_c('tbody',_vm._l((_vm.filteredoperadoras),function(operadora){return _c('tr',{key:operadora.id},[_c('td',[_vm._v(_vm._s(operadora.nome))]),_c('td',[_vm._v(_vm._s(operadora.cnpj))]),_c('td',[_vm._v(_vm._s(operadora.registro_ans))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 pa-0 white--text",attrs:{"color":"red","fab":"","small":"","text":""},on:{"click":function($event){return _vm.DeleteRecord(operadora)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 pa-0 white--text",attrs:{"color":"primary","fab":"","text":"","small":""},on:{"click":function($event){return _vm.showModal(operadora)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)])}),0)]},proxy:true}])})],1),_c('OperadoraForm',{attrs:{"payload":_vm.payload},on:{"close":function($event){_vm.payload.visible=false},"save":_vm.save}}),_c('Confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }