var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lotes"},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"800","outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"text-h5 grey--text"},[_vm._v("Lotes")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-h5 grey--text",attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1),_c('v-row',[_c('v-col',{staticClass:"text-left mt-0 pt-0",attrs:{"cols":"9"}},[_c('v-text-field',{staticClass:"mx-4 mt-0",attrs:{"label":"Buscar","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4 mt-2 mb-1",attrs:{"fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.showModal({})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Adicionar")])])],1)],1),_c('v-simple-table',{attrs:{"height":"267","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"width":"5%"}},[_vm._v(" Lote ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Operadora ")]),_c('th',{staticClass:"text-left",attrs:{"width":"8%"}},[_vm._v(" Tipo Guia ")]),_c('th',{staticClass:"text-left",attrs:{"width":"8%"}},[_vm._v(" Data Inicial ")]),_c('th',{staticClass:"text-left",attrs:{"width":"8%"}},[_vm._v(" Data Fim ")]),_c('th',{staticClass:"text-left",attrs:{"width":"30%"}},[_vm._v(" Ações ")])])]),_c('tbody',_vm._l((_vm.filteredlotes),function(lote){return _c('tr',{key:lote.id},[_c('td',[_vm._v(_vm._s(lote.numero_lote))]),_c('td',[_vm._v(_vm._s(lote.operadora_nome))]),_c('td',[_vm._v(_vm._s(lote.tipo_guia_tipo))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(lote.data_inicial)))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(lote.data_final)))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"red","icon":"","small":"","text":""},on:{"click":function($event){return _vm.DeleteRecord(lote)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"primary","icon":"","small":"","text":""},on:{"click":function($event){return _vm.showModal(lote)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"primary","icon":"","small":"","text":""},on:{"click":function($event){return _vm.selectGuia(lote)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-view-dashboard-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Guias")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"primary","text":"","icon":"","small":""},on:{"click":function($event){return _vm.showModalEmiteProtocolo(lote.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-text-box-check-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Emitir protocolo")])]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"primary","text":"","icon":"","small":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(" mdi-file-code-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Gerar xml")])])]}}],null,true)},[_c('v-list',_vm._l((_vm.xmlVersions),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.viewXml(lote, _vm.xmlVersions[index])}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"primary","icon":"","small":"","text":""},on:{"click":function($event){return _vm.showModalImprimirGuias(lote.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-printer-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir Guia")])])],1)])}),0)]},proxy:true}])})],1),_c('LoteForm',{attrs:{"payload":_vm.payload},on:{"close":function($event){_vm.payload.loteForm.visible=false},"save":_vm.save}}),_c('GuiaConsulta',{attrs:{"payload":_vm.payload},on:{"close":function($event){_vm.payload.guiaConsulta.visible=false},"save":_vm.save}}),_c('GuiasSpSadt',{attrs:{"payload":_vm.payload},on:{"close":function($event){_vm.payload.guiasSpSadt.visible=false},"save":_vm.save}}),_c('RelatorioForm',{attrs:{"payload":_vm.payload},on:{"close":function($event){_vm.payload.visible=false}}}),_c('XmlViewForm',{attrs:{"payload":_vm.payload},on:{"close":function($event){_vm.payload.xml.visible=false}}}),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_vm._v(" Fechar ")])]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" SP/SADT ainda indisponível ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }