<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5 grey--text">Lote</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="dialog = false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>   
          </template>
          <span>Fechar</span>
          </v-tooltip>                 
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"
                sm="6"
                md="4"

              >
              <v-text-field
                label="Lote"
                required
                type="number"
                maxlength="6"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                :disabled="true"
                v-model="payload.lote.numero_lote"
              ></v-text-field>

              </v-col>

              <v-col
                cols="12"
                sm="8"
                md="8"
              >
              <v-select
                  class="ml-4 "
                  v-model="payload.lote.prestador_operadora_obj"
                  :items="payload.prestadorOperadoras"
                  label="Operadora"
                  item-text="operadora_nome"
                  item-value="id"
                  :return-object = "true"
                  :disabled="payload.lote.id != null "
              ></v-select>                     

              </v-col>
              </v-row>
              <v-row>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="initialDateFormatted"
                      label="Data inicial"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @blur="initialDateBlur()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="payload.lote.data_inicial"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="finalDateFormatted"
                      label="Data final"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @blur="finalDateBlur()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="payload.lote.data_final"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              
              <v-col
                cols="12"
                sm="8"
                md="8"
              >
              <v-select
                  class="ml-4 "
                  v-model="payload.lote.tipo_guia_obj"
                  :items="payload.tipoGuia"
                  label="Tipo guia"
                  item-text="tipo"
                  item-value="id"
                  :return-object = "true"
                  
              ></v-select>                     

              </v-col>


            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['payload'],
  data() {
    return {
      
      //initialDate  :null,
      //finalDate : null,
      initialDateFormatted: null,
      finalDateFormatted: null,
      menu2: false,      
      menu1: false,
    }
  },
  watch: {
    'payload.lote.data_inicial' () {
      this.initialDateFormatted = this.formatDate(this.payload.lote.data_inicial)
    },
    'payload.lote.data_final' () {
      this.finalDateFormatted = this.formatDate(this.payload.lote.data_final)
    },
  },
  methods: {
      save() {
          
          
          this.$emit('save');
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month,  year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      initialDateBlur() {
        //this.payload.lote.data_inicial = this.parseDate(this.initialDateFormatted);
        //this.payload.lote.data_inicial = this.payload.lote.data_inicial
      },
      finalDateBlur() {
        //this.payload.lote.data_final = this.parseDate(this.finalDateFormatted);
        //this.payload.lote.data_final = this.payload.lote.data_final
      },
  },
  computed: {

    dialog: {
      get () {
        return this.payload.loteForm.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>