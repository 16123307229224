<template>
  <div class="operadoras">
    <v-card
        class="mx-auto"
        max-width="700"
        outlined
    >
        <v-card-title>
          <span class="text-h5 grey--text">Operadoras</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="close()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

            </template>
            <span>Fechar</span>
            </v-tooltip>


          
        </v-card-title>
        <v-row>
        <v-col class="text-left" cols="9">
            
            <v-text-field
                label="Buscar"
                class="mx-4 "
                  
                v-model="search"
                append-icon="mdi-magnify"
                
                single-line
                hide-details
            ></v-text-field>
        </v-col>
        <v-col class="text-right">
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">    
            <v-btn
                class="mr-4 mt-4"
                fab
                dark
                color="primary"
                
                @click="showModal({})" 
                v-bind="attrs"
                v-on="on"
                >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>
            </template>
            <span>Adicionar</span>
        </v-tooltip>
        </v-col>
        </v-row>
        <v-simple-table
            height = "200" 
            fixed-header
            dense
        >
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">
                    Nome
                </th>
                <th class="text-left"  width= "20%">
                    CNPJ
                </th>
                  <th class="text-left"  width= "15%">
                    Registro ANS
                </th>
                <th class="text-left" width= "20%">
                    Ações
                </th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="operadora in filteredoperadoras" :key="operadora.id" >
                    <td>{{operadora.nome}}</td>
                    <td>{{operadora.cnpj}}</td>
                    <td>{{operadora.registro_ans}}</td>
                    <td>   
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           
                            color="red"
                            class="ma-0 pa-0 white--text"
                            fab
                            small
                             text
                             @click="DeleteRecord(operadora)"
                             v-bind="attrs"
                             v-on="on"
                            >
                            <v-icon >
                                mdi-close
                            </v-icon>
                     
                        </v-btn>
                        </template>
                        <span>Excluir</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 pa-0 white--text"
                            fab
                            text
                            small
                            @click="showModal(operadora)"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon >
                            mdi-pencil
                        </v-icon>
                        </v-btn> 
                        </template>
                        <span>Editar</span>
                         </v-tooltip>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>

    <OperadoraForm :payload="payload" @close="payload.visible=false" @save="save" />
    <Confirm ref="confirm"></Confirm>
  </div>
  
</template>
<script>

import { getAPI } from '../../axios-api'
import router from '../router'
import { mapState } from 'vuex'
import OperadoraForm from '@/components/OperadoraForm'
import Confirm from '@/components/Confirm'
export default {
    data() {
        return {
    
            opearador : {},
            operadoras: [] ,
            search : '',
            payload : {
                visible : false,
                operadora : {}
            }    
            
            
            
        }
    },
    components: {OperadoraForm, Confirm},
    created: function () {
        this.GetRecords();
    }, 

    computed : {
        
        filteredoperadoras : function() {
            return this.operadoras.filter((record) => {
                return record.nome.toLowerCase().trim().match(this.search.toLowerCase().trim())
            })
        },
        ...mapState(['APIData'])
    },
    methods: {
        close() {
            router.push({name: 'home'}).catch(err => {console.log('operadoras.close() exception: ',err)});
        }, 
        save(payload) {
            
            payload.visible=false
            this.operadora = payload.operadora
            // console.log(this.operadora)
            this.CheckRecord();
        },
        showModal(operadora) {
            this.payload.operadora= JSON.parse(JSON.stringify(operadora)); 
            
            this.payload.visible=true
        },
        CheckRecord() {
            if( this.operadora.id === undefined ) {
                this.CreateRecord();        
            } else {
                this.EditRecord();
            }
        },
        GetRecords() {
            
            
            getAPI.get('/api/operadoras/')
            .then(response => {
            this.$store.state.APIData = response.data
            this.operadoras= response.data
            
            })
            .catch(err => {
                console.log('operadoras.GetRecords() exception: ', err)
            })
            
        },
        CreateRecord() {
            getAPI.post("/api/operadoras/", this.operadora)
                .then(() => {
                    
                    //this.profissionais.push( res.data);
                    this.GetRecords();
                    this.operadora = {};
                })
                .catch(function (error) {
                    console.log('operadoras.CreateRecord() exception: ',error);
                });
                
        },
        EditRecord() {

            getAPI.put(`/api/operadoras/${this.operadora.id}/`, this.operadora)
                .then(() => {
                    
                    //this.profissionais.push( res.data);
                    this.GetRecords();
                    this.operadora = {};
                })
                .catch(function (error) {
                    console.log('operadoras.EditRecord() exception: ', error);
                });
                

        },
        async DeleteRecord(operadora) {
            if (await this.$refs.confirm.open('Excluir ' + operadora.nome, 'Confirma exclusão?', { color: 'red' })) {
                getAPI.delete(`/api/operadoras/${operadora.id}/`)
                    .then(() => {
                        
                        
                        this.GetRecords();
                        
                    })
                    .catch(function (error) {
                        console.log('operadoras.DeleteRecord() exception: ', error);
                    });

                }
            else {
                   // cancel
            }
                
            

        }
    }
    
}
</script>