<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"

      
    >

  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Email
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Validação
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step 
        :complete="e1 > 3"
        step="3">
        Nova Senha
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step 
        :complete="e1 > 3"
        step="4">
        Finalizado
      </v-stepper-step>

    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card
          height="300px"
        >
        <p>Esqueceu a senha?<br><br>
        Informe seu endereço de email abaixo. 
        Enviaremos um email com instruções para redefinir a senha.</p><br>
        <v-col
          cols="12"
          sm="12"
          md="12"
          class="ml-0 pl-0"
        >
          <v-text-field
          :error-messages="emailError"
          label="Email"
          maxlength="254"
          v-model="email"
          required
          :rules="[(v) => (!!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) )|| 'Email inválido']"
          outlined
          clearable
          ></v-text-field>
        </v-col>

        </v-card>

        <v-btn
          color="primary"
          @click="enviarEmail()"
          class="mt-4"
        >
          Próximo
        </v-btn>

        <v-btn 
          class="mt-4"
          @click="close()"
          text >
          Cancelar
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card
          height="300px"
          
        >
        <p>
        Por favor cole abaixo o código recebido no email.</p><br>
        <v-col
          cols="12"
          sm="12"
          md="12"
          class="ml-0 pl-0"
        >
          <v-text-field
          
          label="Código de validação"
          maxlength="254"
          v-model="token"
          required
          :error-messages="codigoError"
          :rules="[(v) => !!v|| 'Campo obrigatório']"
          outlined
          clearable
          ></v-text-field>
        </v-col>
        
        </v-card>

        <v-btn
          color="primary"
          @click="validateToken()"
          class="mt-4"

        >
          Próximo
        </v-btn>

        <v-btn 
          @click="close()"
          text
          class="mt-4"
        >
          Cancelar
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card
          height="300px"
          
        >
        <p>
        Por favor informe a nova senha.</p><br>
        <v-col
          cols="12"
          sm="12"
          md="12"
          class="ml-0 pl-0"
        >
          <v-text-field
          
          label="Nova senha"
          maxlength="30"
          v-model="senha"
          required
          :rules="[(v) => !!v && v.length>=6|| 'Mínimo 6 characteres']"
          outlined
          clearable
          :error-messages="passwordError"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"

          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="12"
          class="ml-0 pl-0"
        >
          <v-text-field
          :error-messages="passwordError"
          label="Confirmação da senha"
          maxlength="30"
          v-model="senha1"
          required
          :rules="[(v) => !!v && v.length>=6|| 'Mínimo 6 characteres']"
          outlined
          clearable
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"

          ></v-text-field>
        </v-col>
        
        

        </v-card>

        <v-btn
          color="primary"
          @click="validarSenhas()"
          class="mt-4"

        >
          Próximo
        </v-btn>

        <v-btn 
          @click="close()"
          text
          class="mt-4"
        >
          Cancelar
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="4">
        <v-card
          height="300px"
          
        >
        <p>Senha recuperada com sucesso.</p><br>

        </v-card>

        <v-btn
          color="primary"
          @click="close()"
          class="mt-4"

        >
          Fechar
        </v-btn>

      </v-stepper-content>


    </v-stepper-items>
  </v-stepper>    
    </v-dialog>
  </v-row>
  
</template>

<script>
import { getAPI } from '../../axios-api'

export default {
    props: ['payload'],
    data() {
        return {
            tipo_ErrorMessages : [],
            emailError : [],
            show1 : false,
            dialog : false,
            e1:1,
            codigoError: [],
            email : null,
            token: null,
            passwordError : [],
            senha : null,
            senha1: null
        }
    },
    watch: {
        'payload.visible' :{
            handler(newVal /*, oldVal*/) {
            
            if(newVal) {
               

                this.dialog = true
            }
            else {
                this.$emit('close')
                this.dialog= false
                }
            

            },
            deep: true
        },
    },

    methods: {
        close() {
          this.payload.visible=false;
          this.e1=1;
        },
        validarSenhas() {
          this.passwordError =[];
          if (!this.senha.trim() || !this.senha1.trim()) {
            this.passwordError.push('Por favor informe a nova senha')
            return
          }
          if (this.senha.trim() != this.senha1.trim()) {
            this.passwordError.push('As senhas são diferentes')
            return
          }

          if (this.senha.trim().length < 6 ) {
            this.passwordError.push('Por favor informe uma senha com no mínimo 6 caracteres')
            return
          }
          
          let dados = { 'password': this.senha.trim()};

          // console.log(dados)
          getAPI.patch(`/api/password-reset/${this.token}/`, JSON.stringify(dados))
          .then(() => {
            this.e1 = 4;
          })
          .catch( err => {
            console.log('validacao token exception: ', err)
            this.passwordError.push('Senha inválida. Por favor tente novamente com no mínimo 6 caracteres')
          })


          
        },
        validateToken() {

          this.codigoError = [];
          if(!this.token) {
            this.codigoError.push('Por favor informe o código recebido por email')
            return;
          }
          if(this.token.split('/').lenght<2) {
            this.codigoError.push('Código inválido')
            return;

          }

          let dados = { 'encoded_pk': this.token.split('/')[0], 'token' : this.token.split('/')[1]};

          // console.log(dados)
          getAPI.post("/api/validate-user-token/", JSON.stringify(dados))
          .then(() => {
            this.e1 = 3;
          })
          .catch( err => {
            console.log('validacao token exception: ', err)
            this.codigoError.push('Código inválido')
          })
        },

        enviarEmail() {
          this.emailError = [];
          if (!this.email) {
            this.emailError.push('Por favor informe o email');
            return;
          }

          if(!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) ) {
            this.emailError.push('Email invalido');
            return;

          }


          let dados = { 'email': this.email};

          // console.log(dados)
          getAPI.post("/api/password-reset-email/", JSON.stringify(dados))
          .then(() => {
            this.e1 = 2;
          })
          .catch( err => {
            console.log('recuperacao de senha exception: ', err)
          })
        },
        save() {
            this.$emit('save');
        },

    },


}


</script>