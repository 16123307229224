<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"

      
    >
      <v-card >
        <v-card-title>
          <span class="text-h5 grey--text">Usuário</span>
          <v-spacer></v-spacer>
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="close()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>          
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="userForm" class="mx-2" lazy-validation>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Email"
                  v-model="payload.usuario.email"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  type="text"
                  maxlength="254"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  @input="(val) => (payload.usuario.email = payload.usuario.email.toLowerCase())"
                ></v-text-field>
              </v-col>

              <v-col cols="12"
                sm="6"
                md="6"

              >
                <v-select
                  v-model="payload.usuario.tipo_usuario"
                  :items="[{id:1, text:'Administrador'}, {id:2, text: 'Básico'}]"
                  label="Tipo de Usuário"
                  item-text="text"
                  item-value="id"
                  :menu-props="{ bottom: true, offsetY: true }"
                ></v-select>  
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
                v-if="payload.usuario.id == undefined"
              >
                <v-text-field
                  label="Senha incial"
                  v-model="payload.usuario.senha"
                  
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  label="Usuário"
                  v-model="payload.usuario.username"
                  @input="(val) => (payload.usuario.username = payload.usuario.username.toLowerCase())"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-container
                class="py-0"
                fluid
                
                >
                <v-switch
                    :label="payload.usuario.is_active ? 'Ativo' : 'Inativo'"
                    v-model="payload.usuario.is_active"
                    dense
                    hide-details  
                    class="my-0 py-0"
                ></v-switch>
                </v-container>                      

              </v-col>

            </v-row>
            </v-form>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  
</template>

<script>

export default {
    props: ['payload'],
    data() {
        return {
            tipo_ErrorMessages : [],
            show1 : false,
            dialog : false
        }
    },
    watch: {
        'payload.visible' :{
            handler(newVal /*, oldVal*/) {
            
            if(newVal) {
                if(!this.payload.usuario.id) {
                    if (this.$refs['userForm']) {
                        this.$refs.userForm.resetValidation()
                    }         
                   
                    this.payload.usuario.is_active = true;
                    
                
                }

                this.dialog = true
            }
            else {
                this.$emit('close')
                this.dialog= false
                }
            

            },
            deep: true
        },
    },

    methods: {
        save() {
            this.$emit('save');
        },

        close() {
            this.payload.visible = false
        },        
    },


}


</script>