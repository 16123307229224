<template>
    <div class="guia-consulta" >
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1100"
    >

        <v-card>
        <v-card-title>
          <span class="text-h5 grey--text">Guias SP/SADT Lote {{ payload.lote.numero_lote}} - {{ payload.lote.operadora_nome}}</span>
          <v-spacer></v-spacer>
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="payload.guiasSpSadt.visible = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>     

        <v-card-text>
        <v-row class="mb-3">
        <v-col class="text-left" cols="9" >
            
            <v-text-field
                label="Buscar"
                class="mx-4 "
                  
                v-model="search"
                append-icon="mdi-magnify"
                
                single-line
                hide-details
            ></v-text-field>
        </v-col>

        </v-row>
        
        <v-simple-table 
        height = "300" 
        fixed-header
        dense
        >
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left" width= "10%">
                    Guia
                </th>
                <th class="text-left">
                    Beneficiario
                </th>
                 <th class="text-left" width= "15%">
                    Profissional
                </th>

                <th class="text-left" width= "20%">
                    Ações
                    <v-btn
                        class="ml-10 mb-1"
                        small
                        fab
                        dark
                        color="primary"
                        
                        @click="showModal({})" 
                        >
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>                    
                </th>

            
            </tr>
            </thead>
            <tbody>
                <tr v-for="guia in filteredGuias" :key="guia.id" > 
                    <td>{{guia.numeroGuiaPrestador}}</td>

                    <td class="maxSize100pxOrEllipsesTD"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{guia.nomeBeneficiario}}</span>
                        </template>
                        <span>{{guia.nomeBeneficiario}}</span>
                        </v-tooltip>                    
                    </td>

                    <td class="maxSize100pxOrellipsesTD">
                        
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{guia.nomeProfissional}}</span>
                        </template>
                        <span>{{guia.nomeProfissional}}</span>
                        </v-tooltip>                    
                    
                    </td>



                    <td>   
                        <v-btn
                           
                            color="red"
                            class="ma-0 white--text"
                            fab
                             text
                             small
                            >
                            <v-icon >
                                 mdi-close
                            </v-icon>
                     
                        </v-btn>
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            fab
                            text
                            small
                            @click="showModal(guia)"
                            
                        >
                        <v-icon >
                            mdi-pencil
                        </v-icon>
                        </v-btn>
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            fab
                            text
                            small
                            @click="showModalPDF(guia)"
                            
                        >
                        <v-icon >
                            mdi-printer-eye
                        </v-icon>
                        </v-btn> 
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        </v-card-text>
        </v-card>
    </v-dialog>
   
     <GuiaSpSadtForm 
        :payload="payload" 
        @close="closeGuiaSpSadtForm" 
        @save="save" />
    <PrintGuiaForm
        :payload="payload" 
        @close="close" 
     />

    </div>

 
</template>

<script>
import { getAPI } from '../../axios-api'
import GuiaSpSadtForm from '@/components/GuiaSpSadtForm'
import PrintGuiaForm from '@/components/PrintGuiaForm'

export default {
    props: ['payload'],

    components: {GuiaSpSadtForm, PrintGuiaForm},

    data() {
        return {
            search : '',
            dialog : false
        }
    },
    
    computed : {
        
        filteredGuias : function() {
            return this.payload.guiasSpSadt.guias.filter((record) => {
                return record.nomeBeneficiario.toLowerCase().trim().match(this.search.toLowerCase().trim())
            })
        },
        
    },
    
    watch: {
        'payload.guiasSpSadt.visible' :{
            handler(newVal /*, oldVal*/) {

                if(newVal) {
                    this.dialog = true
                }
                else {
                    this.dialog= false
                }
                this.getGuias(this.payload.lote.id)

            },
            deep: true
        },
    },

    methods: {
        async getGuias(loteId) {
            
            try{
                let response = await getAPI.get(`/api/guia-sp-sadt/?lote=${loteId}`)
                this.payload.guiasSpSadt.guias = JSON.parse(JSON.stringify(response.data));
            }catch(err){
                console.log('guiaconsulta.getGuias() exception: ', err)
            }            
            
        } ,

        closeGuiaSpSadtForm() {
            this.getGuias(this.payload.lote.id)
        },
        close() {
            this.payload.guiasSpSadt.visible=false
            this.getGuias(this.payload.lote.id)
        },
        save() {
            //this.payload.GuiaConsulta.visible=false;
            
            /*
            if(this.savedIndex) {
                this.$set(this.payload.prestadorOperadoras, this.savedIndex, this.payload.prestadorOperadorasTabForm.operadora)
            }
            this.$emit('save');
            */
        },
        showModalPDF(guia){
            this.payload.guiaPDF.guia = JSON.parse(JSON.stringify(guia)); 
            this.payload.guiaPDF.visible=true;
        },
        showModal(guia) {
            
            
            this.payload.guiasSpSadt.form.guia = JSON.parse(JSON.stringify(guia)); 
            this.payload.guiasSpSadt.form.visible=true;
            
            
        },
        

    },
    
}
</script>

<style scoped>

 .maxSize100pxOrEllipsesTD /deep/   {
 max-width: 100px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
</style>