import Vue from 'vue'
import VueRouter from 'vue-router'
import Profissionais from '../views/Profissionais.vue'
import Prestadores from '../views/Prestadores.vue'
import Operadoras from '../views/Operadoras.vue'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Home from '../views/Home.vue'
import Tabelas from '../views/Tabelas.vue'
import Lotes from '../views/Lotes.vue'
import Relatorios from '../views/Relatorios.vue'
import Usuarios from '../views/Usuarios.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/profissionais',
    name: 'profissionais',
    component: Profissionais,
    meta: {
        requiresLogin: true
      }
  },
  {
    path: '/prestadores',
    name: 'prestadores',
    component: Prestadores
  },
  {
    path: '/operadoras',
    name: 'operadoras',
    component: Operadoras,
    meta: {
      requiresLogin: true
    }

  },
  {
    path: '/tabelas',
    name: 'tabelas',
    component: Tabelas
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/faturamento',
    name: 'faturamento',
    component: Lotes
  },
  {
    path: '/relatorios',
    name: 'relatorios',
    component: Relatorios
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Usuarios
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
