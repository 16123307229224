<template>
    <nav  v-if="this.$store.getters.loggedIn">
        <v-navigation-drawer
        v-model="drawer"
        app
        
        >
        <!-- class="indigo" -->
<!--         
        <div class="text-uppercase grey--text mx-6 mt-5">
            <span class="font-weight-light">Contas</span>
            <span>Médicas</span>
        </div> -->

        <v-img
            class="mx-6 mt-5"
            lazy-src="../assets/logo-contas-medicas.png"
            max-height="100"
            max-width="200"
            src="../assets/logo-contas-medicas.png"
        ></v-img> 
        <v-list class=" mt-2">
            <v-list-item v-for="link in links" :key="link.text"  :to="link.route" >
                
                
                <v-list-item-action>
                    <v-icon class="grey--text text--darken-4 ml-2 mt-2">{{ link.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="grey--text text--darken-4 mt-2 ">{{ link.text }}</v-list-item-title>
                </v-list-item-content>
                <br>
            </v-list-item>
        </v-list>
        </v-navigation-drawer>
        
        <v-app-bar flat app>
        <v-app-bar-nav-icon  class="grey--text" @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title class="text-uppercase indigo--text text-darnken-4 font-italic" v-if="!drawer">
                <span class="font-weight-light">Contas</span>
                <span class="font-weight-bold">Médicas</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- <v-menu offset-y open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                <v-btn  text color="grey"
                  v-bind="attrs"
                    v-on="on"
                > 
                    <v-icon left>expand_more</v-icon>
                    <span>{{ prestadorAtual}}</span>
                </v-btn>
                </template>
                <v-list>
                    <v-list-item
                    
                    v-for="prestador in $store.getters.prestadores" 
                    :key="prestador.id"
                    
                    >
                    <v-list-item-title class="tile ">{{ prestador.nome }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu> -->
            
            <v-col cols="auto"  class = "d-flex justify-end">
        
                <v-select
                    
                    class="mt-3 grey--text v-select-prestadores"
                    v-model="select"
                    :items="this.$store.getters.prestadores"
                    light
                    item-text="nome"
                    item-value="id"
                    @input = "onChangeSelection"
                    
                    append-icon="expand_more"
                    
                    flat
                    solo
                    background-color = "grey lighten-4"
                    :menu-props="{ bottom: true, offsetY: true }"         
                >
                
                </v-select>  
             </v-col>      

             <!--
                prepend-icon = 'expand_more'    
                                  background-color = "grey lighten-4"
                     :style="{  'display': 'flex'
                                        'justify-content': 'flex-end'
                                    }"
                   -->
            <!-- <v-btn plain color = "grey">
                <span>Sign Out</span>
                <v-icon right>exit_to_app</v-icon>
            </v-btn> -->

        </v-app-bar>
        

    </nav>

</template>

<script>
//import { getAPI } from '../../axios-api'
import router from '../router'
export default {
    data() {
        return {
            prestadorAtual: null,
            drawer : false,
            links : [
                {icon : 'mdi-bank-outline', text: 'Operadoras' , route: '/operadoras'},
                {icon : 'mdi-heart-pulse', text: 'Prestadores' , route: '/prestadores'},
                {icon : 'mdi-account-multiple', text: 'Profissionais' , route: '/profissionais'},
                {icon : 'mdi-file-document-multiple-outline', text: 'Tabelas' , route: '/tabelas'},
                {icon : 'mdi-view-dashboard', text: 'Faturamento' , route: '/faturamento'},
                {icon : 'mdi-script-text-outline', text: 'Relatórios' , route: '/relatorios'},
                {icon : 'mdi-power', text: 'Encerrar' , route: '/logout'}, // mdi-exit-run
            ],

            select : null,
            //items : []
            items : [
                {
                    title: 'Clinica 1'
                    
                },
                {
                    title: 'Clinica 2'
                    
                },
                {
                    title: 'Clinica 3'
                    
                },                
            ]

            
        }
    },
    mounted() {
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            this.select = this.getSelected(this.$store.state.idPrestadorSelecionado);
            //let $ref = this.$refs['vselect']
            //$ref.style.padding = '1px'
            //this.$refs.vselect.style.padding = '1px' 
        })
    },
    watch: {
        '$store.state.prestadores': function() {
            
            this.select = this.getSelected(this.$store.state.idPrestadorSelecionado);
            
        }
    },
    /*
    created: function () {
        this.GetRecords();
        
    },
    */ 
    /*
    watch: {
    items: {
      handler: function () {
            console.log('whatch :lista mudou')
            this.select = this.getSelected(this.$store.state.idPrestadorSelecionado);
            console.log('watch', this.select )
            console.log('state.idPrestadorSelecionado', this.$store.state.idPrestadorSelecionado);        
      },
      deep: true
    }
  },
  */
  /*
    watch: {
        'this.items': function() {
            console.log('whatch :lista mudou')
            this.select = this.getSelected(this.$store.state.idPrestadorSelecionado);
            console.log('watch', this.select )
            console.log('state.idPrestadorSelecionado', this.$store.state.idPrestadorSelecionado);

        },
      deep: true
    },*/
    methods: {
        getSelected(lastSelection) {
            var result = null;

            var selected = this.$store.getters.prestadores.find(function(current) {
                if(current.id == lastSelection)
                    return true;
            });

            if (selected != null) {
                result =  lastSelection;
                this.prestadorAtual = selected.nome
            }
            else {
                if(this.$store.getters.prestadores.length) {
                    result = this.$store.getters.prestadores[0].id;
                    this.prestadorAtual = this.$store.getters.prestadores[0].nome;
                }
            }
            
            return result;
        },
        onChangeSelection() {
            
             this.$store.dispatch('updatePrestador', this.select)
              router.push({name: 'home'}).catch(err => {
                console.log('navbar.onChangeSelection() exception: ',err)
            });
        },

        /*
        GetRecords() {
            
            
            getAPI.get('/api/prestadores/')
            .then(response => {
                this.$store.state.APIData = response.data
                this.items= response.data
                console.log('navbar.getrecords', response.data)
                
            })
            .catch(err => {
                console.log('navbar error:', err)
            })
            
        },*/
    }
    
}


/*
<style scoped>
.v-select {
    width: 100%;
    justify-content: right;
}
</style>

<style>
  #selectPrestador .v-select.select.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
  padding-right: 1px
}
.v-select >>> .fit {
  width: min-content;
  padding: 0 !important;
  justify-content: right;
}
$text-field-padding: 0px 0 0px !default;
$text-field-append-prepend-margin: 1px !default;

</style>
*/
</script>

<style scoped >

.v-select-prestadores /deep/ .v-select__selection {
padding: 0 !important;
width: 100%;
display : flex;
justify-content: flex-end;
}

</style>