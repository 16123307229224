<template>
  <v-app class="grey lighten-4">
    <Navbar />
    <v-main class="grey lighten-4">
      <router-view></router-view>
      <Reconnecting></Reconnecting>
      <loader></loader>
      
    </v-main>      
  </v-app>
</template>

<script>

import Navbar from './components/Navbar';
import Loader from './components/Loader';
import Reconnecting from './components/Reconnecting';
export default {
  name: 'App',

  components: {
    Navbar, Loader, Reconnecting
  },

  data: () => ({
    //
  }),
};
</script>
