<template>
     <!-- "1200px" -->
    <v-dialog
      v-model="dialog"
      persistent
        :fullscreen= "dialogFullScreen"
       max-width="1200px"
        hide-overlay
      class="ma-0 pa-0 noscroll"
    >
    <!-- that worked 
    <iframe :src="pdfsrc" 
                type="application/pdf"
                
                
                width="100%" height="98%">
                </iframe>
    -->
        <!-- style="position:absolute;right:0; top:0; bottom:0; width:100%" -->
        <v-card height="100%"  class="ma-0 pa-0 noscroll" hide-overlay>
        <v-card-title>
          <span class="text-h5 grey--text">Impressão da Guia</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">

            <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="dialogResize()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{dialogResizeIcon}}</v-icon>
            </v-btn>  
          </template>
          <span>Maximizar</span>
          </v-tooltip>  

          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="payload.guiaPDF.visible = false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>    
          </template>
          <span>Fechar</span>
          </v-tooltip>      
        </v-card-title>

                <iframe :src="pdfsrc" 
                type="application/pdf"
                
                
                width="100%" :height="frameHeight">
                </iframe>
        </v-card>
        
    </v-dialog>

</template>

<script>
import { getAPI } from '../../axios-api'
export default {
  props: ['payload'],
  data() {
    return {
      dialog: false,
      pdfsrc: null,
      frameHeight : '400px',
      dialogFullScreen: false,
      dialogResizeIcon : 'mdi-arrow-expand-all'

    };
  },
  watch: {
  
    'payload.guiaPDF.visible' :{
      handler(newVal /*, oldVal*/) {
       //do your stuff.
       if(newVal) {
          // console.log('form dialog OPEN', newVal, oldVal)
          this.getReport()
          this.dialog = true
          
        }
       else {
         // console.log('form dialog OPEN', newVal, oldVal)
         this.$emit('close')
         this.dialog= false
         URL.revokeObjectURL(this.pdfsrc)
         this.pdfsrc = null
        }
       

      },
      deep: true
    },
    
  },

  methods: {
    dialogResize() {
      if (this.dialogFullScreen) {      
        this.frameHeight = "400px"
        this.dialogFullScreen = false
        this.dialogResizeIcon = 'mdi-arrow-expand-all'
      }
      else {
        this.frameHeight = "95%"
        this.dialogFullScreen = true
        this.dialogResizeIcon = 'mdi-arrow-collapse-all'

      }
    },
    getReport() {
      
        return getAPI.get(`/api/print-guia-consulta/${this.payload.guiaPDF.guia.id}/`, {responseType: 'arraybuffer'
        })
        .then(response => {
          // response.headers['filename']="report.pdf"
          // 'content-disposition', 'inline; filename='+myGeneratedFilename
          //response.headers['content-disposition']="inline; filename=report.pdf"
          //console.log("Success", response);
          
          const blob = new Blob([response.data], {type:"application/pdf"}); // {type: 'text/html'}
          //const objectUrl = URL.createObjectURL(blob);
          //this.pdfsrc = objectUrl;
          //var iframe = document.querySelector("iframe");
          //iframe.src = URL.createObjectURL(blob)+ '#view=FitH';
          this.pdfsrc = URL.createObjectURL(blob)+ '#view=FitH';
          //console.log('this.pdfsrc', this.pdfsrc) : blob:http://localhost:8080/a3bfc8a1-cc11-44bf-9d40-9287effbcf04#view=FitH
          // URL.revokeObjectURL(data)
        })
        .catch(err => {
          console.log('printGuiaForm.getReport() exception: ', err)
        })
        
    },

  },
  /*
  computed: {
    dialog: {
      get () {
        return this.payload.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
  */
}
</script>

<style scoped>
.noscroll /deep/{
   overflow: hidden;
}
</style>