var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabelas"},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1000","outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"text-h5 grey--text"},[_vm._v("Tabelas")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-h5 grey--text",attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-autocomplete',{staticClass:"ml-4 ",attrs:{"hide-details":"","items":_vm.items,"label":"Tabela","item-text":"nome","item-value":"id","return-object":true},on:{"input":_vm.onChangeTabela},model:{value:(_vm.currentItem),callback:function ($$v) {_vm.currentItem=$$v},expression:"currentItem"}})],1),_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-4 mt-4",attrs:{"fab":"","small":"","dark":"","color":"primary"},on:{"click":_vm.showModalNovaTabela}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-file-plus-outline ")])],1)]}}])},[_c('span',[_vm._v("copiar tabela")])])],1),_c('v-col',{staticClass:"text-left mb-8 mt-0 pt-0",attrs:{"cols":"9"}},[_c('v-text-field',{staticClass:"ml-4 pt-0 mt-0",attrs:{"label":"Para buscar informe parte do Procedimento ou Descrição e tecle enter","append-icon":"mdi-magnify","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.busca.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(false)?_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showModal({})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,1561265177)},[_c('span',[_vm._v("Adicionar")])])],1):_vm._e()],1),_c('v-simple-table',{attrs:{"height":"267","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"width":"10%"}},[_vm._v(" Procedimento ")]),_c('th',{staticClass:"text-left",attrs:{"width":"50%"}},[_vm._v(" Descrição ")]),_c('th',{staticClass:"text-left"},[_vm._v(" CH ")]),_c('th',{staticClass:"text-left"},[_vm._v(" CO ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Filme ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Outros ")]),_c('th',{staticClass:"text-left",attrs:{"width":"15%"}},[_vm._v(" Ações ")])])]),_c('tbody',_vm._l((_vm.filteredrecords),function(record){return _c('tr',{key:record.id},[_c('td',[_vm._v(_vm._s(record.procedimento))]),_c('td',{staticClass:"maxSize100pxOrEllipsesTD"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(record.descricao))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(record.descricao))])])],1),_c('td',[_vm._v(_vm._s(record.ch))]),_c('td',[_vm._v(_vm._s(record.co))]),_c('td',[_vm._v(_vm._s(record.filme))]),_c('td',[_vm._v(_vm._s(record.outros))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"red","fab":"","small":"","text":""},on:{"click":function($event){return _vm.DeleteRecord(record)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"primary","fab":"","small":"","text":""},on:{"click":function($event){return _vm.showModal(record)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)])}),0)]},proxy:true}])}),[_c('div',{staticClass:"text-center ma-2"},[_c('v-pagination',{attrs:{"length":_vm.tabLength,"total-visible":7,"circle":""},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2),_c('TabelaForm',{attrs:{"payload":_vm.payload},on:{"close":function($event){_vm.payload.visible=false},"save":_vm.save}}),_c('NovaTabelaForm',{attrs:{"payload":_vm.payloadNovaTabela},on:{"close":function($event){_vm.payloadNovaTabela.visible=false},"save":_vm.saveNovaTabela}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }