<template>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="1200px"

      
    >
    
        <!-- style="position:absolute;right:0; top:0; bottom:0; width:100%" -->
        <v-card >
        <v-card-title>
          <span class="text-h5 grey--text">{{this.payload.relatorio.nome}}</span>
          <v-spacer></v-spacer>
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="payload.visible = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>          
        </v-card-title>

                <iframe :src="pdfsrc" 
                type="application/pdf"
                
                
                width="100%" height="400px">
                </iframe>
        </v-card>
    </v-dialog>

</template>

<script>
import { getAPI } from '../../axios-api'
export default {
  props: ['payload'],
    data() {
    return {
      dialog: false,
      pdfsrc: null
    };
  },
  watch: {
  
    'payload.visible' :{
      handler(newVal /*, oldVal*/) {
       //do your stuff.
       if(newVal) {
          // console.log('form dialog OPEN', newVal, oldVal)
          this.getReport()
          this.dialog = true
          
        }
       else {
         // console.log('form dialog OPEN', newVal, oldVal)
         this.$emit('close')
         this.dialog= false
         URL.revokeObjectURL(this.pdfsrc)
         this.pdfsrc = null
        }
       

      },
      deep: true
    },
    
  },

  methods: {
    getReport() {
      
        return getAPI.get(this.payload.api, {responseType: 'arraybuffer'
        })
        .then(response => {
          // response.headers['filename']="report.pdf"
          // 'content-disposition', 'inline; filename='+myGeneratedFilename
          //response.headers['content-disposition']="inline; filename=report.pdf"
          //console.log("Success", response);
          
          const blob = new Blob([response.data], {type:"application/pdf"}); // {type: 'text/html'}
          //const objectUrl = URL.createObjectURL(blob);
          //this.pdfsrc = objectUrl;
          //var iframe = document.querySelector("iframe");
          //iframe.src = URL.createObjectURL(blob)+ '#view=FitH';
          this.pdfsrc = URL.createObjectURL(blob)+ '#view=FitH';
          //console.log('this.pdfsrc', this.pdfsrc) : blob:http://localhost:8080/a3bfc8a1-cc11-44bf-9d40-9287effbcf04#view=FitH
          // URL.revokeObjectURL(data)
        })
        .catch(function (error) {
                console.log('relatoriosForm.getReport() exception: ', error);
            });
        
    },

  },
  /*
  computed: {
    dialog: {
      get () {
        return this.payload.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
  */
}
</script>