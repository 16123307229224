<template>
  <div class="relatorios">
    <v-card
        class="mx-auto"
        max-width="700"
        outlined
    >
        <v-card-title>
          <span class="text-h5 grey--text">Relatórios</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="close()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
        <span>Fechar</span>
        </v-tooltip>

        </v-card-title>
        <v-row>
        <v-col class="text-left" cols="9">
            
            <v-text-field
                label="Buscar"
                class="mx-4 mt-0 pt-0 mb-4"
                  
                v-model="search"
                append-icon="mdi-magnify"
                
                single-line
                hide-details
            ></v-text-field>
        </v-col>
        <!-- <v-col class="text-right">
            <v-btn
                class="mr-4 mt-4"
                fab
                dark
                color="primary"
                
                @click="showModal({})" 
                >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>
        </v-col> -->
        </v-row>
        <v-simple-table
            height = "270" 
            fixed-header
            dense        
        >
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">
                    Nome
                </th>
                <th class="text-left">
                    Ações
                </th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="relatorio in filteredrelatorios" :key="relatorio.id" >
                    <td>{{relatorio.nome}}</td>
                    <td>   
                        <!-- <v-btn
                           
                            color="red"
                            class="ma-2 white--text"
                            fab
                             text
                             @click="DeleteRecord(operadora)"
                            >
                            <v-icon >
                                mdi-close
                            </v-icon>
                     
                        </v-btn> -->
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            fab
                            text
                            small
                            @click="showModal(relatorio)"
                            v-bind="attrs"
                            v-on="on"

                        >
                        <v-icon >
                            {{relatorio.icon}}
                        </v-icon>
                        </v-btn> 
                        </template>
                        <span>Visualizar</span>
                        </v-tooltip>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>

    <RelatorioForm :payload="payload" @close="payload.visible=false" />
  </div>
  
</template>
<script>

import router from '../router'

import RelatorioForm from '@/components/RelatorioForm'
export default {
    data() {
        return {
    
            relatorio : {},
            relatorios: [
                {id: 1, nome: 'Guias de Consulta', icon: 'mdi-printer-eye'},
                {id: 2, nome: 'Relatório 2', icon: 'mdi-printer-eye'},
                {id: 3, nome: 'Relatorio 3', icon: 'mdi-printer-eye'},
                {id: 4, nome: 'Relatorio 4', icon: 'mdi-printer-eye'},
               // {id: 5, nome: 'Usuários', icon: 'mdi-account-settings'},

            ] ,
            search : '',
            payload : {
                visible : false,
                relatorio : {},
                api : '/api/pdf/report/'
            }    
            
            
            
        }
    },
    components: {RelatorioForm},
    created: function () {
        //this.GetRecords();
    }, 

    computed : {
        
        filteredrelatorios : function() {
            return this.relatorios.filter((record) => {
                return record.nome.toLowerCase().trim().match(this.search.toLowerCase().trim())
            })
        },
        
    },
    methods: {
        close() {
            router.push({name: 'home'}).catch(err => {console.log('usuarios.close() exception: ',err)});
        }, 

        showModal(relatorio) {
            if( relatorio.id == 5) {
                router.push({name: 'usuarios'}).catch(err => {console.log('usuarios.showModal() exception: ',err)});
            }
            else {
                this.payload.relatorio= JSON.parse(JSON.stringify(relatorio)); 
                
                this.payload.visible=true
            }
        },

    }
    
}
</script>