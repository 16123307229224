<template>
  <div class="tabelas">
    <v-card
        class="mx-auto"
        max-width="1000"
        outlined
    >
        <v-card-title>
          <span class="text-h5 grey--text">Tabelas</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="close()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
        <span>Fechar</span>
        </v-tooltip>

        </v-card-title>
        <v-row>
        <v-col
        cols="12"
        sm="6"
        md="4"
        >       
        <v-autocomplete
            class="ml-4 "
            v-model="currentItem"
            hide-details
            :items="items"
            label="Tabela"
            item-text="nome"
            item-value="id"
            :return-object="true"
            
            @input = "onChangeTabela"
        ></v-autocomplete>                     
        </v-col>              
        <v-col class="text-right">
            <v-tooltip top>
            <template v-slot:activator="{ on }">
            <v-btn
                v-on="on"
                class="mr-4 mt-4"
                fab
                small
                dark
                color="primary"
                
                @click="showModalNovaTabela" 
                >
                <v-icon dark>
                    mdi-file-plus-outline
                </v-icon>
            </v-btn>
            </template>
            <span>copiar tabela</span>
          </v-tooltip>            
        </v-col>
        <v-col class="text-left mb-8 mt-0 pt-0" cols="9">
            
            <v-text-field
                label="Para buscar informe parte do Procedimento ou Descrição e tecle enter"
                class="ml-4 pt-0 mt-0"
                  
                v-model="search"
                append-icon="mdi-magnify"
                
                single-line
                hide-details
                @keydown.enter.prevent="busca" 
            ></v-text-field>
        </v-col>

        <v-col v-if="false" class="text-right" >
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="ma-0"
                fab
                dark
                small
                color="primary"
                
                @click="showModal({})" 
                v-bind="attrs"
                v-on="on"
                >
                <v-icon dark >
                    mdi-plus
                </v-icon>
            </v-btn>
            </template>
            <span>Adicionar</span>
            </v-tooltip>
        </v-col>
        </v-row>
        <v-simple-table 
        height = "267" 
        fixed-header
        dense
        >
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left" width= "10%">
                    Procedimento
                </th>
                <th class="text-left" width= "50%">
                    Descrição
                </th>
                <th class="text-left">
                    CH
                </th>
                <th class="text-left">
                    CO
                </th>
                <th class="text-left">
                    Filme
                </th>
                <th class="text-left">
                    Outros
                </th>

                <th class="text-left" width= "15%">
                    Ações
                </th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="record in filteredrecords" :key="record.id" >
                    <td>{{record.procedimento}}</td>

                    <td class="maxSize100pxOrEllipsesTD"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{record.descricao}}</span>
                        </template>
                        <span>{{record.descricao}}</span>
                        </v-tooltip>                    
                    </td>

                    <td>{{record.ch}}</td>
                    <td>{{record.co}}</td>
                    <td>{{record.filme}}</td>
                    <td>{{record.outros}}</td>
                    <td>   
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           
                            color="red"
                            class="ma-0 white--text"
                            fab
                            small
                             text
                             @click="DeleteRecord(record)"
                             v-bind="attrs"
                             v-on="on"
                            >
                            <v-icon >
                                 mdi-close
                            </v-icon>
                     
                        </v-btn>
                        </template>
                        <span>Excluir</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            fab
                            small
                            text
                            @click="showModal(record)"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon >
                            mdi-pencil
                        </v-icon>
                        </v-btn> 
                        </template>
                        <span>Editar</span>
                        </v-tooltip>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        <template>
            <div class="text-center ma-2">
                <v-pagination
                v-model="page"
                :length="tabLength"
                :total-visible="7"
                @input="handlePageChange"
                circle
                ></v-pagination>
            </div>
        </template>
    </v-card>

    <TabelaForm :payload="payload" @close="payload.visible=false" @save="save" />
    <NovaTabelaForm :payload="payloadNovaTabela" @close="payloadNovaTabela.visible=false" @save="saveNovaTabela" />
  </div>
  
</template>
<script>
import { getAPI } from '../../axios-api'
import { mapState } from 'vuex'
import router from '../router'

import TabelaForm from '@/components/TabelaForm'
import NovaTabelaForm from '@/components/NovaTabelaForm'
export default {
    data() {
        return {
            items : [],
            currentItem: {id:1},
            page: 1,
            tabLength : 10,
            record : {},
            records: [] ,
            search : '',
            payload : {
                visible : false,
                record : {}
            },
            payloadNovaTabela : {
                visible : false,
                tabelas: []
            }    
            
            
            
        }
    },
    components: {TabelaForm, NovaTabelaForm},
    created: function () {
        this.GetRecords(1);
        this.GetTabelas();
    }, 
  
    computed : {
        
        filteredrecords : function() {
            return this.records.filter((record) => {
                return record.descricao.toLowerCase().trim().match(this.search.toLowerCase().trim())
            })
        },
        ...mapState(['APIData'])
    },
    methods: {
        close() {
            router.push({name: 'home'}).catch(err => {console.log('navbar.onChangeSelection() exception: ',err)});
        }, 

        onChangeTabela() {
            //console.log('tabela:', this.currentItem, this.currentItem.id)
            this.GetRecords(this.currentItem.id)
            
        },
        busca() {
            //console.log('busca:', this.search, 'tab',this.currentItem.id, this.currentItem)
            this.GetRecords(this.currentItem.id, 1, this.search)
            this.page = 1
        },
        saveNovaTabela(){
            this.GetTabelas();
            this.payloadNovaTabela.visible=false
        },
        save(payload) {
            
            payload.visible=false
            this.record = payload.record
            //console.log(this.record)
            //console.log('id:',this.record.id)
            this.CheckRecord();
        },
        showModal(record) {
            this.payload.record= JSON.parse(JSON.stringify(record)); 
            
            
            this.payload.visible=true
        },
        showModalNovaTabela() {
            this.payloadNovaTabela.visible=true
            this.payloadNovaTabela.tabelas = JSON.parse(JSON.stringify(this.items)); 
            // console.log('showModalNovaTabela() : items', this.items)
            // console.log('showModalNovaTabela() : tabelas', this.payloadNovaTabela.tabelas)
        },
        handlePageChange(page) {
            this.GetRecords(this.currentItem.id, page, this.search)
            this.page = page
        },
        CheckRecord() {
            if( this.record.id === undefined ) {
                this.CreateRecord();        
            } else {
                this.EditRecord();
            }
        },
        GetRecords(tabela=1, page=1, descricao='') {
            var parameters = `tabela=${tabela}&page=${page}`
            if (descricao != '') {
                parameters += `&descricao=${descricao}`
            } 
            // console.log('parameters',parameters)
            getAPI.get(`/api/tabela-detalhe/?${parameters}`)
            .then(response => {
            // this.$store.state.APIData = response.data
            /*
            {"count":5749,"next":"http://127.0.0.1:8000/api/tabela-detalhe/?page=2&tabela=1",
            "previous":null,"results":[{"id":1,"procedimento":10101012,"descricao
            */
            // console.log('count:' , response.data.count)
            this.records= response.data.results
            this.tabLength = Math.ceil(response.data.count / 15)
            // console.log('this.records.count',this.records.length)
            this.page = page

            })
            .catch(err => {
                console.log('tabelas.GetRecords() exception: ', err)
            })
            
        },
        CreateRecord() {

            getAPI.post("/api/tabela-detalhe/", this.record)
                .then(() => {
                    
                    
                    this.GetRecords(1);
                    this.record = {};
                })
                .catch(function (error) {
                    console.log('CreateRecord() exception: ', error);
                });
                
        },
        EditRecord() {
            
            getAPI.put(`/api/tabela-detalhe/${this.record.id}/`, this.record)
                .then(() => {
                    
                    
                    this.GetRecords(this.currentItem.id);
                    this.record = {};
                })
                .catch(function (error) {
                    console.log('EditRecord() exception: ',error);
                });
                

        },
        DeleteRecord(record) {
            
            getAPI.delete(`/api/tabela-detalhe/${record.id}/`)
                .then(() => {
                    
                    
                    this.GetRecords(1);
                    
                })
                .catch(function (error) {
                    console.log('DeleteRecord() exception: ',error);
                });
                
            

        },
        GetTabelas() {
            getAPI.get('/api/tabela/')
            .then(response => {
            
            this.items= response.data
            //console.log('GetTabelas() exception: ',this.items)
            
            })
            .catch(err => {
                console.log('GetTabelas() exception: ', err)
            })
    

        },
        handleBlur(select) {
            console.log('debug', select)
        },


    }
    
}
</script>
<style scoped>

 .maxSize100pxOrEllipsesTD /deep/   {
 max-width: 100px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
</style>