<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >

      <v-card>
        <v-card-title>
          <span class="text-h5 grey--text">Operadora</span>
          <span class="text-h5  ml-0 grey--text">Prestador</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="dialog = false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn> 
          </template>
          <span>Fechar</span>
          </v-tooltip>         
                      
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="payload.prestadorOperadorasTabForm.operadora.operadora"
                  :items="payload.operadoras"
                  label="Operadora"
                  item-text="nome"
                  :disabled="payload.prestadorOperadorasTabForm.operadora.id  != null "
                  item-value="id"
                  
                ></v-select>     
                
                
              </v-col>
              <v-col cols="12"
                sm="6"
                md="6"

              >
                <v-select
                  v-model="payload.prestadorOperadorasTabForm.operadora.tabela"
                  :items="payload.tabelas"
                  label="Tabela"
                  item-text="nome"
                  item-value="id"
                  
                ></v-select>     

              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Código Prestador"
                  v-model="payload.prestadorOperadorasTabForm.operadora.codigo_prestador_na_operadora"
                  required
                  type="number"
                  maxlength="6"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
              </v-col>


              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  label="Valor CH"
                  v-model="payload.prestadorOperadorasTabForm.operadora.valor_ch"
                  required
                  type="number"
                  maxlength="6"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  label="Valor CO"
                  v-model="payload.prestadorOperadorasTabForm.operadora.valor_co"
                  required
                  type="number"
                  maxlength="6"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  label="Valor Filme"
                  v-model="payload.prestadorOperadorasTabForm.operadora.valor_filme"
                  required
                  type="number"
                  maxlength="6"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  label="Valor Outros"
                  v-model="payload.prestadorOperadorasTabForm.operadora.valor_outros"
                  required
                  type="number"
                  maxlength="6"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['payload'],
  data() {
      return {
  
          
      }
  },

  methods: {
      save() {

          var idSelecionado = this.payload.prestadorOperadorasTabForm.operadora.operadora
          
          var operadoraCombo = this.payload.operadoras.find(function(current) {
              
              if(current.id == idSelecionado)
                  return true;
          });

          idSelecionado = this.payload.prestadorOperadorasTabForm.operadora.tabela
          
          var tabelaCombo = this.payload.tabelas.find(function(current) {
              
              if(current.id == idSelecionado)
                  return true;
          });

          this.payload.prestadorOperadorasTabForm.operadora.operadora_nome = operadoraCombo.nome
          this.payload.prestadorOperadorasTabForm.operadora.tabela_nome = tabelaCombo.nome
          
          if (this.payload.prestadorOperadorasTabForm.operadora.id ) {
            //console.log('this.payload.operadora.id', this.payload.prestadorOperadorasTabForm.operadora.id);
          }
          else {
            
            
            this.payload.prestadorOperadorasTabForm.operadora.id = this.hashCode(operadoraCombo.nome)
            this.payload.prestadorOperadoras.push(this.payload.prestadorOperadorasTabForm.operadora)
          }
          this.$emit('save');
      },
      hashCode(s='') {
        s= s.toUpperCase();
        var h = 1000000, l = s.length, i = 0;
        if ( l > 0 )
          while (i < l)
            h = h + ( s.charCodeAt(i++) *(i+1)*31 ) ;
        return h*-1;
      },


  },
  computed: {
    dialog: {
      get () {
        return this.payload.prestadorOperadorasTabForm.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>