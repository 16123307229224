<template>
    <div class="prestador-operadoras-tab " >
        <v-card>
        <v-card-text>
        <v-simple-table 
            height = "267" 
            fixed-header
            dense
        >
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">
                    Operadora
                </th>
                <th class="text-left" width= "15%">
                    Tabela
                </th>
                 <th class="text-left" width= "8%">
                    Valor CH
                </th>
                <th class="text-left" width= "8%">
                    Valor CO
                </th>
                <th class="text-left" width= "8%">
                    Valor Filme
                </th>
                <th class="text-left" width= "8%">
                    Valor Outros
                </th>

                <th class="text-left" width= "20%">
                    Ações
                
         <v-tooltip bottom>
         <template v-slot:activator="{ on, attrs }">    
                <v-btn
                small
                fab
                dark
                color="primary"
                class = "ml-16 mr-0 pa-1"                
                @click="showModal({prestador: payload.prestadorForm.prestador.id})"
                v-bind="attrs"
                v-on="on" 
                >
                <v-icon dark small>
                    mdi-plus
                </v-icon>
            </v-btn>
         </template>
         <span>Adicionar</span>
         </v-tooltip>

                </th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="(operadora, index) in filteredPrestadorOperadoras" :key="operadora.id" >
                    
                    <td class="maxSize100pxOrEllipsesTD"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{operadora.operadora_nome}}</span>
                        </template>
                        <span>{{operadora.operadora_nome}}</span>
                        </v-tooltip>                    
                    </td>

                    <td class="maxSize100pxOrEllipsesTD"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{operadora.tabela_nome}}</span>
                        </template>
                        <span>{{operadora.tabela_nome}}</span>
                        </v-tooltip>                    
                    </td>

                    <td>{{operadora.valor_ch}}</td>
                    <td>{{operadora.valor_co}}</td>
                    <td>{{operadora.valor_filme}}</td>
                    <td>{{operadora.valor_outros}}</td>
                    <td> 
                        
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           
                            color="red"
                            class="ma-0 white--text"
                            fab
                             text
                             small
                             @click="deleteRecord(operadora, index)"
                             v-bind="attrs"
                             v-on="on"
                            >
                            <v-icon >
                                 mdi-close
                            </v-icon>
                     
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            fab
                            text
                            small
                            @click="showModal(operadora, index)"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon >
                            mdi-pencil
                        </v-icon>
                        </v-btn> 
                    </template>
                    <span>Editar</span>
                    </v-tooltip>

                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        </v-card-text>
        </v-card>
        <PrestadorOperadorasTabForm 
            :payload="payload" 
            @close="payload.prestadorOperadorasTabForm.visible=false" 
            @save="save" />
    </div>
</template>

<script>
import PrestadorOperadorasTabForm from '@/components/PrestadorOperadorasTabForm'

export default {
    props: ['payload'],

    components: {PrestadorOperadorasTabForm},

    data() {
        return {
            active_tab : 1,
            //operadora : {},
            savedIndex : null
            /*
            payloadPrestadorOperadorasTabForm : {
                visible : false,
                operadora : {},
                operadoras : [],
                tabelas : [],
                prestadorOperadoras : this.payload.prestadorOperadoras 
            }
            */
        }
    },
    computed : {
        
        filteredPrestadorOperadoras : function() {
            return this.payload.prestadorOperadoras.filter((record) => {
                //console.log('record.prestador', record.prestador)
                //console.log('this.payload.prestadorForm.prestador', this.payload.prestadorForm.prestador.id)
                return record.prestador == this.payload.prestadorForm.prestador.id && !record.isDeleted  
            })
        },
        
    },    
  created: function () {
      // console.log('PrestadorOperadorasTab.this.payload.operadoras',this.payload.operadoras)
  }, 
  
    methods: {
        save() {
            //console.log('payloadPrestadorOperadorasTabForm.prestadorOperadoras.forEach')
            //this.payload.prestadorOperadoras = this.payloadPrestadorOperadorasTabForm.prestadorOperadoras;
            //this.payloadPrestadorOperadorasTabForm.prestadorOperadoras.forEach(element => console.log(element));
            this.payload.prestadorOperadorasTabForm.visible=false;
            //console.log('PrestadorOperadorasTab this.payload.prestadorOperadorasTabForm.operadora', this.payload.prestadorOperadorasTabForm.operadora)
            //this.operadora = this.payload.prestadorOperadorasTabForm.operadora;

            //console.log('this.payload.prestadorOperadoras[this.savedIndex]',this.payload.prestadorOperadoras[this.savedIndex])
            //this.payload.operadora = this.payload.prestadorOperadorasTabForm.operadora
            //this.payload.prestadorOperadoras[this.savedIndex]= this.payload.prestadorOperadorasTabForm.operadora

            //console.log('this.savedIndex', this.savedIndex)
            if(this.savedIndex >= 0) {
                this.$set(this.payload.prestadorOperadoras, this.savedIndex, this.payload.prestadorOperadorasTabForm.operadora)
            }
            this.$emit('save');
        },
        showModal(operadora, index) {
            /*
            this.payloadPrestadorOperadorasTabForm.operadoras =this.payload.operadoras;
            this.payloadPrestadorOperadorasTabForm.tabelas =this.payload.tabelas;
            this.payloadPrestadorOperadorasTabForm.prestadorOperadoras = this.payload.prestadorOperadoras;
            this.payloadPrestadorOperadorasTabForm.operadora= operadora; //JSON.parse(JSON.stringify(operadora)); 
            */
            //this.payload.operadora = operadora;
            if(index== 'undefined') {
                this.savedIndex = -1
            } else {
                this.savedIndex = index;
            }
            
            // console.log('showmodal operadora', this.operadora, index)
            this.payload.prestadorOperadorasTabForm.operadora = JSON.parse(JSON.stringify(operadora)); 
            this.payload.prestadorOperadorasTabForm.visible=true
        },
        deleteRecord(operadora, index) {
            // this.payload.prestadorOperadoras[index].isDeleted = true;
            operadora.isDeleted = true

            // updates the array in a reactive way
            this.$set(this.payload.prestadorOperadoras, index, operadora)
            // console.log('delete()',this.payload.prestadorOperadoras[index])
        }

    },
    
}
</script>
<style scoped>

 .maxSize100pxOrEllipsesTD /deep/   {
 max-width: 100px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
</style>