<template>
  <div class="team">
    <v-card
        class="mx-auto"
        max-width="1000"
        outlined
    >
        <v-card-title>
          <span class="text-h5 grey--text">Profissionais</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="close()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>   
        </template>
        <span>Fechar</span>
        </v-tooltip>       
        </v-card-title>
        <v-row>
        <v-col class="text-left" cols="6">
            <!-- <input type="text" v-model="search" placeholder="search"/> -->
            <v-text-field
                label="Buscar"
                class="mx-4 "
                  
                v-model="search"
                append-icon="mdi-magnify"
                
                single-line
                hide-details
            ></v-text-field>
        </v-col>
        <v-col class="text-right">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mr-4 mt-4 mb-1"
                fab
                dark
                color="primary"
                
                @click="showModal({})" 
                v-bind="attrs"
                v-on="on"
                >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>
            </template>
            <span>Adicionar</span>
            </v-tooltip>
        </v-col>
        </v-row>
        <v-simple-table
            height = "350" 
            fixed-header
            dense
        >
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">
                    Nome
                </th>
                <th class="text-left" width= "10%">
                    CPF
                </th>
                <th class="text-left"  width= "8%">
                    Conselho
                </th>
                <th class="text-left"  width= "14%">
                    Número Conselho
                </th>
                <th class="text-left"  width= "11%">
                    UF Conselho
                </th>
                <th class="text-left" width= "8%">
                    CBO
                </th>
  
                <th class="text-left" width= "15%">
                    Ações
                </th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="profissional in filteredProfessionals" :key="profissional.id" >
                    
                    <td class="maxSize100pxOrEllipsesTD"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{profissional.nome}}</span>
                        </template>
                        <span>{{profissional.nome}}</span>
                        </v-tooltip>                    
                    </td>

                    <td>{{profissional.cpf}}</td>
                    <td>{{conselho[profissional.conselho-1].text}}</td>
                    <td>{{profissional.numero_conselho}}</td>
                    <td>{{profissional.uf_conselho}}</td>
                    <td>{{profissional.cbo}}</td>
                    <td>   
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           
                            color="red"
                            class="white--text ma-0 pa-0"
                            fab
                             text
                             small
                             @click="DeleteProfissional(profissional)"
                             v-bind="attrs"
                             v-on="on"
                            >
                            <v-icon >
                                
                                <!-- mdi-trash-can-outline -->
                                mdi-close
                            </v-icon>
                     
                        </v-btn>
                        </template>
                        <span>Excluir</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="white--text ma-0 pa-0"
                            fab
                            text
                            small
                            @click="showModal(profissional)"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon >
                            
                            mdi-pencil
                        </v-icon>
                        </v-btn> 
                        </template>
                        <span>Editar</span>
                        </v-tooltip>
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>

    <ProfissionalForm :payload="payload" @close="payload.visible=false" @save="save" />
  </div>
  
</template>
<script>
//import axios from "axios";
import { getAPI } from '../../axios-api'
import { mapState } from 'vuex'
import router from '../router'

import ProfissionalForm from '@/components/ProfissionalForm'
export default {
    data() {
        return {
    
            profissional : {},
            profissionais: [] ,
            search : '',
            payload : {
                visible : false,
                profissional : {}
            }    ,
            conselho : [
            { id: 1,  text:'CRAS'	},
            { id: 2,  text:'COREN' },
            { id: 3,  text:'CRF'},	
            { id: 4,  text:'CRFA' },
            { id: 5,  text:'CREFITO'},
            { id: 6,  text:'CRM'	}	,
            { id: 7,  text:'CRN'	}	,
            { id: 8,  text:'CRO'	}	,
            { id: 9,  text:'CRP'	}	,
            { id: 10, text:'OUT'	}	            
            ]
            
            
            
        }
    },
    components: {ProfissionalForm},
    created: function () {
        this.GetProfissional();
    }, 

    // computed: mapState(['APIData']),

    computed : {
        
        filteredProfessionals : function() {
            return this.profissionais.filter((prof) => {
                return prof.nome.toLowerCase().trim().match(this.search.toLowerCase().trim())
            })
        },
        ...mapState(['APIData'])
    },
    
    methods: {
        close() {
            router.push({name: 'home'}).catch(err => {console.log('navbar.onChangeSelection() exception: ',err)});
        }, 

        save(payload) {
            
            payload.visible=false
            this.profissional = payload.profissional
            // console.log('this.profissional',this.profissional)
            if(this.profissional.prestador == null) {
                // console.log('prestador null', this.$store.state.idPrestadorSelecionado);
                this.profissional.prestador = this.$store.state.idPrestadorSelecionado
            }
            // console.log('Profissionais: save(): payload.profissional.cbo', payload.profissional.cbo);
            this.CheckProfissional();
        },
        showModal(profissional) {
            this.payload.profissional= JSON.parse(JSON.stringify(profissional)); 
            this.payload.visible=true
        },
        CheckProfissional() {
            if( this.profissional.id === undefined ) {
                this.CreateProfissional();        
            } else {
                this.EditProfissional();
            }
        },
        GetProfissional() {
            getAPI.get(`/api/profissionais/?prestador=${this.$store.state.idPrestadorSelecionado}`)
            .then(response => {
            // this.$store.state.APIData = response.data
            this.profissionais= response.data
            //console.log(this.profissionais)
            
            })
            .catch(err => {
                console.log('profissionais.GetProfissional() exception: ', err)
            })
    

        },
        CreateProfissional() {
            
            getAPI.post("/api/profissionais/", this.profissional)
                .then(() => {
                    
                    //this.profissionais.push( res.data);
                    this.GetProfissional();
                    this.profissional = {};
                })
                .catch(function (error) {
                    console.log('CreateProfissional() exception:', error);
                });
                
        },
        EditProfissional() {
            getAPI.put(`/api/profissionais/${this.profissional.id}/`, this.profissional)
                .then(() => {
                    
                    //this.profissionais.push( res.data);
                    this.GetProfissional();
                    this.profissional = {};
                })
                .catch(function (error) {
                    console.log('EditProfissional() exception: ',error);
                });
                

        },
        DeleteProfissional(profissional) {
            
            getAPI.delete(`/api/profissionais/${profissional.id}/`)
                .then(() => {
                    
                    //this.profissionais.push( res.data);
                    this.GetProfissional();
                    //this.profissional = {};
                })
                .catch(function (error) {
                    console.log('DeleteProfissional() exception: ',error);
                });
                
            

        }
    },
    watch: {
        '$store.state.idPrestadorSelecionado': function() {
            //console.log('mudou prestador')
            this.GetProfissional();
        }
    }
   
   
    
}
</script>
<style scoped>

 .maxSize100pxOrEllipsesTD /deep/   {
 max-width: 100px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
</style>