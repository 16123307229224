var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5 grey--text"},[_vm._v("Lote")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-h5 grey--text",attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Lote","required":"","type":"number","maxlength":"6","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","disabled":true},model:{value:(_vm.payload.lote.numero_lote),callback:function ($$v) {_vm.$set(_vm.payload.lote, "numero_lote", $$v)},expression:"payload.lote.numero_lote"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-select',{staticClass:"ml-4 ",attrs:{"items":_vm.payload.prestadorOperadoras,"label":"Operadora","item-text":"operadora_nome","item-value":"id","return-object":true,"disabled":_vm.payload.lote.id != null},model:{value:(_vm.payload.lote.prestador_operadora_obj),callback:function ($$v) {_vm.$set(_vm.payload.lote, "prestador_operadora_obj", $$v)},expression:"payload.lote.prestador_operadora_obj"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data inicial","prepend-icon":"mdi-calendar","readonly":""},on:{"blur":function($event){return _vm.initialDateBlur()}},model:{value:(_vm.initialDateFormatted),callback:function ($$v) {_vm.initialDateFormatted=$$v},expression:"initialDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.payload.lote.data_inicial),callback:function ($$v) {_vm.$set(_vm.payload.lote, "data_inicial", $$v)},expression:"payload.lote.data_inicial"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data final","prepend-icon":"mdi-calendar","readonly":""},on:{"blur":function($event){return _vm.finalDateBlur()}},model:{value:(_vm.finalDateFormatted),callback:function ($$v) {_vm.finalDateFormatted=$$v},expression:"finalDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.payload.lote.data_final),callback:function ($$v) {_vm.$set(_vm.payload.lote, "data_final", $$v)},expression:"payload.lote.data_final"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-select',{staticClass:"ml-4 ",attrs:{"items":_vm.payload.tipoGuia,"label":"Tipo guia","item-text":"tipo","item-value":"id","return-object":true},model:{value:(_vm.payload.lote.tipo_guia_obj),callback:function ($$v) {_vm.$set(_vm.payload.lote, "tipo_guia_obj", $$v)},expression:"payload.lote.tipo_guia_obj"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }