<template>
<div>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5 grey--text">Guia Consulta</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              dark
              dense
              class="text-h5 grey--text"
              
              @click="setupFields"
              v-bind="attrs"
              v-on="on"

            >
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>
          </template>
          <span>Configurar Colunas</span>
          </v-tooltip>

         <v-tooltip bottom>
         <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              dense              
              class="text-h5 grey--text"
              
              @click="close"
              v-bind="attrs"
              v-on="on"

            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Fechar</span>
          </v-tooltip>

        </v-card-title>
        <v-card-text>
          <v-container>
          <v-form ref="guiaForm" class="mx-2" lazy-validation>
          
            <v-row>
        <!-- 
              sera preenchido sempre automatico do cadastro operadora
              talvez criar uma aba para mostrar valor atual

              <v-col
                cols="12"
                sm="3"
                md="3"
              >
                <v-text-field
                  label="Registro ANS"
                  v-model="payload.guiaConsultaForm.guia.RegistroANS"
                  required
                  dense
                ></v-text-field>
              </v-col> -->

  <!--
              <v-col cols="12"
                sm="3"
                md="3"

              >
                <v-text-field
                  label="Guia Prestador"
                  required
                  dense
                  type="number"
                  maxlength="14"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                  v-model="payload.guiaConsultaForm.guia.numeroGuiaPrestador"
                ></v-text-field>
              </v-col>

                  type="number"
                  maxlength="6"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"

   -->
              <v-col
                cols="12"
                xs="6" sm="6" md="3" lg="2" xl="2"                
              >
                <v-text-field
                  label="Guia Operadora"
                  class = "inputNumberNoSpin"
                  v-model="payload.guiaConsultaForm.guia.numeroGuiaOperadora"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  
                  ref="startField"
                  autofocus
                  type="text"
                  maxlength="20"
                  v-mask="'####################'"
                ></v-text-field>
              </v-col>
             <v-col
                cols="12"
                xs="6" sm="4" md="3" lg="2" xl="2"
                
              >
                <v-text-field
                  label="C.Beneficiário"
                  v-model="payload.guiaConsultaForm.guia.numeroCarteiraBeneficiario"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  type="text"
                  maxlength="20"
                  v-mask="'####################'"
                   
                ></v-text-field>
              </v-col>              
              <v-col
                cols="6"
                xs="2" sm="2" md="2" lg="2" xl="2"
                v-if="payload.guiaConsultaForm.cols.atendimentoRN.visible"
              >
                <v-select
                  label="RN"
                  v-model="payload.guiaConsultaForm.guia.atendimentoRN"
                  :items="['N', 'S']"
                  required
                  dense
                   
                ></v-select>
              </v-col>              
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                class="text-capitalize"
                  label="Nome Beneficiário"
                  v-model="payload.guiaConsultaForm.guia.nomeBeneficiario"
                  @input="(val) => (payload.guiaConsultaForm.guia.nomeBeneficiario = payload.guiaConsultaForm.guia.nomeBeneficiario.toUpperCase())"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  type="text"
                  maxlength="70"
                   
                ></v-text-field>
              </v-col>          
              <v-col
                cols="12"
                xs="3" sm="3" md="3" lg="3" xl="3"
                v-if="payload.guiaConsultaForm.cols.numeroCNS.visible"
              >
                <v-text-field
                  label="CNS"
                  v-model="payload.guiaConsultaForm.guia.numeroCNS"
                  required
                  dense
                  type="text "
                  maxlength="15"
                  v-mask="'###############'"
                                     
                ></v-text-field>
                
              </v-col>          
  <!-- 
              <v-col
                cols="12"
                sm="3"
                md="3"
              >
                <v-text-field
                  label="Código Prestador na Operadora"
                  v-model="payload.guiaConsultaForm.guia.codigoPrestadorNaOperadora"
                  required
                  dense
                  type="number"
                  maxlength="14"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>
        
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Contratado"
                  v-model="payload.guiaConsultaForm.guia.nomeContratado"
                  required
                  dense
                  type="text"
                  maxlength="70"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>   
               
              <v-col
                cols="12"
                sm="3"
                md="3"
              >
                <v-text-field
                  label="CNES"
                  v-model="payload.guiaConsultaForm.guia.CNES"
                  required
                  dense
                  type="number"
                  maxlength="7"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>
              -->
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="payload.guiaConsultaForm.cols.indicacaoAcidente.visible"
              >
                <v-select
                  label="Indicação Acidente"
                  v-model="payload.guiaConsultaForm.guia.indicacaoAcidente"
                  :items = "indicacaoAcidente"
                  item-text="text"
                  item-value="id"

                  required
                  dense
                   
                ></v-select>
                
              </v-col>         
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="payload.guiaConsultaForm.cols.regimeAtendimento.visible"
              >  
                <v-autocomplete
                  label="R.Atendimento"
                  v-model="payload.guiaConsultaForm.guia.regimeAtendimento"
                  required
                  dense
                  
                  :items = "regimeAtendimento"
                  item-text="key"
                  item-value="id"
                  @change="regimeAtendimentoOnChange"
                  @blur = "regimeAtendimentoOnChange"
                  type="text"
                  hide-no-data
                  maxlength="30"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-autocomplete>
              </v-col>

                      
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="payload.guiaConsultaForm.cols.saudeOcupacional.visible"
              >  
                <v-autocomplete
                  label="S.Ocupacional"
                  v-model="payload.guiaConsultaForm.guia.saudeOcupacional"
                  required
                  dense
                  
                  :items = "saudeOcupacional"
                  item-text="key"
                  item-value="id"
                  type="text"
                  hide-no-data
                  maxlength="30"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="payload.guiaConsultaForm.cols.coberturaEspecial.visible"
              >  
                <v-autocomplete
                  label="C.Especial"
                  v-model="payload.guiaConsultaForm.guia.coberturaEspecial"
                  required
                  dense
                  
                  :items = "coberturaEspecial"
                  item-text="key"
                  item-value="id"
                  type="text"
                  hide-no-data
                  maxlength="30"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-autocomplete>
              </v-col>              
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-autocomplete
                  label="Profissional"
                  v-model="profissional"
                  required
                  :rules="[(v) => !!v.id  || 'Campo obrigatório']"
                  dense
                  return-object
                  :items = "profissionais"
                  item-text="key"
                  item-value="id"
                  @change="profissionalOnChange"
                  @blur = "profissionalOnChange"
                  type="text"
                  hide-no-data
                  maxlength="70"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-autocomplete>
                
              </v-col>    
              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="payload.guiaConsultaForm.cols.numeroConselhoProfissionalAuto.visible"
              >
                <v-text-field
                  label="Número Conselho"
                  v-model="payload.guiaConsultaForm.guia.numeroConselhoProfissional"
                  required
                  class = "inputNumberNoSpin"
                  dense
                  type="number"
                  maxlength="15"
                  disabled
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>    
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  label="Conselho"
                  v-model="conselhoSelecionado"
                  required
                  dense
                  type="text"
                  maxlength="15"
                  disabled
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>   
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  label="UF Conselho"
                  v-model="uf_conselho"
                  required
                  dense
                  type="text"
                  disabled
                  maxlength="2"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>     
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  label="CBO"
                  v-model="payload.guiaConsultaForm.guia.CBOS"
                  required
                  dense
                  disabled
                  type="number"
                  maxlength="6"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>     
              
              <v-col
                cols="12"
                sm="3"
                md="3"
              >
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="true"
                  :nudge-left="25"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dataAtendimentoFormatted"
                      class = "inputNumberNoSpin"
                      label="Atendimento"
                      prepend-icon="mdi-calendar"
                      type="text"
                      v-mask="'##/##/####'"
                      maxlength="10"
                      ref="dataAtendimento"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      
                      required
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      @change="dataAtendimentoOnChange"
                      :error-messages="dataAtendimentoError"
                      dense
                      v-bind="attrs"
                      v-on="on"
                      
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="payload.guiaConsultaForm.guia.dataAtendimento"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
                <!--                 
                <v-text-field
                  label="Atendimento"
                  v-model="payload.guiaConsultaForm.guia.dataAtendimento"
                  required
                  dense
                  type="text"
                  maxlength="10"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field> 
                -->
                
              </v-col>     

              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="payload.guiaConsultaForm.cols.tipoConsulta.visible"
              >
                <v-select
                  label="Tipo Consulta"
                  v-model="payload.guiaConsultaForm.guia.tipoConsulta"
                  :items = "tipoConsulta"
                  required
                  item-text="text"
                  item-value="id"

                  dense
                   
                ></v-select>
                
              </v-col>                     
               <v-col
                cols="12"
                xs="6" sm="6" md="6" lg="6" xl="6"
              >
                <!-- payload.guiaConsultaForm.guia.codigoProcedimento -->
                <!--
                :search-input.sync adds 200ms delay. You can change it to 0    
                :debounce-search="0"

                 -->
                <v-autocomplete
                  label="Procedimento"
                  v-model="procedimento"
                  required
                  :rules="[(v) => !!(v.id && v.id !='undefined') || 'Campo obrigatório']"
                  dense
                  return-object
                  :items = "procedimentos"
                  item-text="text"
                  item-value="id"
                  
                  type="text"
                  :search-input.sync="search"
                  hide-no-data
                  @change="procedimentoOnChange"
                  @blur="procedimentoOnChange"
                  maxlength="70"
                  
                   
                ></v-autocomplete>

                <!-- @change="profissionalOnChange" cache-items -->
                <!--oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"-->
              </v-col>
              <v-col
                cols="12"
                xs="3" sm="3" md="3" lg="3" xl="3"
                v-if="payload.guiaConsultaForm.cols.codigoTabelaAuto.visible"
              >
                <v-text-field
                  label="Tabela"
                  v-model="tabela"
                  required
                  dense
                  type="text"
                  disabled
                   
                ></v-text-field>
                
              </v-col>     

                      

              <v-col
                cols="12"
                xs="2" sm="2" md="2" lg="2" xl="2"
                
              >
                <v-text-field
                  label="Valor"
                  v-model="payload.guiaConsultaForm.guia.valorProcedimento"
                  required
                  dense
                  type="number"
                  maxlength="10"
                  disabled
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>
              <!-- 
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  label="lote"
                  v-model="payload.guiaConsultaForm.guia.lote"
                  required
                  dense
                  type="number"
                  maxlength="8"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>         
              -->                     
              <v-col
                cols="12"
                xs="8" sm="8" md="10" lg="10" xl="10"
                v-if="payload.guiaConsultaForm.cols.observacao.visible"
              >
                <v-text-field
                  label="Observação"
                  v-model="payload.guiaConsultaForm.guia.observacao"
                  required
                  dense
                  
                  type="text"
                  maxlength="500"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
              </v-col>         


            </v-row>
            </v-form>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
            v-if="payload.guiaConsultaForm.cols.botaoFechar.visible"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveGuiaConsulta"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <GuiaConsultaFormPop1
    :payload="payload" 
    @close="setupFieldsClose" 
  />

  <v-snackbar
      v-model="alert"
      color="error"
  >
  <v-icon class="pr-3" dark large>error</v-icon>
      Campos obrigatórios devem ser preenchidos corretamente

      <template v-slot:action="{ attrs }">
        <v-btn
          color="grey"
          text
          v-bind="attrs"
          @click="alert = false"
        >
          Fechar
        </v-btn>
      </template>
  </v-snackbar>    

</div>
</template>

<script>
import { getAPI } from '../../axios-api'
import GuiaConsultaFormPop1 from '@/components/GuiaConsultaFormPop1'
export default {
  props: ['payload'],
  components: {GuiaConsultaFormPop1},
  data() {
    return {
      alert: false,
      dialog: false,
      tabela : null,
      procedimento : {id: null, text: null},
      procedimentos : [],
      search : null,
      uf_conselho : null,
      conselhoSelecionado : null,
      profissional : {id: null, nome: ''},
      profissionais : [],
      dataAtendimentoError : [],
      menu1 : null,
      dataAtendimentoFormatted : null,
      indicacaoAcidente : [
        {id : 0, text: '0 | Trabalho'}, 
        {id : 1, text: '1 | Trânsito'}, 
        {id : 2, text: '2 | Outros Acidentes'}, 
        {id : 9, text: '9 | Não Acidentes'}
      ],
      tipoConsulta  :[
        {id: 1, text: '1 | Primeira'}, 
        {id: 2, text: '2 | Seguimento'}, 
        {id: 3, text: '3 | Pré-Natal'}, 
        {id: 4, text: '4 | Por encaminhamento'}
      ],

      conselho : [
            { id: 1,  text:'CRAS'	},
            { id: 2,  text:'COREN' },
            { id: 3,  text:'CRF'},	
            { id: 4,  text:'CRFA' },
            { id: 5,  text:'CREFITO'},
            { id: 6,  text:'CRM'	}	,
            { id: 7,  text:'CRN'	}	,
            { id: 8,  text:'CRO'	}	,
            { id: 9,  text:'CRP'	}	,
            { id: 10, text:'OUT'	}	,
            // v4.00.00
            { id: 11, text:'CRBio'}	,
            { id: 12, text:'CRBM' }	,
            { id: 13, text:'CREF' }	,
            { id: 14, text:'CRMV' }	,
            { id: 15, text:'CRTR'}	
                        
            ],
      uf : [
        {id : 11, estado : 'Rondônia', sigla:'RO'},
        {id : 12, estado : 'Acre', sigla:'AC'},
        {id : 13, estado : 'Amazonas', sigla:'AM'},
        {id : 14, estado : 'Roraima', sigla:'RR'},
        {id : 15, estado : 'Pará', sigla:'PA'},
        {id : 16, estado : 'Amapá', sigla:'AP'},
        {id : 17, estado : 'Tocantins', sigla:'TO'},
        {id : 21, estado : 'Maranhão', sigla:'MA'},
        {id : 22, estado : 'Piauí', sigla:'PI'},
        {id : 23, estado : 'Ceará', sigla:'CE'},
        {id : 24, estado : 'Rio Grande do Norte', sigla:'RN'},
        {id : 25, estado : 'Paraíba', sigla:'PB'},
        {id : 26, estado : 'Pernambuco', sigla:'PE'},
        {id : 27, estado : 'Alagoas', sigla:'AL'},
        {id : 28, estado : 'Sergipe', sigla:'SE'},
        {id : 29, estado : 'Bahia', sigla:'BA'},
        {id : 31, estado : 'Minas Gerais', sigla:'MG'},
        {id : 32, estado : 'Espírito Santo', sigla:'ES'},
        {id : 33, estado : 'Rio de Janeiro', sigla:'RJ'},
        {id : 35, estado : 'São Paulo', sigla:'SP'},
        {id : 41, estado : 'Paraná', sigla:'PR'},
        {id : 42, estado : 'Santa Catarina', sigla:'SC'},
        {id : 43, estado : 'Rio Grande do Sul', sigla:'RS'},
        {id : 50, estado : 'Mato Grosso do Sul', sigla:'MS'},
        {id : 51, estado : 'Mato Grosso', sigla:'MT'},
        {id : 52, estado : 'Goiás', sigla:'GO'},
        {id : 53, estado : 'Distrito Federal', sigla:'DF'},
        {id : 98, estado : 'Países Estrangeiros', sigla:'EX'}
      ],
      tabelas : [
        {id: 22,	nome: 'Procedimentos'},
        {id: 20,	nome: 'Medicamentos'},
        {id: 19,	nome: 'Materiais e OPME'},
        {id: 18,	nome: 'Diárias e Taxas'},
        {id: 0,	  nome: 'Própria'}
      ],

      // optional tab 75
      coberturaEspecial : [
        { id:null, key: '0 | Não se aplica'},
        { id:'01', key: '1 | Gestante'},
        { id:'02', key: '2 | Pré-operatório'},
        { id:'03', key: '3 | Pós-operatório'}

       ],

      // optional tab 77
      saudeOcupacional : [
        { id: null, key: '0 | Não se aplica'},
        { id: '01', key: '1 | Admissional'},
        { id: '02', key: '2 | Demissional'},
        { id: '03', key: '3 | Periódico'},
        { id: '04', key: '4 | Retorno ao trabalho'},
        { id: '05', key: '5 | Mudança de função'},
        { id: '06', key: '6 | Promoção à saúde'}
      ],

      // required - tab 76
      regimeAtendimento : [
        { id: '01', key: '1 | Ambulatorial'},
        { id: '02', key: '2 | Domiciliar' },
        { id: '03', key: '3 | Internação'},
        { id: '04', key: '4 | Pronto-socorro'},
        { id: '05', key: '5 | Telessaúde'}
      ]
      
    }
  },
  watch: {
    'payload.guiaConsultaForm.guia.dataAtendimento' () {
      //console.log('this.payload.guiaConsultaForm.guia.dataAtendimento', this.payload.guiaConsultaForm.guia.dataAtendimento)
      this.dataAtendimentoFormatted = this.formatDate(this.payload.guiaConsultaForm.guia.dataAtendimento)
    },
    
    search(val) {
      val && val !== this.procedimento.nome && this.searchVal(val);
    },

    'payload.guiaConsultaForm.visible' :{
      handler(newVal /*, oldVal*/) {
       //do your stuff.
       if(newVal) {
         if(!this.payload.guiaConsultaForm.guia.id) {
            this.defaultFieldValues()
            if (this.$refs['guiaForm']) {
              //this.$refs.form.reset();
              this.$refs.guiaForm.resetValidation()
            }
           
         }
         else {
           
            this.loadValues()
         }
         this.fillHiddenFields()

         this.dialog = true
         this.getFieldSetup()
         }
       else {
         this.$emit('close')
         this.dialog= false
        }
       // console.log('form dialog OPEN', newVal, oldVal)

      },
      deep: true
    },
    
  },
  //beforeCreate: function() {
  Created: function() {
    
  },
  async mounted() {
    await this.GetProfissional()
    
    //this.$nextTick(async () => {
    
    //});             

    
  },
  methods: {
    
    /*
    mounted() {
      this.inputFocus()
      this.$nextTick(function () {
          // Code that will run only after the
          // entire view has been rendered
            
      })
    },    
    
    inputFocus() {
      this.$nextTick(() => {
        const focusStartField = this.$refs.startField
        focusStartField.focus()
      });
    },
    */
    async loadValues() {
            this.payload.guiaConsultaForm.guia.indicacaoAcidente = parseInt(this.payload.guiaConsultaForm.guia.indicacaoAcidente)
            this.payload.guiaConsultaForm.guia.tipoConsulta = parseInt(this.payload.guiaConsultaForm.guia.tipoConsulta)

            const ufprof = this.payload.guiaConsultaForm.guia.UFConselhoProfissional
            var ufsel = this.uf.find(function(current) {
              
            if(current.id == ufprof)
                  return true;
            });
            if(ufsel) {
              this.uf_conselho = ufsel.sigla
              
            }
            var numeroConselho = this.payload.guiaConsultaForm.guia.numeroConselhoProfissional
            
            const profSel = this.profissionais.find(function(current) {
              
              if(current.numero_conselho == numeroConselho && current.uf_conselho == ufprof)
                      return true;
            });
            if(profSel) {
              this.profissional= profSel
            }

            
            const conselhoPro = this.payload.guiaConsultaForm.guia.conselhoProfissional
            const conselhoSel = this.conselho.find(function(current) {
              if(current.id == conselhoPro)
                return true;
            })

            if (conselhoSel) {
              this.conselhoSelecionado = conselhoSel.text
            }

            let response = await  getAPI.get(`/api/tabela-detalhe/?tabela=${this.payload.lote.prestador_tabela}&page=1&descricao=${this.payload.guiaConsultaForm.guia.codigoProcedimento}`)

            const txt = response.data.results[0].procedimento + ' | ' + response.data.results[0].descricao
            this.procedimentos = [
              {
                'id': response.data.results[0].id, 
                'text': txt, 
                procedimento : response.data.results[0].procedimento,
                tabela : response.data.results[0].tabela,
                ch : response.data.results[0].ch,
                co : response.data.results[0].co,
                filme : response.data.results[0].filme, 
                outros : response.data.results[0].outros
              }
            ]
            
            
            this.procedimento = this.procedimentos[0]  //{id: response.data.results[0].id, tabela : response.data.results[0].tabela, procedimento : response.data.results[0].procedimento}

            this.tabela= await this.GetTabelas(this.procedimento.tabela)
            
    },
    close() {
      this.payload.guiaConsultaForm.visible = false
    },
      searchVal(val) {
        
        this.GetTabela(this.payload.lote.prestador_tabela, 1, val) 
      },
      GetTabela(tabela=1, page=1, descricao='') {
            var parameters = `tabela=${tabela}&page=${page}`
            if (descricao != '') {
                if (descricao.indexOf('|') > -1){
                  descricao = descricao.substring(0, descricao.indexOf('|')  )
                }
                parameters += `&descricao=${descricao}`
            } 
            // console.log('descricao:', descricao)
            getAPI.get(`/api/tabela-detalhe/?${parameters}`,{'silentMode': 1})
            .then(response => {
            // this.$store.state.APIData = response.data
            /*
            {"count":5749,"next":"http://127.0.0.1:8000/api/tabela-detalhe/?page=2&tabela=1",
            "previous":null,"results":[{"id":1,"procedimento":10101012,"descricao
            */
            
            response.data.results.forEach(row => {
              row.text = row.procedimento + ' | ' + row.descricao
              /*
              if (this.isNumeric(descricao)) {
                row.text = String(row.procedimento )
              }
              else {
                row.text = row.descricao
              }
              */

            }); 
                       
            this.procedimentos= response.data.results.slice(0, 5)

            })
            .catch(err => {
                console.log('guiaConsultaForm.GetTabela() exception: ', err)
            })
      },
      
      isNumeric(str) {
        
        if (typeof str != "string") return false // we only process strings!  
        
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
              !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
              
      },  
      procedimentoOnChange() {
        
        this.$nextTick(async  () => {
          
          if (this.procedimento.tabela) {
            this.tabela= await this.GetTabelas(this.procedimento.tabela)
            var valorProc = 0
            if (this.procedimento.ch) {valorProc += this.procedimento.ch* this.payload.lote.prestador_operadora_obj.valor_ch}
            if (this.procedimento.co) {valorProc += this.procedimento.co* this.payload.lote.prestador_operadora_obj.valor_co}
            if (this.procedimento.filme) {valorProc += this.procedimento.filme* this.payload.lote.prestador_operadora_obj.valor_filme}
            if (this.procedimento.outros) {valorProc += this.procedimento.outros* this.payload.lote.prestador_operadora_obj.valor_outros}

            this.payload.guiaConsultaForm.guia.valorProcedimento = valorProc
          }
        })
        this.$forceUpdate()
      },
      async GetTabelas(id) {
        try{
          if(id) {
            let response = await getAPI.get(`/api/tabela/${id}/`, {'silentMode': 1})
            var tabNome = ''
            const idTab = response.data.dominio_tuss
              var tabSel = this.tabelas.find(function(current) {
                
                if(current.id == idTab)
                    return true;
              });
              if(tabSel) {
                tabNome = tabSel.nome
                
              }


            return response.data.dominio_tuss + ' ' + tabNome  
          }       
        }catch(err){
          console.log('guiaConsultaForm.GetTabelas() exception: ', err)
        }    
          

      },
      regimeAtendimentoOnChange(){

      },
      profissionalOnChange() {
        if(this.profissional.id) {
          this.$nextTick(() => {
            this.payload.guiaConsultaForm.guia.numeroConselhoProfissional= ''
            this.payload.guiaConsultaForm.guia.numeroConselhoProfissional= String(this.profissional.numero_conselho)
            this.payload.guiaConsultaForm.guia.nomeProfissional = this.profissional.nome
            this.conselhoSelecionado = this.conselho[this.profissional.conselho-1].text
            this.payload.guiaConsultaForm.guia.conselhoProfissional =  this.profissional.conselho
            this.payload.guiaConsultaForm.guia.CBOS = this.profissional.cbo
            this.payload.guiaConsultaForm.guia.UFConselhoProfissional = this.profissional.uf_conselho
            const ufprof = this.profissional.uf_conselho
            var ufsel = this.uf.find(function(current) {
              
              if(current.id == ufprof)
                  return true;
            });
            if(ufsel) {
              this.uf_conselho = ufsel.sigla
              // console.log('uf', ufsel.sigla)
            }

            this.$forceUpdate()
            this.$refs.dataAtendimento.focus()
          })
        }
      },
      validaData(date) {
        if (!date) {
          return false;
        }

        let today = new Date().toISOString().split('T')[0]

        const day = date.slice(0, 2);
        const month = date.slice(3, 5);
        const year = date.slice(date.length - 4, date.length);
        if(!Date.parse(year+'-' + month + '-'+ day)) {
          return false;
        }

        let dateParsed = year+'-' + month + '-'+ day

        if (dateParsed > today){
          return false;
        }

        return true;
      },
      dataAtendimentoOnChange(){
        
        if (this.dataAtendimentoFormatted) {
          const date = this.dataAtendimentoFormatted;
          
          const day = date.slice(0, 2);
          const month = date.slice(3, 5);
          const year = date.slice(date.length - 4, date.length);
          if(!Date.parse(year+'-' + month + '-'+ day)) {
            this.dataAtendimentoError = ['data inválida']

            //this.$nextTick(() => {
            
              //this.dataAtendimentoFormatted = date.replace('.','')
            //})             
          } 
          else {
            if (!this.validaData(this.dataAtendimentoFormatted)){
              this.dataAtendimentoError = ['data futura não permitida']
            }
            else {
              this.dataAtendimentoError = []
              this.$nextTick(() => {
                this.payload.guiaConsultaForm.guia.dataAtendimento = `${year}-${month}-${day}` 
                this.dataAtendimentoFormatted = date.replace('.','')
              })   
            }
          }
          
        }
        /*
        this.$nextTick(() => {
          
          this.dataAtendimentoFormatted = date.replace('.','')
        })   
        */
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month,  year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      save() {

          this.$emit('save');
      },
      setupFields() {
        this.payload.guiaConsultaForm.GuiaConsultaFormPop1.visible = true;
        
      },
      setupFieldsClose() {
        this.payload.guiaConsultaForm.GuiaConsultaFormPop1.visible = false;

        // set default for invisible fields
        Object.keys(this.payload.guiaConsultaForm.guia).forEach( (key) => {
            if (Object.keys(this.payload.guiaConsultaForm.cols).includes(key)) {
              if(!this.payload.guiaConsultaForm.cols[key].visible) {
                
                this.payload.guiaConsultaForm.guia[key] = this.payload.guiaConsultaForm.cols[key].valorDefault
                
              }          

            }
                        
        });

        this.saveFieldsSetup();

      },
      saveFieldsSetup() {
        let config = []
        Object.keys(this.payload.guiaConsultaForm.cols).forEach( (key) => {
          config[this.payload.guiaConsultaForm.cols[key].id] = [this.payload.guiaConsultaForm.cols[key].visible, this.payload.guiaConsultaForm.cols[key].valorDefault]
                        
        }, this);
        //console.log('config:', config)
        this.$store.dispatch('updateGuiaConfigList', config)       
        
      },
      getFieldSetup() {
        let config = this.$store.getters.getGuiaConfigList;
        
          Object.keys(this.payload.guiaConsultaForm.cols).forEach( (key) => {
            if (config.length>this.payload.guiaConsultaForm.cols[key].id) {
              this.payload.guiaConsultaForm.cols[key].visible = config[this.payload.guiaConsultaForm.cols[key].id][0];
              this.payload.guiaConsultaForm.cols[key].valorDefault = config[this.payload.guiaConsultaForm.cols[key].id][1]
            }                            
          });
        
      },
      async saveGuiaConsulta() {
          if (!this.payload.guiaConsultaForm.guia.numeroGuiaOperadora ||
              !this.payload.guiaConsultaForm.guia.numeroCarteiraBeneficiario ||
              !this.payload.guiaConsultaForm.guia.numeroCarteiraBeneficiario || 
              !this.payload.guiaConsultaForm.guia.nomeBeneficiario ||
              !this.profissional.id ||
              !this.validaData(this.dataAtendimentoFormatted) ||
              !this.procedimento.id) {
            this.alert = true;
            return
          }
          
          this.payload.guiaConsultaForm.guia.numeroGuiaPrestador = this.payload.guiaConsultaForm.guia.numeroGuiaOperadora 
          this.payload.guiaConsultaForm.guia.codigoProcedimento = String(this.procedimento.procedimento)
          this.payload.guiaConsultaForm.guia.numeroCNS= String(this.payload.guiaConsultaForm.guia.numeroCNS)

          
          try{
              if(this.payload.guiaConsultaForm.guia.id) {
                
                await getAPI.put(`/api/guia-consulta/${this.payload.guiaConsultaForm.guia.id}/`, this.payload.guiaConsultaForm.guia)
                this.payload.guiaConsultaForm.visible = false
              }
              else {
                await this.fillHiddenFields()
                // console.log('save guia this.payload.guiaConsultaForm.guia',  this.payload.guiaConsultaForm.guia)
                await getAPI.post('/api/guia-consulta/', this.payload.guiaConsultaForm.guia)

                // reset
                this.payload.guiaConsultaForm.guia = {}
                this.defaultFieldValues()
                await this.fillHiddenFields()
                if (this.$refs['guiaForm']) {
                  //this.$refs.form.reset();
                  this.$refs.guiaForm.resetValidation()
                }
                
                this.$forceUpdate()            
                this.$refs.startField.focus()
              }
          }catch(err){
              console.log('guiaConsultaForm.saveGuiaConsulta() exception: ', err,this.payload.guiaConsultaForm.guia)
          }            
          
      },
      defaultFieldValues() {
        this.payload.guiaConsultaForm.guia.lote = this.payload.lote.id  //numero_lote
        this.payload.guiaConsultaForm.guia.indicacaoAcidente = 9 // {id: 9}
        this.payload.guiaConsultaForm.guia.atendimentoRN = 'N'
        this.payload.guiaConsultaForm.guia.tipoConsulta = 1
        this.payload.guiaConsultaForm.guia.numeroCNS = null
        this.payload.guiaConsultaForm.guia.observacao = null
        this.payload.guiaConsultaForm.guia.regimeAtendimento = '01'
        this.payload.guiaConsultaForm.guia.saudeOcupacional = null
        this.payload.guiaConsultaForm.guia.coberturaEspecial = null

        this.tabela = null
        this.profissional = {id: null, nome: ''};
        this.procedimento = {id: null, text: ''};
        this.dataAtendimentoFormatted = null;

        this.setupFieldsClose()  
      },

      fillHiddenFields() {
        if(this.tabela) {
          this.payload.guiaConsultaForm.guia.codigoTabela = parseInt(this.tabela.substring(0, this.tabela.indexOf(' ')  ) , 10)
        }
        this.payload.guiaConsultaForm.guia.RegistroANS = String(this.payload.lote.operadora_registro_ans)
        this.payload.guiaConsultaForm.guia.nomeContratado =  this.payload.lote.prestador_nomeContratado
        this.payload.guiaConsultaForm.guia.CNES = String(this.payload.lote.prestador_cnes)
        //this.payload.guiaConsultaForm.guia.tipoConsulta= String(this.payload.guiaConsultaForm.guia.tipoConsulta)
        //this.payload.guiaConsultaForm.guia.indicacaoAcidente= String(this.payload.guiaConsultaForm.guia.indicacaoAcidente)
        if(this.payload.lote.codigo_prestador_na_operadora) {
            this.payload.guiaConsultaForm.guia.codigoPrestadorNaOperadora = this.payload.lote.codigo_prestador_na_operadora
        }
        else {
            this.payload.guiaConsultaForm.guia.codigoPrestadorNaOperadora = null  // xml will fill  cnpjContratado with this.payload.lote.prestador_cnpj
        }

      },
      async GetProfissional() {
        
        let response = await getAPI.get(`/api/profissionais/?prestador=${this.$store.state.idPrestadorSelecionado}`)
        try {
          this.profissionais= response.data;
          this.profissionais.forEach(element => element.key = element.numero_conselho + ' | ' + element.nome);
          
        }
        catch(err) {
            console.log('guiaConsultaForm.GetProfissional() exception: ',err)
        }


      },
      


  },
  /*
  computed: {
    dialog: {
      get () {
        return this.payload.guiaConsultaForm.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    */
    /*
    numeroGuiaOperadora: {
      get() {
        return this.payload.guiaConsultaForm.guia.numeroGuiaOperadora;
      },
      set(val) {
        this.payload.guiaConsultaForm.guia.numeroGuiaOperadora = '';
        this.$nextTick(() => {
          this.payload.guiaConsultaForm.guia.numeroGuiaOperadora = val.replace('.', '');
        });
      }
    }
    */
  //}

}
</script>


<style scoped>
.inputNumberNoSpin >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumberNoSpin >>> input::-webkit-outer-spin-button,
.inputNumberNoSpin >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.input-upper-case input{
  text-transform: uppercase
}

</style>