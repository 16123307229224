import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '../store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import setup from '../axios-intesept';


import VueMask from 'v-mask'

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  
  //if (to.matched.some(record => record.meta.requiresLogin)) {
  if (to.name != 'login') {  
    if (!store.getters.loggedIn) {
      // console.log('not logged', to.name)
      next({ name: 'login' })
    } else {
      // console.log('logged')
      next()
    }
  } else {
    // console.log('to = login')
    next()
  }


  //} else {
  //  next()
  //}
})

Vue.use(VueMask)

setup(store);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
