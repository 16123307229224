<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5  ml-0 grey--text">Prestador</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="dialog = false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>  
          </template>
          <span>Fechar</span>
          </v-tooltip>        
        </v-card-title>
               

          <v-tabs
           v-model="active_tab"
          >
          <v-tab href="#cadastro">Cadastro</v-tab>

          <v-tab-item
            value = "cadastro"
          >

          <v-card flat>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                  >
                    <v-text-field
                      label="Nome"
                      maxlength="70"
                      v-model="payload.prestadorForm.prestador.nome"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col 
                    cols="12"
                    sm="6"
                    md="4"
                    >
                    <v-text-field
                      label="CNPJ"
                      required
                      type="number"
                      maxlength="14"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      @change="validateCnpj()"
                      @blur="validateCnpj()"
                      :error-messages="cnpj_ErrorMessages"
                      v-model="payload.prestadorForm.prestador.cnpj"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      label="CNES"
                      v-model="payload.prestadorForm.prestador.cnes"
                      required
                      type="number"
                      maxlength="7"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"

                    ></v-text-field>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
          </v-card>

        </v-tab-item>

        <v-tab href="#operadoras" @click="tabChange()">Operadoras</v-tab>
          
        <v-tab-item
          value = "operadoras"
        >
          <PrestadorOperadorasTab :payload="payload" @save="saveOperadoras" />
        </v-tab-item>
        </v-tabs>




        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { getAPI } from '../../axios-api'

import PrestadorOperadorasTab from '@/components/PrestadorOperadorasTab'
export default {
  data() {
        return {
            active_tab : null,
            cnpj_ErrorMessages : []
            /*
            payloadOperadorasTab : {
              prestadorOperadoras : [],
              operadoras : [],
              tabelas : [],

            }
            */
            
        }
  },
  props: ['payload'],

  activated: function () {
    //this.getPrestadorOperadoras(this.payload.prestadorForm.prestador.id);
    // console.log('PrestadorForm.acivated')
    this.GetOperadoras();
    this.GetTabelas();
    
  }, 
  components: {PrestadorOperadorasTab},
  
  methods: {
      saveOperadoras() {
        //console.log('this.payloadOperadorasTab.prestadorOperadoras',this.payload.prestadorOperadoras)
      },

      save() {
          //this.payload.prestadorOperadoras = this.payloadOperadorasTab.prestadorOperadoras
          //this.$emit('save', this.payload);
          this.$emit('save');
      },
      tabChange() {
        //this.getPrestadorOperadoras(this.payload.prestadorForm.prestador.id);
        this.GetOperadoras();
        this.GetTabelas();

      },
        GetOperadoras() {
            
            
            getAPI.get('/api/operadoras/')
            .then(response => {
            
            //console.log('GetOperadoras()',response.data)
            //this.payloadOperadorasTab.operadoras= response.data
            this.payload.operadoras= response.data
            
            
            })
            .catch(err => {
                console.log('prestadorForm.GetOperadoras() exception: ', err)
            })
            
        },    
        GetTabelas() {
            getAPI.get('/api/tabela/')
            .then(response => {
            
            //this.payloadOperadorasTab.tabelas= response.data
            this.payload.tabelas= response.data
            
            })
            .catch(err => {
                console.log('prestadorForm.GetTabelas() exception: ', err)
            })
    

        },            
        validateCnpj() {
        
        if(!this.isNumeric(String(this.payload.prestadorForm.prestador.cnpj))) {
          this.cnpj_ErrorMessages.push('Apenas números nesse campo')
          this.payload.prestadorForm.prestador.cnpj = null
        }
        else {
          if(this.isValidCNPJ(this.payload.prestadorForm.prestador.cnpj))
            this.cnpj_ErrorMessages = []
          else {
            this.cnpj_ErrorMessages.push('CNPJ inválido')
          }
        }
        
      },
      isNumeric(str) {
        
        if (typeof str != "string") return false // we only process strings!  
        
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
              !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
              
      } ,
      isValidCNPJ(cnpj) {
 
        cnpj = cnpj.replace(/[^\d]+/g,'');
    
        if(cnpj == '') return false;
        
        if (cnpj.length != 14)
            return false;
    
        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999")
            return false;
            
        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0,tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        var i; 
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;
            
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
              return false;
              
        return true;
        
    }
  },
  computed: {
    dialog: {
      get () {
        //return this.payload.visible
        return this.payload.prestadorForm.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>