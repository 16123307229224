var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"guia-consulta"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1100"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5 grey--text"},[_vm._v("Guias Consulta Lote "+_vm._s(_vm.payload.lote.numero_lote)+" - "+_vm._s(_vm.payload.lote.operadora_nome))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-h5 grey--text",attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.payload.guiaConsulta.visible = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1),_c('v-card-text',[_c('v-row',{staticClass:"mb-3"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"9"}},[_c('v-text-field',{staticClass:"mx-4 ",attrs:{"label":"Buscar","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-simple-table',{attrs:{"height":"300","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"width":"10%"}},[_vm._v(" Guia ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Beneficiario ")]),_c('th',{staticClass:"text-left",attrs:{"width":"15%"}},[_vm._v(" Profissional ")]),_c('th',{staticClass:"text-left",attrs:{"width":"12%"}},[_vm._v(" Data ")]),_c('th',{staticClass:"text-left",attrs:{"width":"10%"}},[_vm._v(" Procedimento ")]),_c('th',{staticClass:"text-left",attrs:{"width":"8%"}},[_vm._v(" Valor ")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v(" Ações "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-10 mb-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.showModal({})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Adicionar")])])],1)])]),_c('tbody',_vm._l((_vm.filteredGuiasConsulta),function(guia){return _c('tr',{key:guia.id},[_c('td',[_vm._v(_vm._s(guia.numeroGuiaPrestador))]),_c('td',{staticClass:"maxSize100pxOrEllipsesTD"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(guia.nomeBeneficiario))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(guia.nomeBeneficiario))])])],1),_c('td',{staticClass:"maxSize100pxOrellipsesTD"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(guia.nomeProfissional))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(guia.nomeProfissional))])])],1),_c('td',[_vm._v(_vm._s(guia.dataAtendimento))]),_c('td',[_vm._v(_vm._s(guia.codigoProcedimento))]),_c('td',[_vm._v(_vm._s(guia.valorProcedimento))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"red","fab":"","text":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"primary","fab":"","text":"","small":""},on:{"click":function($event){return _vm.showModal(guia)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"primary","fab":"","text":"","small":""},on:{"click":function($event){return _vm.showModalPDF(guia)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-printer-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar Impressão")])])],1)])}),0)]},proxy:true}])})],1)],1)],1),_c('GuiaConsultaForm',{attrs:{"payload":_vm.payload},on:{"close":_vm.close,"save":_vm.save}}),_c('PrintGuiaForm',{attrs:{"payload":_vm.payload},on:{"close":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }