<template>
  <div class="login">
   <v-app id="inspire">
      <v-main>
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <img class=" mr-2" :src="require('../assets/faviconcontasmed.png')" height="40"/>
                        <v-toolbar-title>Bem vindo</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form v-on:keyup.enter.native="xlogin">
                           <v-text-field
                              prepend-icon="person"
                              name="login"
                              label="Usuário"
                              type="text"
                              v-model="username"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              prepend-icon="lock"
                              name="password"
                              label="Senha"
                              type="password"
                              v-model="password"
                              :error-messages="error_list"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <!-- <p><a href="http://127.0.0.1:8000/api/password_reset/" target="_blank" style ="margin-left:  10px;">Esqueceu a senha?</a></p> -->
                        
                         <v-btn
                           color="blue darken-1"
                           class="font-weight-light"
                           text
                           @click="showModal()"
                        >
                           <u>Esqueceu a senha?</u>
                        </v-btn>                     
                        
     
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :loading="loading" :disabled="loading" @click="xlogin()">Entrar</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
      </v-main>
   </v-app>


   
   <RecuperaSenhaForm :payload="payload"/> 
</div>
</template>

<script>
import RecuperaSenhaForm from '@/components/RecuperaSenhaForm'
export default {
   name: 'Login',
   props: {
      source: String,
   },
   components: {RecuperaSenhaForm},
    data() {
        return {
            error_list : [],
            loading: false,
            username : '',
            password: '' ,
            payload : {
               visible :false
            },
                
        }
    },   
   created: function () {
        //console.log('login()');
        this.$store.dispatch('loader', false)
    }, 

   methods: {
         showModal() {
            this.payload.visible = true;
         },
        xlogin() {
           this.loading = true;

            this.$store.dispatch( 'userLogin', {
                username: this.username,
                password: this.password
            })
            .then( () => {

               this.$store.dispatch( 'getPrestadores')
               .then( () => {

                  
                  this.$router.push({ name: 'home' })
               })
               .catch( err => {
                  this.loading = false;
                  this.error_list.push('Problemas selecionando prestador')
                  console.log('login exception 1', err)
               })               
               
            })
            .catch( err => {
               this.loading = false;
               this.error_list.push('Credenciais inválidas')
               console.log('login exception 2', err)
            })
        }

    },
};
</script>

<style></style>
