<template>
 
    <v-dialog
      v-model="dialog"
      persistent
      outlined
      max-width="600px"
      class="ma-auto"
    >
      <v-card         
        class="ma-auto"
      >
        <v-card-title>
          <span class="text-h5 grey--text">Configuração de colunas</span>
          <v-spacer></v-spacer>
          <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              
              @click="close"

            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

        </v-card-title>
        

            <v-simple-table class="elevation-1" dense ma-10 pa-10>
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left" width= "50%">
                    Coluna
                </th>
                <th class="text-left" width= "5%">
                    Visível
                </th>
                <th class="text-left" width= "45%">
                    Valor Padrão
                </th>


                </tr>
            </thead>
            <tbody>
                <tr v-for="col in Object.keys(payload.guiaConsultaForm.cols)" :key="col.index" >
                    <td
                      dense
                      hide-details  
                      class="my-0 py-0"

                    
                    
                    >
                      
                      {{payload.guiaConsultaForm.cols[col].title}}</td>
                    <td>
                      
                      <v-container
                        class="py-0"
                        fluid
                        
                      >
                        <v-switch
                          v-model="payload.guiaConsultaForm.cols[col].visible"
                          dense
                          hide-details  
                          class="my-0 py-0"
                        ></v-switch>
                      </v-container>                      
                      
                      
                    </td>
                  <td>
                    <v-autocomplete
                      label=""
                      v-model="payload.guiaConsultaForm.cols[col].valorDefault"
                      required
                      dense
                      hide-details  
                      flat
                      solo

                      class="my-0 py-0"

                      :disabled="!payload.guiaConsultaForm.cols[col].valorDefault && payload.guiaConsultaForm.cols[col].dominio.length<=1"
                      :items = "payload.guiaConsultaForm.cols[col].dominio"
                      item-text="key"
                      item-value="id"
                      type="text"
                      hide-no-data
                      maxlength="10"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      
                  ></v-autocomplete>
                </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        
      </v-card>
    </v-dialog>
 
</template>

<script>
export default {
  props: ['payload'],
  data() {
    return {
      
      //initialDate  :null,
      //finalDate : null,
      initialDateFormatted: null,
      finalDateFormatted: null,
      menu2: false,      
      menu1: false,
      dialog: false,
    }
  },

  watch: {
    
      'payload.guiaConsultaForm.GuiaConsultaFormPop1.visible' :{
        handler(newVal /*, oldVal*/) {
        //do your stuff.
        if(newVal) {
            // console.log('form dialog OPEN', newVal, oldVal)

            this.dialog = true
            
          }
        else {
          // console.log('form dialog OPEN', newVal, oldVal)
          this.$emit('close')
          this.dialog= false
          }
        

        },
        deep: true
      },
      
  },  
  methods: {

    close() {
      this.payload.guiaConsultaForm.GuiaConsultaFormPop1.visible = false
    },

  }

}
</script>