<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5 grey--text">Profissional</span>
          
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="dialog = false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn> 
          </template>
          <span>Fechar</span>
          </v-tooltip>                   
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nome"
                  required
                  maxlength="70"
                  v-model="payload.profissional.nome"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="CPF"
                  v-model="payload.profissional.cpf"
                  required
                  type="number"
                  
                  maxlength="11"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  @change="validateCpf()"
                  @blur="validateCpf()"
                  :error-messages="cpf_ErrorMessages"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >       
                <v-select
                  v-model="payload.profissional.conselho"
                  :items="items"
                  label="Conselho"
                  item-text="text"
                  item-value="id"
                  @blur="handleBlur(select)"
                ></v-select>                     
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Número"
                  v-model="payload.profissional.numero_conselho"
                  required
                  pattern="\d*"
                  maxlength="15"
                  type="number"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-select
                  label="UF"
                  v-model="payload.profissional.uf_conselho"
                  :items="uf"
                  item-text="sigla"
                  item-value="id"

                  required
                  
                  
                  
                ></v-select>   
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="8"
              >                             
              <!-- payload.profissional.cbo -->
              <v-autocomplete
                        label="CBO"
                        v-model="cbo"
                        required
                        
                        return-object
                        :items = "cbos"
                        item-text="key"
                        item-value="id"
                        @change="cboOnChange"
                        @blur = "cboOnChange"
                        type="text"
                        hide-no-data
                        maxlength="15"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        
                      >
              </v-autocomplete>

              </v-col>

            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { getAPI } from '../../axios-api'
export default {
  props: ['payload'],
  async mounted() {
    await this.GetCbo();
    this.cbo = {id: this.payload.profissional.cbo , descricao : null, key: null};
    // console.log('cbo', this.cbo)

  },
  activated() {
    this.cbo = {id: this.payload.profissional.cbo , descricao : null, key : null};
    // console.log('cbo', this.cbo)

  },
  methods: {
    cboOnChange()  {
      this.payload.profissional.cbo = this.cbo.id;
      // console.log('cboOnChange(): payload.profissional.cbo', this.payload.profissional.cbo )
    },

    async GetCbo() {
      
      let response = await getAPI.get(`/api/cbo/`);
      try {
        this.cbos= response.data;

        // "id":223815,"descricao":"Fono..."
        //  {id: null, descricao: '', key : ''},
        // key is used in the <v-autocomplete> cbo
        this.cbos.forEach(element => element.key = element.id + ' | ' + element.descricao);
      }
      catch(err) {
          console.log('guiaConsultaForm.GetCbos() exception: ', err)
      }

    },

      save() {
          
          if(!this.payload.profissional.prestador){
            this.payload.profissional.prestador = this.$store.state.idPrestadorSelecionado
            
          }
          this.$emit('save', this.payload);
      },
      handleBlur(/*e*/) {
        //console.log(e)
      },
      validateCpf() {
        
        if(!this.isNumeric(String(this.payload.profissional.cpf))) {
          this.cpf_ErrorMessages.push('Apenas números nesse campo')
          this.payload.profissional.cpf = null
        }
        else {
          if(this.isValidCPF(this.payload.profissional.cpf))
            this.cpf_ErrorMessages = []
          else {
            this.cpf_ErrorMessages.push('CPF inválido')
          }
        }
        
      },
      isNumeric(str) {
        
        if (typeof str != "string") return false // we only process strings!  
        
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
              !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
              
      } ,
      isValidCPF(number) {
          var sum;
          var rest;
          var i;
          sum = 0;
          if (number == "00000000000") return false;

          for ( i=1; i<=9; i++) sum = sum + parseInt(number.substring(i-1, i)) * (11 - i);
          rest = (sum * 10) % 11;

          if ((rest == 10) || (rest == 11))  rest = 0;
          if (rest != parseInt(number.substring(9, 10)) ) return false;

          sum = 0;
          for (i = 1; i <= 10; i++) sum = sum + parseInt(number.substring(i-1, i)) * (12 - i);
          rest = (sum * 10) % 11;

          if ((rest == 10) || (rest == 11))  rest = 0;
          if (rest != parseInt(number.substring(10, 11) ) ) return false;
          return true;
      }     
          
  },
  computed: {
    dialog: {
      get () {
        
        return this.payload.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }

      }
    }
  },
  
  watch: {
    dialog(visible) {
      if (visible) {
        // Here you would put something to happen when dialog opens up
        this.cbo = {id: this.payload.profissional.cbo , descricao : 'teste'};
        // console.log('watch cbo', this.cbo)
        
      } else {
        // console.log("Dialog was closed!")
      }
    }
  },
  data() {
        return {
          select : 10,
          cpf_ErrorMessages: [],

          // "id":223815,"descricao":"Fono..."
          cbo : {id: null, descricao: '', key : ''},
          cbos : [],

          items : [
            { id: 1,  text:'CRAS'	},
            { id: 2,  text:'COREN' },
            { id: 3,  text:'CRF'},	
            { id: 4,  text:'CRFA' },
            { id: 5,  text:'CREFITO'},
            { id: 6,  text:'CRM'	}	,
            { id: 7,  text:'CRN'	}	,
            { id: 8,  text:'CRO'	}	,
            { id: 9,  text:'CRP'	}	,
            { id: 10, text:'OUT'	}	,
            // v4.00.00
            { id: 11, text:'CRBio'}	,
            { id: 12, text:'CRBM' }	,
            { id: 13, text:'CREF' }	,
            { id: 14, text:'CRMV' }	,
            { id: 15, text:'CRTR'}	
            
            ],
          uf : [
            {id : 11, estado : 'Rondônia', sigla:'RO'},
            {id : 12, estado : 'Acre', sigla:'AC'},
            {id : 13, estado : 'Amazonas', sigla:'AM'},
            {id : 14, estado : 'Roraima', sigla:'RR'},
            {id : 15, estado : 'Pará', sigla:'PA'},
            {id : 16, estado : 'Amapá', sigla:'AP'},
            {id : 17, estado : 'Tocantins', sigla:'TO'},
            {id : 21, estado : 'Maranhão', sigla:'MA'},
            {id : 22, estado : 'Piauí', sigla:'PI'},
            {id : 23, estado : 'Ceará', sigla:'CE'},
            {id : 24, estado : 'Rio Grande do Norte', sigla:'RN'},
            {id : 25, estado : 'Paraíba', sigla:'PB'},
            {id : 26, estado : 'Pernambuco', sigla:'PE'},
            {id : 27, estado : 'Alagoas', sigla:'AL'},
            {id : 28, estado : 'Sergipe', sigla:'SE'},
            {id : 29, estado : 'Bahia', sigla:'BA'},
            {id : 31, estado : 'Minas Gerais', sigla:'MG'},
            {id : 32, estado : 'Espírito Santo', sigla:'ES'},
            {id : 33, estado : 'Rio de Janeiro', sigla:'RJ'},
            {id : 35, estado : 'São Paulo', sigla:'SP'},
            {id : 41, estado : 'Paraná', sigla:'PR'},
            {id : 42, estado : 'Santa Catarina', sigla:'SC'},
            {id : 43, estado : 'Rio Grande do Sul', sigla:'RS'},
            {id : 50, estado : 'Mato Grosso do Sul', sigla:'MS'},
            {id : 51, estado : 'Mato Grosso', sigla:'MT'},
            {id : 52, estado : 'Goiás', sigla:'GO'},
            {id : 53, estado : 'Distrito Federal', sigla:'DF'},
            {id : 98, estado : 'Países Estrangeiros', sigla:'EX'}
          ],


        }
  }
}
</script>