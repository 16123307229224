<template>
  <div class="usuarios">
    <v-card
        class="mx-auto"
        max-width="800"
        outlined
    >
        <v-card-title>
          <span class="text-h5 grey--text maxSize30pxOrEllipses">Usuários <span class="text-h5 indigo--text ">{{nomePrestadorSelecionado}} </span></span>
          <v-spacer></v-spacer>
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="close()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

        </v-card-title>
        <v-row>
        <v-col class="text-left" cols="9">
            
            <v-text-field
                label="Buscar"
                class="mx-4 "
                  
                v-model="search"
                append-icon="mdi-magnify"
                
                single-line
                hide-details
            ></v-text-field>
        </v-col>
        <v-col class="text-right">
            <v-btn
                class="mr-4 mt-4 mb-3"
                fab
                dark
                color="primary"
                
                @click="showModal({})" 
                >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>
        </v-col>
        </v-row>
        <v-simple-table
            height = "300" 
            fixed-header
            dense
        >
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">
                    Nome
                </th>
                <th class="text-left"  width= "10%">
                    Tipo
                </th>
                <th class="text-left"  width= "10%">
                    Situação
                </th>
                
                <th class="text-left"  width= "35%">
                    Email
                </th>
                <th class="text-left" width= "10%">
                    Ações
                </th>

                </tr>
            </thead>
            <tbody>
                <tr v-for="usuario in usuariosFiltrados" :key="usuario.id" >
                    
                    <td class="maxSize100pxOrEllipsesTD"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{usuario.username}}</span>
                        </template>
                        <span>{{usuario.username}}</span>
                        </v-tooltip>                    
                    </td>

                    <td>{{usuario.tipo_usuario ==1 ? 'Admin' : 'Básico'}}</td>
                    <td>{{usuario.is_active ? 'Ativo' : 'Inativo'}}</td>

                    <td class="maxSize100pxOrEllipsesTD"
                    >
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{usuario.email}}</span>
                        </template>
                        <span>{{usuario.email}}</span>
                        </v-tooltip>                    
                    </td>
                    
                    <td>   
                        <v-btn 
                            color="primary" 
                            class="ma-0 pa-0 white--text"
                            fab
                            text
                            small
                            @click="showModal(usuario)"
                        >
                        <v-icon >
                            mdi-pencil
                        </v-icon>
                        </v-btn> 
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>

    <UsuarioForm :payload="payload" @close="payload.visible=false" @save="save" /> 
  </div>
  
</template>

<script>
import UsuarioForm from '@/components/UsuarioForm'
import { getAPI } from '../../axios-api'
import router from '../router'

export default {
    components: {UsuarioForm},
    data() {
        return {
            nomePrestadorSelecionado: '',
            usuarios: [] ,
            search : '',

            payload : {
                visible : false,
                usuario : {}
            }               
            
            
        }
    },

    created: function () {
        this.getUsuarios();
    }, 

    computed : {
        
        usuariosFiltrados : function() {
            return this.usuarios.filter((record) => {
                return record.username.toLowerCase().trim().match(this.search.toLowerCase().trim())
            })
        },
      
    },
    methods: {
        getUsuarios() {
            let prestadoresList = this.$store.getters.prestadores

            let prestadorId = this.$store.getters.getParamFromPrestadorListItemToUsuariosIdPrestador;

            var prestadorParametro = prestadoresList.find(function(current) {
                
                if(current.id == prestadorId) {
                    return true;
                }
            });

            if(prestadorParametro) {
              this.nomePrestadorSelecionado = prestadorParametro.nome;
            }
            else {
                console.log('usuarios.getUsuarios() exception: ', 'nao identificou prestador')
            }
            // this.$store.state.idPrestadorSelecionado
            getAPI.get(`/api/get-usuarios/?prestador=${prestadorId}`)
            .then(response => {

                this.usuarios= [...JSON.parse(response.data)];
            
            })
            .catch(err => {
                console.log('usuarios.getUsuarios() exception: ', err)
            })
            
        },
        close() {
            router.push({name: 'prestadores'}).catch(err => {console.log('usuarios.close() exception: ',err)});
        }, 
        save() {
            
            this.payload.visible=false
            let usuario = this.payload.usuario
            usuario.prestador =  this.$store.getters.getParamFromPrestadorListItemToUsuariosIdPrestador;// this.$store.state.idPrestadorSelecionado

            if( !usuario.id ) {
                this.addUsuario(usuario);        
            } else {
                this.editUsuario(usuario);
            }

            //this.operadora = payload.operadora
            // console.log(this.operadora)
            //this.CheckRecord();
        },
        addUsuario(usuario) {
            getAPI.post("/api/add-usuario/", usuario)
                .then(() => {
                    
                    //this.profissionais.push( res.data);
                    this.getUsuarios();
                    
                })
                .catch(function (error) {
                    console.log('usuarios.addUsuario() exception: ',error);
                });
                
        },
        editUsuario(usuario) {
            getAPI.put("/api/edit-usuario/", usuario)
                .then(() => {
                    
                    //this.profissionais.push( res.data);
                    this.getUsuarios();
                    
                })
                .catch(function (error) {
                    console.log('usuarios.editUsuario() exception: ',error);
                });
                
        },

        showModal(usuario) {
            this.payload.usuario= JSON.parse(JSON.stringify(usuario)); 
            
            
            this.payload.visible=true
        },



    }
}

</script>
<style scoped>

 .maxSize100pxOrEllipsesTD /deep/   {
 max-width: 100px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}

 .maxSize30pxOrEllipses /deep/   {
 max-width: 487px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
</style>