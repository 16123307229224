<template>
  <div class="lotes">
    <v-card
        class="mx-auto"
        max-width="800"
        outlined
    >
        <v-card-title>
          <span class="text-h5 grey--text">Lotes</span>
          <v-spacer></v-spacer>
           <v-tooltip bottom>
           <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="close()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </template>
            <span>Fechar</span>
            </v-tooltip>

        </v-card-title>
        <v-row>
        <v-col class="text-left mt-0 pt-0" cols="9">
            
            <v-text-field
                label="Buscar"
                class="mx-4 mt-0"
                  
                v-model="search"
                append-icon="mdi-magnify"
                
                single-line
                hide-details
            ></v-text-field>
        </v-col>
        <v-col class="text-right">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mr-4 mt-2 mb-1"
                fab
                dark
                color="primary"
                
                @click="showModal({})" 
                v-bind="attrs"
                v-on="on"
                >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>
            </template>
            <span>Adicionar</span>
            </v-tooltip>
        </v-col>
        </v-row>
        <v-simple-table 
            height = "267" 
            fixed-header
            dense
        > 
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left"  width= "5%">
                    Lote
                </th>
                <th class="text-left">
                    Operadora
                </th>
                <th class="text-left"  width= "8%">
                    Tipo Guia
                </th>
                <th class="text-left"  width= "8%">
                    Data Inicial
                </th>           
                <th class="text-left"  width= "8%">
                    Data Fim
                </th>                     
                <th class="text-left"  width= "30%">
                    Ações
                </th>

                </tr>

            </thead>
            <tbody>
                <tr v-for="lote in filteredlotes" :key="lote.id" >
                    <td>{{lote.numero_lote}}</td>
                    <td>{{lote.operadora_nome}}</td>
                    <td>{{lote.tipo_guia_tipo }}</td>
                    <td>{{formatDate(lote.data_inicial)}}</td>
                    <td>{{formatDate(lote.data_final)}}</td>
                    <td>  
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }"> 
                        <v-btn
                           
                            color="red"
                            class="ma-0 white--text"
                            icon
                            small
                             text
                             @click="DeleteRecord(lote)"
                             v-bind="attrs"
                             v-on="on"
                            >
                            <v-icon >
                                mdi-close
                            </v-icon>
                     
                        </v-btn>
                        </template>
                        <span>Excluir</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            icon
                            small
                            text
                            @click="showModal(lote)"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon >
                            mdi-pencil
                        </v-icon>
                        </v-btn> 
                        </template>
                        <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            icon
                            small
                            text
                            @click="selectGuia(lote)"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon >
                            mdi-view-dashboard-outline
                        </v-icon>
                        </v-btn>
                        </template>
                        <span>Guias</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            
                            text
                            icon
                            small
                            @click="showModalEmiteProtocolo(lote.id)"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon >
                            mdi-text-box-check-outline
                        </v-icon>
                        </v-btn>  
                        </template>
                        <span>Emitir protocolo</span>
                        </v-tooltip>  
                
                        <v-menu offset-y>
                        <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">    
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"

                            text
                            icon
                            small
                            
                        >
                        <v-icon >
                            mdi-file-code-outline
                        </v-icon>
                        </v-btn>
                    </template>
                    <span>Gerar xml</span>
                    </v-tooltip>
                    
                        </template>
                        <v-list>
                            <v-list-item
                            v-for="(item, index) in xmlVersions"
                            :key="index"
                            @click="viewXml(lote, xmlVersions[index])"
                            >
                            <v-list-item-title>{{ item }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                        </v-menu>    

                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 white--text"
                            icon
                            small
                            text
                            @click="showModalImprimirGuias(lote.id)"
                            v-bind="attrs"
                            v-on="on"
                            
                        >
                        <v-icon >
                            mdi-printer-eye
                        </v-icon>
                        </v-btn>   
                        </template>
                        <span>Imprimir Guia</span>
                        </v-tooltip>                                                        
                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>
    
    <LoteForm :payload="payload" @close="payload.loteForm.visible=false" @save="save" /> 
    <GuiaConsulta :payload="payload" @close="payload.guiaConsulta.visible=false" @save="save" />
    <GuiasSpSadt :payload="payload" @close="payload.guiasSpSadt.visible=false" @save="save" />
    <RelatorioForm :payload="payload" @close="payload.visible=false" />
    <XmlViewForm :payload="payload" @close="payload.xml.visible=false"/>
<!--     
    <v-alert 
      type="info"
      v-model="alert1"
      dismissible
      max-width="250"
    >
      SP/SADT ainda indisponível
    </v-alert> -->

   <v-snackbar
      v-model="alert"
    >
      SP/SADT ainda indisponível

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="alert = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>    
  </div>
  
</template>
<script>

import { getAPI } from '../../axios-api'
import router from '../router'

//import { mapState } from 'vuex'
import LoteForm from '@/components/LoteForm'
import GuiaConsulta from '@/components/GuiaConsulta'
import GuiasSpSadt from '@/components/GuiasSpSadt'

import RelatorioForm from '@/components/RelatorioForm'
import XmlViewForm from '@/components/XmlViewForm'

export default {
    data() {
        return {
            alert : false,
            lotes: [] ,
            lote:{},
            search : '',
            xmlVersions: ['3.05.00', '4.00.00', '4.00.01', '4.01.00'],
            payload : {
                loteForm : {visible : false},
                lote : {},
                prestadorOperadoras : [],
                prestadorSelecionado  :{},
                tipoGuia: [{tipo : null}],
                tipoGuiaSelecionada : {},

                guiasSpSadt : {
                    visible : false,
                    guias : [],
                    form : {
                        visible : false,
                        guia: {}
                    }
                },

                guiaConsulta : {
                    visible : false,
                    guiasConsulta : []
                },
                
                guiaConsultaForm : {
                    visible : false,
                    guia : {},
                    cols: {
                        numeroCNS: {
                            id: 1,
                            title: 'NumeroCNS', 
                            visible : false,
                            dominio :[{id: null, key: 'manter vazio'}],
                            valorDefault: null
                        },
                        observacao: {
                            id: 2,
                            title : 'Observação',
                            visible : false,
                            dominio :[{id: null, key: 'manter vazio'}],
                            valorDefault: null
                        },

                        atendimentoRN: {
                            id: 3,
                            title : 'RN',
                            visible : false,
                            dominio :[
                                {id: 'N', key: 'N'},
                                {id: 'S', key: 'S'},
                                
                            ],
                            valorDefault: 'N'                            
                        },

                        indicacaoAcidente: {
                            id: 4,
                            title : 'Indicação Acidente',
                            visible : false,
                            dominio :[
                                {id : 0, key: '0 | Trabalho'}, 
                                {id : 1, key: '1 | Trânsito'}, 
                                {id : 2, key: '2 | Outros Acidentes'}, 
                                {id : 9, key: '9 | Não Acidentes'}
                            ],
                            valorDefault: 9                            

                        },

                        regimeAtendimento: {
                            id: 5,
                            title : 'Regime de atendimento',
                            visible : false,
                            dominio : [
                                { id: '01', key: '1 | Ambulatorial'},
                                { id: '02', key: '2 | Domiciliar' },
                                { id: '03', key: '3 | Internação'},
                                { id: '04', key: '4 | Pronto-socorro'},
                                { id: '05', key: '5 | Telessaúde'}
                            ],
                            valorDefault: '01'                            

                        },

                        saudeOcupacional: {
                            id: 6,
                            title : 'Saúde ocupacional',
                            visible : false,
                            dominio : [
                                { id: null, key: 'manter vazio'},
                                { id: '01', key: '1 | Admissional'},
                                { id: '02', key: '2 | Demissional'},
                                { id: '03', key: '3 | Periódico'},
                                { id: '04', key: '4 | Retorno ao trabalho'},
                                { id: '05', key: '5 | Mudança de função'},
                                { id: '06', key: '6 | Promoção à saúde'}
                            ],
                            valorDefault: null                            

                        },

                        coberturaEspecial: {
                            id: 7,
                            title : 'Cobertura especial',
                            visible : false,
                            dominio : [
                                { id:null, key: 'manter vazio'},
                                { id:'01', key: '1 | Gestante'},
                                { id:'02', key: '2 | Pré-operatório'},
                                { id:'03', key: '3 | Pós-operatório'}
                            ],
                            valorDefault: null                            

                        },

                        numeroConselhoProfissionalAuto: {
                            id: 8,
                            title : 'Número conselho profissional',
                            visible : false,
                            dominio : [{id: null, key: 'automático'}],
                            valorDefault: null                            

                        },

                        tipoConsulta: {
                            id: 9,
                            title : 'Tipo consulta',
                            visible : false,
                            dominio : [
                                {id: 1, key: '1 | Primeira'}, 
                                {id: 2, key: '2 | Seguimento'}, 
                                {id: 3, key: '3 | Pré-Natal'}, 
                                {id: 4, key: '4 | Por encaminhamento'}
                                
                            ],
                            valorDefault: 1                            

                        },

                        codigoTabelaAuto: {
                            id: 10,
                            title : 'Tabela de procedimento',
                            visible : false,
                            dominio : [{id: null, key: 'automático'}],
                            valorDefault: null                            

                        },

                        botaoFechar: {
                            id: 11,
                            title : 'Botão fechar',
                            visible : false,
                            dominio : [{id: null, key: 'não se aplica'}],
                            valorDefault: null                            

                        },


                    },
                    GuiaConsultaFormPop1: {
                        visible : false
                    }
                },
                guiaPDF : {
                    guia : {},
                    visible : false
                },
                
                xml : {
                    visible : false,
                    lote: null,
                    xml: null,
                    fileName : null,
                    version: null
                },
                relatorio : {},
                api : '',
                visible : false  // show protocolo
            }    
            
            
            
        }
    },
    components: {LoteForm, GuiaConsulta, GuiasSpSadt, RelatorioForm, XmlViewForm},
    
    mounted() {
        this.$nextTick(async  function () {
            // Code that will run only after the
            // entire view has been rendered
            this.GetLotes();
            this.getPrestadorOperadoras();
            await this.getTipoGuia();

        })
    },

    computed : {
        
        filteredlotes : function() {
            return this.lotes.filter((record) => {
                
                return (record.id !=null) 
            })
        },
        //...mapState(['APIData'])
    },
    methods: {
        close() {
            router.push({name: 'home'}).catch(err => {console.log('navbar.onChangeSelection() exception: ',err)});
        }, 

        // old version to save xml directly
        downloadSaveAs(lote) {
            //window.open("http://127.0.0.1:8000/api/xml")
            // https://stackoverflow.com/questions/53772331/vue-html-js-how-to-download-a-file-to-browser-using-the-download-tag
            // https://base.url?access_token=f4f4994a875f461ca4d7708b9e027df4

            //const url=`http://127.0.0.1:8000/api/xml/?lote=${lote.id}`;
            const url=`https://contasmedicas.net/fat/api/xml/?lote=${lote.id}`;
            window.location.href = url
            
            //window.location.href = "https://contasmedicas.net/fat/api/xml";
        },
        selectGuia(lote) {
            if (lote.tipo_guia_tipo=='Consulta') {
                
                this.showGuiaConsulta(lote)
            }
            else if (lote.tipo_guia_tipo=='Hon.Individual'){
                this.alert = true
                
            }
            else {
                this.showGuiaSpSadt(lote)
            }
        },

        viewXml(lote, version) {

            this.payload.xml.version = version;
            this.payload.lote = lote;
            this.payload.xml.visible = true;
            /*
            , xmlVersions[index
            getAPI.get(`/api/xml/?lote=${lote.id}`)
            .then(response => {
            
            let payLoad = JSON.parse(response.data)
            let blob = new Blob([window.atob(payLoad.base64_xml)], {type: 'text/xml'});
            let url = URL.createObjectURL(blob);
            
            window.open(url);
            URL.revokeObjectURL(url); //Releases the resources            
            
            })
            .catch(err => {
                console.log('lotes.donwload() exception: ',err)
            })
            */
        },
        showGuiaSpSadt(lote) {
            const prestadorOperadora = lote.prestador_operadora
            

            var findResult = this.payload.prestadorOperadoras.find(function(current) {
              
                if(current.id == prestadorOperadora)
                    return true;
            });
            if(findResult) {
              lote.prestador_operadora_obj = JSON.parse(JSON.stringify(findResult));
              
            }
            

            this.payload.lote= JSON.parse(JSON.stringify(lote));

            this.payload.guiasSpSadt.visible = true;
        },
        showGuiaConsulta(lote) {
            // await this.getGuiaConsulta(lote.id)
            
            const prestadorOperadora = lote.prestador_operadora
            

            var findResult = this.payload.prestadorOperadoras.find(function(current) {
              
                if(current.id == prestadorOperadora)
                    return true;
            });
            if(findResult) {
              lote.prestador_operadora_obj = JSON.parse(JSON.stringify(findResult));
              
            }
            

            this.payload.lote= JSON.parse(JSON.stringify(lote));
            
            this.payload.guiaConsulta.visible = true
        },
        async showModal(lote) {
            if(!lote.id)  {
                lote.prestador = this.$store.state.idPrestadorSelecionado
                lote.numero_lote =  await this.getNextLote()
                lote.prestador_operadora_obj = this.payload.prestadorOperadoras[0]
  
                lote.tipo_guia_obj = this.payload.tipoGuia[0]
            }
            else {
                
                //lote.prestador_operadora.operadora_nome = lote.operadora_nome
                lote.prestador_operadora_obj = {id: lote.prestador_operadora}
                lote.tipo_guia_obj = {id: lote.tipo_guia}
                
  
            }
            this.payload.lote= JSON.parse(JSON.stringify(lote)); 
            
            
            this.payload.loteForm.visible=true
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        save() {
            
            this.payload.loteForm.visible=false
            this.lote =this.payload.lote
            this.lote.prestador_operadora = this.payload.lote.prestador_operadora_obj.id

            
            this.lote.tipo_guia = this.payload.lote.tipo_guia_obj.id

            //delete this.lote.prestador_operadora_obj
            //delete this.lote.tipo_guia_obj
            //delete this.lote.operadora_nome
            //delete this.lote.tipo_guia_tipo

            

            this.CheckRecord();
        },


        CheckRecord() {
            if( this.lote.id === undefined ) {
                this.CreateRecord();        
            } else {
                this.EditRecord();
            }
        },
        GetLotes() {
            
            
            getAPI.get(`/api/get-lotes/?prestador=${this.$store.state.idPrestadorSelecionado}`)
            .then(response => {
            
            this.lotes= [...JSON.parse(response.data)]
            
            })
            .catch(err => {
                console.log('lotes.getLotes() exception: ',err)
            })
            
        },
        CreateRecord() {
            
            getAPI.post("/api/lotes/", this.lote)
                .then(() => {
                    this.GetLotes();
                    this.lote = {};
                })
                .catch(function (error) {
                    console.log( 'lotes.CreateRecord() exception', error);
                });
                
        },
        EditRecord() {
            
            getAPI.put(`/api/lotes/${this.lote.id}/`, this.lote)
                .then(() => {
                    this.GetLotes();
                    this.lote = {};
                })
                .catch(function (error) {
                    console.log('lotes.EditRecord() exception: ', error);
                });
                

        },
        DeleteRecord(lote) {
            
            getAPI.delete(`/api/lotes/${lote.id}/`)
                .then(() => {
                    this.GetLotes();
                })
                .catch(function (error) {
                    console.log('lotes.DeleteRecord() exception: ', error);
                });

        },
        getPrestadorOperadoras() {
                
        
            getAPI.get(`/api/get-prestador-operadora/?prestador=${this.$store.state.idPrestadorSelecionado}`)
            .then(response => {
            
            
            this.payload.prestadorOperadoras = [...JSON.parse(response.data)]
            
            
            })
            .catch(err => {
                console.log('lotes.getPrestadorOperadoras() exception: ', err)
            })
        


        },      
        
        async getNextLote() {
            try{
                let response = await getAPI.get(`/api/get-next-lote/${this.$store.state.idPrestadorSelecionado}/`)
                return response.data.nextLote;
            }catch(err){
                console.log('lotes.getNextLote() exception: ', err)
            }                

        },      
        async getTipoGuia() {
            
            try{
                let response = await getAPI.get('/api/get-tipo-guia/')
                this.payload.tipoGuia = response.data
            }catch(err){
                console.log('lotes.getTipoGuia() exception: ', err)
            }            
            
        },
        showModalEmiteProtocolo(loteId) {
            // this.payload.relatorio
            this.payload.api = `/api/pdf/protocolo/${loteId}/`
            this.payload.relatorio = {id : 1, nome : 'RELAÇÃO DE GUIAS'}
            this.payload.visible=true
        },
        showModalImprimirGuias(loteId) {
            // this.payload.relatorio
            this.payload.api = `/api/print-guias-consulta/${loteId}/`
            this.payload.relatorio = {id : 2, nome : 'IMPRESSÃO DE GUIAS'}
            this.payload.visible=true
        },

        /*
        async getGuiaConsulta(loteId) {
            
            try{
                let response = await getAPI.get(`/api/guia-consulta/?lote=${loteId}`)
                this.payload.guiaConsulta.guiasConsulta = response.data
            }catch(err){
                console.log(err)
            }            
            
        }
        */

    },
    
    
}
</script>