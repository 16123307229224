import Vue from 'vue'
import Vuex from 'vuex'
import { getAPI } from './axios-api'
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    accessToken: null,
    refreshToken: null,
    APIData: '',
    idPrestadorSelecionado : null,
    paramFromPrestadorListItemToUsuariosIdPrestador : null,
    globalLoading : false,
    globalReconnecting : false,
    prestadorList : [],
    guiaConfigList: [],
    guiaSadtConfigList: []
  },
  
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 3, secure: true }),
        removeItem: (key) => Cookies.remove(key)
      }
    })
  ],
  mutations: {
    updateStorage (state, { access, refresh }) {
      state.accessToken = access
      state.refreshToken = refresh
    },
    destroyToken (state) {
      state.accessToken = null
      state.refreshToken = null
    },
    selectPrestador (state, id) {
      state.idPrestadorSelecionado = id
    },
    updateParamFromPrestadorListItemToUsuariosIdPrestador (state, id) {
      state.paramFromPrestadorListItemToUsuariosIdPrestador = id
    },
    
    updatePrestadorList (state, list) {
      state.prestadorList = list
    },

    updateGuiaConfigList (state, list) {
      state.guiaConfigList = list
    },

    updateGuiaSadtConfigList (state, list) {
      state.guiaSadtConfigList = list
    },

    loaderToggle (state, status) {
      state.globalLoading = status
    },
    reconnectToggle (state, status) {
      state.globalReconnecting = status
    }

  },
  getters: {
    loggedIn (state) {
      return state.accessToken != null
    },

    prestadores (state) {
      return state.prestadorList
    },

    getGuiaConfigList (state) {
      return state.guiaConfigList
    },
    getGuiaSadtConfigList (state) {
      return state.guiaSadtConfigList
    },
    getParamFromPrestadorListItemToUsuariosIdPrestador (state) {
      return state.paramFromPrestadorListItemToUsuariosIdPrestador
    }

    
  },
  actions: {
    userLogout (context) {
      if (context.getters.loggedIn) {
          context.commit('destroyToken')
      }
    },
    updateToken (context, newtoken) {
      context.commit('updateStorage', { access: newtoken.access, refresh: newtoken.refresh }) 
    },
    updatePrestador (context, newId) {
      context.commit('selectPrestador', newId) 
    },
    updateParamFromPrestadorListItemToUsuariosIdPrestador (context, newId) {
      context.commit('updateParamFromPrestadorListItemToUsuariosIdPrestador', newId) 
    },
    

    updateGuiaConfigList (context, list) {
      context.commit('updateGuiaConfigList', list) 
    },

    updateGuiaSadtConfigList (context, list) {
      context.commit('updateGuiaSadtConfigList', list) 
    },

    userLogin (context, usercredentials) {
      return new Promise((resolve, reject) => {
        getAPI.post('/api/token/', {
          username: usercredentials.username,
          password: usercredentials.password
        })
          .then(response => {
            context.commit('updateStorage', { access: response.data.access, refresh: response.data.refresh }) 
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getPrestadores(context) {
      return new Promise((resolve, reject) => {
        getAPI.get('/api/prestadores/')
          .then(response => {
            context.commit('updatePrestadorList', response.data) 
            resolve()
          })
          .catch(err => {
            reject(err)
          })
      })      
    },
    loader (context, status) {
      context.commit('loaderToggle', status) 
    },
    reconnect (context, status) {
      context.commit('reconnectToggle', status) 
    },

  }
})