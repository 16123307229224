<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5 grey--text">Tabela</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
           <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="dialog = false"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>  
          </template>
          <span>Fechar</span>
          </v-tooltip>                  
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="3"
                md="3"
              >
                <v-text-field
                  label="Procedimento"
                  required
                  type="number"
                  maxlength="14"
                  disabled
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  v-model="payload.record.procedimento"
                ></v-text-field>
              </v-col>
              
              <v-col 
                cols="12"
                sm="12"
                md="12"
                >
                <v-text-field
                  label="Descrição"
                  required
                  maxlength="70"
                  
                  v-model="payload.record.descricao"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
              >
                <v-text-field
                  label="CH"
                  v-model="payload.record.ch"
                  required
                  type="number"
                  maxlength="10"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
              >
                <v-text-field
                  label="CO"
                  v-model="payload.record.co"
                  required
                  type="number"
                  maxlength="10"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
              >
                <v-text-field
                  label="Filme"
                  v-model="payload.record.filme"
                  required
                  type="number"
                  maxlength="10"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
                md="3"
              >
                <v-text-field
                  label="Outros"
                  v-model="payload.record.outros"
                  required
                  type="number"
                  maxlength="10"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ['payload'],
  methods: {
      save() {
          // console.log('executed save()');
          // console.log(this.payload)
          this.$emit('save', this.payload);
      }

  },
  computed: {
    dialog: {
      get () {
        return this.payload.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>