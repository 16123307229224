<template>
  <v-autocomplete
    label=""
    v-model="vmodel.procedimento"
    :disabled="disabled"
    required
    :rules="[(v) => !!(v.id && v.id !='undefined') || 'Campo obrigatório']"
    dense
    return-object
    :search-input.sync="searchProcedimento"
    :items = "vmodel.procedimentos"
    item-text="text"
    item-value="id"
    hide-details
    type="text"
    
    hide-no-data
    @change="procedimentoOnChange"
    @blur="procedimentoOnChange"
    maxlength="70"
    class = " pl-1"
      
  ></v-autocomplete>

</template>

<script>
import { getAPI } from '../../axios-api'

export default {
  props: ['payload','vmodel', 'disabled'],

  data() {
    return {
   
      procedimentoSearchQuery : ''
      
    } // data return
     
  }, // data
  mounted() {
  },
  computed: {
    searchProcedimento: {
      get () {
        return this.procedimentoSearchQuery 
      },
      
      set (searchInput) {
        if (searchInput && this.procedimentoSearchQuery  !== searchInput) {
          this.procedimentoSearchQuery  = searchInput
          this.GetTabela(this.payload.lote.prestador_tabela, 1, searchInput) 
        }
      }
    }
  },    

  
  methods: {
    
    procedimentoOnChange() {   
    },
    GetTabela(tabela=1, page=1, descricao='') {
      let parameters = `tabela=${tabela}&page=${page}`
      if (descricao != '') {
          if (descricao.indexOf('|') > -1){
            descricao = descricao.substring(0, descricao.indexOf('|')  )
          }
          parameters += `&descricao=${descricao}`
      } 
      // console.log('descricao:', descricao)
      getAPI.get(`/api/tabela-detalhe/?${parameters}`,{'silentMode': 1})
      .then(response => {
        /*
        {"count":5749,"next":"http://127.0.0.1:8000/api/tabela-detalhe/?page=2&tabela=1",
        "previous":null,"results":[{"id":1,"procedimento":10101012,"descricao
        */
        
        response.data.results.forEach(row => {
          row.text = row.procedimento + ' | ' + row.descricao
        }); 
                    
        this.vmodel.procedimentos= response.data.results.slice(0, 5)
      })
      .catch(err => {
          console.log('guiaConsultaForm.GetTabela() exception: ', err)
      })
    },
    
  }
}
</script>