<template>
  <div class="prestadores">
    <v-card
        class="mx-auto"
        max-width="750"
        outlined
    >
        <v-card-title>
          <span class="text-h5 grey--text">Prestadores</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              dark
              
              class="text-h5 grey--text"
              @click="close()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            </template>
            <span>Fechar</span>
            </v-tooltip>

        </v-card-title>
        <v-row>
        <v-col class="text-left" cols="9">
            
            <v-text-field
                label="Buscar"
                class="mx-4 "
                  
                v-model="search"
                append-icon="mdi-magnify"
                
                single-line
                hide-details
            ></v-text-field>
        </v-col>
        <v-col class="text-right">
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mr-4 mt-4 mb-1"
                fab
                dark
                color="primary"
                
                @click="showModal({})" 
                v-bind="attrs"
                v-on="on"
                >
                <v-icon dark>
                    mdi-plus
                </v-icon>
            </v-btn>
            </template>
            <span>Adicionar</span>
            </v-tooltip>
        </v-col>
        </v-row>
        <v-simple-table
            height = "200" 
            fixed-header
            dense
        >
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left">
                    Nome
                </th>
                <th class="text-left"  width= "15%">
                    CNPJ
                </th>
                  <th class="text-left" width= "10%">
                    cnes
                </th>
                <th class="text-left"  width= "25%">
                    Ações
                </th>

                </tr>
            </thead>
            <!-- <td class = "prestadorTD" :title="prestador.nome">{{prestador.nome}}</td> -->
            <tbody>
                <tr v-for="prestador in filteredprestadores" :key="prestador.id" >
                    <td class = "prestadorTD" >
                        
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span  v-bind="attrs" v-on="on">{{prestador.nome}}</span>
                        </template>
                        <span>{{prestador.nome}}</span>
                        </v-tooltip>
                        
                    </td>
                    
                    <td>{{prestador.cnpj}}</td>
                    <td>{{prestador.cnes}}</td>
                    <td>   
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn
                           
                            color="red"
                            class="ma-0 pa-0 white--text"
                            fab
                            small
                             text
                             @click="DeleteRecord(prestador)"
                             v-bind="attrs"
                             v-on="on"
                            >
                            <v-icon >
                                 mdi-close
                            </v-icon>
                     
                        </v-btn>
                        </template>
                        <span>Excluir</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 pa-0 white--text"
                            fab
                            small
                            text
                            @click="showModal(prestador)"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon >
                            mdi-pencil
                        </v-icon>
                        </v-btn> 
                        </template>
                        <span>Editar</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            color="primary" 
                            class="ma-0 pa-0 white--text"
                            fab
                            small
                            text
                            @click="showModalUsuarios(prestador)"
                            v-if="$store.state.prestadorList.length>1"
                            v-bind="attrs"
                            v-on="on"
                        >
                        <v-icon >
                            mdi-account-settings
                        </v-icon>
                        </v-btn> 
                        </template>
                        <span>Usuários</span>
                        </v-tooltip>

                    </td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>

    <prestadorForm :payload="payload" @close="payload.prestadorForm.visible=false" @save="save" />
  </div>
  
</template>
<script>
import { getAPI } from '../../axios-api'
import router from '../router'

//import { mapState } from 'vuex'
import PrestadorForm from '@/components/PrestadorForm'
export default {
    data() {
        return {
    
            prestador : {},
            prestadores: [] ,
            
            search : '',

            payload : {
                prestadorForm : {visible : false, prestador : {}},
				operadorasTab : {visible : false},
				prestadorOperadorasTabForm : {visible : false, operadora : {}},
                prestador : {},
				operadoras : [],
				tabelas : [],

                prestadorOperadoras : [],
				operadora : {},
            }  
            
            
        }
    },
    components: {PrestadorForm},
    created: function () {
        this.GetPrestadores();
        this.getPrestadorOperadoras();
    }, 

    computed : {
        
        filteredprestadores : function() {
            return this.prestadores.filter((record) => {
                return record.nome.toLowerCase().trim().match(this.search.toLowerCase().trim())
            })
        },
        //...mapState(['APIData'])
    },
    methods: {
        close() {
            router.push({name: 'home'}).catch(err => {console.log('navbar.onChangeSelection() exception: ',err)});
        }, 

          save() {  

            // console.log('Prestadores.payload.prestadorOperadoras.forEach')
            this.payload.prestadorOperadoras.forEach(element => console.log(element));

            this.payload.prestadorForm.visible=false
            //this.prestador = payload.prestador
            this.prestador = this.payload.prestadorForm.prestador;
            
            this.CheckRecord();
            
            
            //this.$router.go()
            //window.location.reload()
            
            
        },
        showModalUsuarios(prestador) {
            this.$store.dispatch('updateParamFromPrestadorListItemToUsuariosIdPrestador', prestador.id)       
            
            router.push({name: 'usuarios'}).catch(err => {console.log('usuarios.showModal() exception: ',err)});
        },
        showModal(prestador) {
            this.payload.prestadorForm.prestador= JSON.parse(JSON.stringify(prestador)); 
            // console.log('this.payload.prestadorOperadoras',this.payload.prestadorOperadoras)
            this.payload.prestadorForm.visible=true
        },
        CheckRecord() {
            if( !this.prestador.id ) {
                this.CreateRecord();        
            } else {
                this.EditRecord();
            }
        },
        GetPrestadores() {
            
            
            getAPI.get('/api/prestadores/')
            .then(response => {
            
            this.prestadores= response.data
            
            
            })
            .catch(err => {
                console.log('prestadores.GetPrestadores() exception: ', err)
            })
            
        },
        savePrestadorOperadoras() {
            
            this.payload.prestadorOperadoras.forEach(prestadorOperadora => {
                delete prestadorOperadora.operadora_nome;
                delete prestadorOperadora.tabela_nome;

                // in memory only
                if(prestadorOperadora.id<=0) {
                    delete prestadorOperadora.id;
                    
                    if(prestadorOperadora.isDeleted) {
                        //console.log('Prestadores prestadorOperadora isDeleted - record in memory only',prestadorOperadora);

                    }
                    else {
                        // console.log('Prestadores prestadorOperadora POST',prestadorOperadora);
                        getAPI.post("/api/prestador-operadora/", prestadorOperadora)
                        .then(() => {
                            
                           // console.log('POST /api/prestador-operadora/ : ok');
                        })
                        .catch(function (error) {
                            console.log('prestadores.savePrestadorOperadoras() exception: ', error);
                        });                
                    }

                }
                else {
                    if(prestadorOperadora.isDeleted) {

                        // console.log('Prestadores prestadorOperadora DELETE',prestadorOperadora);
                        getAPI.delete(`/api/prestador-operadora/${prestadorOperadora.id}/`)
                        .then(() => {
                            
                            //console.log('DELETE /api/prestador-operadora/ : ok');
                        })
                        .catch(function (error) {
                            console.log('prestadores.savePrestadorOperadoras() exception: ', error, prestadorOperadora);
                        });                

                    }
                    else {

                        // console.log('PUT Prestadores prestadorOperadora',prestadorOperadora);
                        getAPI.put(`/api/prestador-operadora/${prestadorOperadora.id}/`, prestadorOperadora)
                        .then(() => {
                            
                           // console.log('PUT /api/prestador-operadora/ : ok');
                        })
                        .catch(function (error) {
                            console.log('prestadores.savePrestadorOperadoras() exception: ', error);
                        });               
                    
                    }
                }
            });
            this.getPrestadorOperadoras();
            //console.log('savePrestadorOperadoras() this.getPrestadorOperadoras() this.payload.prestadorOperadoras', this.payload.prestadorOperadoras)
        },
        CreateRecord() {

            //console.log('this.prestador', this.prestador)
            getAPI.post("/api/prestadores/", this.prestador)
                .then(response => {
                    
                    this.savePrestadorOperadoras(response.data.id);
                    this.GetPrestadores();
                    this.prestador = {};
                    this.$store.dispatch( 'getPrestadores')
                })
                .catch(function (error) {
                    console.log('prestadores.CreateRecord() exception: ', error);
                });
                
        },
        EditRecord() {
            
            getAPI.put(`/api/prestadores/${this.prestador.id}/`, this.prestador)
                .then(() => {
                    
                    this.savePrestadorOperadoras();
                    this.GetPrestadores();
                    this.prestador = {};
                    this.$store.dispatch( 'getPrestadores')
                })
                .catch(function (error) {
                    console.log('prestadores.EditRecord() exception: ', error);
                });
                

        },
        DeleteRecord(prestador) {
            
            getAPI.delete(`/api/prestadores/${prestador.id}/`)
                .then(() => {
                    
                    
                    this.GetPrestadores();
                    this.$store.dispatch( 'getPrestadores')
                })
                .catch(function (error) {
                    console.log('prestadores.DeleteRecord() exception: ', error);
                });
                
            

        },
      getPrestadorOperadoras() {
            
        // console.log('getPrestadorOperadoras()')    
        getAPI.get('/api/get-prestador-operadora/')
        .then(response => {
        
        //this.payloadOperadorasTab.prestadorOperadoras = JSON.parse(response.data)
        this.payload.prestadorOperadoras = [...JSON.parse(response.data)]

        this.payload.prestadorOperadoras.forEach( row => {
            row.isDeleted = false;
        })
                
        })
        .catch(err => {
            console.log('prestadores.getPrestadorOperadoras() exception: ', err)
        })
    


        },      

    }
    
}
</script>

<style scoped>

 .prestadorTD /deep/   {
 max-width: 100px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
</style>