<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5 grey--text">Nova Tabela</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >       
                <v-combobox
                  v-model="currentItem"
                  :items="payload.tabelas"
                  label="Copiar de"
                  item-text="nome"
                  item-value="id"
                  :return-object = "true"
                  @blur="handleBlur(currentItem)"
                ></v-combobox>                     
              </v-col>              
              <v-col
                cols="12"
                sm="12"
                md="9"
              >
                <v-text-field
                  label="Nome Nova Tabela"
                  required
                  type="text"
                  maxlength="30"
                  
                  v-model="nomeNovaTabela"
                ></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            copiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { getAPI } from '../../axios-api'

export default {
  props: ['payload'],
  data() {
    return {
      
      currentItem: null,
      nomeNovaTabela: null,
      
    }
  },

  methods: {
      save() {
          // console.log('executed save()');
          // console.log(this.payload)
          this.CopiarTabela() ;   
          this.$emit('save');
      },
      handleBlur(/*select*/) {
        // console.log('blur', select)
      },

      CopiarTabela() {
          
          var dados = { 'id': this.currentItem.id,
                    'nome' : this.nomeNovaTabela
                  };

          // console.log(dados)
          getAPI.post("/api/copiar-tabela/", JSON.stringify(dados))
              /*.then(response  => {
                  // console.log(response.data)
                  
              })*/
              .catch(function (error) {
                  console.log('novaTabelaForm.CopiarTabela() exception: ', error);
              });
              
      },
        
    
  },
  computed: {
    dialog: {
      get () {
        return this.payload.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>