var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prestador-operadoras-tab "},[_c('v-card',[_c('v-card-text',[_c('v-simple-table',{attrs:{"height":"267","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Operadora ")]),_c('th',{staticClass:"text-left",attrs:{"width":"15%"}},[_vm._v(" Tabela ")]),_c('th',{staticClass:"text-left",attrs:{"width":"8%"}},[_vm._v(" Valor CH ")]),_c('th',{staticClass:"text-left",attrs:{"width":"8%"}},[_vm._v(" Valor CO ")]),_c('th',{staticClass:"text-left",attrs:{"width":"8%"}},[_vm._v(" Valor Filme ")]),_c('th',{staticClass:"text-left",attrs:{"width":"8%"}},[_vm._v(" Valor Outros ")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v(" Ações "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-16 mr-0 pa-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.showModal({prestador: _vm.payload.prestadorForm.prestador.id})}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v("Adicionar")])])],1)])]),_c('tbody',_vm._l((_vm.filteredPrestadorOperadoras),function(operadora,index){return _c('tr',{key:operadora.id},[_c('td',{staticClass:"maxSize100pxOrEllipsesTD"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(operadora.operadora_nome))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(operadora.operadora_nome))])])],1),_c('td',{staticClass:"maxSize100pxOrEllipsesTD"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(operadora.tabela_nome))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(operadora.tabela_nome))])])],1),_c('td',[_vm._v(_vm._s(operadora.valor_ch))]),_c('td',[_vm._v(_vm._s(operadora.valor_co))]),_c('td',[_vm._v(_vm._s(operadora.valor_filme))]),_c('td',[_vm._v(_vm._s(operadora.valor_outros))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"red","fab":"","text":"","small":""},on:{"click":function($event){return _vm.deleteRecord(operadora, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-0 white--text",attrs:{"color":"primary","fab":"","text":"","small":""},on:{"click":function($event){return _vm.showModal(operadora, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)])}),0)]},proxy:true}])})],1)],1),_c('PrestadorOperadorasTabForm',{attrs:{"payload":_vm.payload},on:{"close":function($event){_vm.payload.prestadorOperadorasTabForm.visible=false},"save":_vm.save}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }