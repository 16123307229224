<template>
<div>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
      :retain-focus="false"
       scrollable
    >
      <v-card>
        <v-card-title>
          <span class="text-h5 grey--text">Guia SP/SADT</span>
          <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              dense
              class="text-h5 grey--text"
              
              @click="setupFields"

            >
              <v-icon>mdi-cog-outline</v-icon>
            </v-btn>

           <v-btn
              icon
              dark
              dense              
              class="text-h5 grey--text"
              
              @click="close"

            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

        </v-card-title>
        <v-card-text>
          <v-container>
          <v-form ref="guiaForm" class="mx-2" lazy-validation>
          
            <v-row>
              <v-col
                cols="12"
                xs="6" sm="6" md="3" lg="2" xl="2"                
              >
                <v-text-field
                  label="Guia Operadora"
                  class = "inputNumberNoSpin"
                  v-model="payload.guiasSpSadt.form.guia.numeroGuiaOperadora"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  
                  ref="startField"
                  autofocus
                  type="text"
                  maxlength="20"
                  v-mask="'####################'"
                ></v-text-field>
              </v-col>

              
              <v-col
                cols="12"
                xs="6" sm="6" md="3" lg="2" xl="2"                
              >
                <v-text-field
                  label="Guia Principal"
                  class = "inputNumberNoSpin"
                  v-model="payload.guiasSpSadt.form.guia.guiaPrincipal"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  

                  type="text"
                  maxlength="20"
                  v-mask="'####################'"
                ></v-text-field>
              </v-col>

             <v-col
                cols="12"
                xs="6" sm="4" md="3" lg="2" xl="2"
                
              >
                <v-text-field
                  label="C.Beneficiário"
                  v-model="payload.guiasSpSadt.form.guia.numeroCarteiraBeneficiario"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  type="text"
                  maxlength="20"
                  v-mask="'####################'"
                   
                ></v-text-field>
              </v-col>              
              
              <!--  -->
              <v-col
                cols="6"
                xs="2" sm="2" md="2" lg="2" xl="2"
                v-if="guiaPayload.cols.atendimentoRN.visible"
              >
                <v-select
                  label="RN"
                  v-model="payload.guiasSpSadt.form.guia.atendimentoRN"
                  :items="['N', 'S']"
                  required
                  dense
                   
                ></v-select>
              </v-col>              
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                class="text-capitalize"
                  label="Nome Beneficiário"
                  v-model="payload.guiasSpSadt.form.guia.nomeBeneficiario"
                  @input="(val) => (payload.guiasSpSadt.form.guia.nomeBeneficiario = payload.guiasSpSadt.form.guia.nomeBeneficiario.toUpperCase())"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  dense
                  type="text"
                  maxlength="70"
                   
                ></v-text-field>
              </v-col>          
              
              <!--  -->
              <v-col
                cols="12"
                xs="3" sm="3" md="3" lg="3" xl="3"
                v-if="guiaPayload.cols.numeroCNS.visible"
              >
                <v-text-field
                  label="CNS"
                  v-model="payload.guiasSpSadt.form.guia.numeroCNS"
                  required
                  dense
                  type="text "
                  maxlength="15"
                  v-mask="'###############'"
                                     
                ></v-text-field>
                
              </v-col>          
              
              <!--  -->
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="guiaPayload.cols.indicacaoAcidente.visible"
              >
                <v-select
                  label="Indicação Acidente"
                  v-model="payload.guiasSpSadt.form.guia.indicacaoAcidente"
                  :items = "indicacaoAcidente"
                  item-text="text"
                  item-value="id"

                  required
                  dense
                   
                ></v-select>
                
              </v-col>         
              
              <!--  -->
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="guiaPayload.cols.regimeAtendimento.visible"
              >  
                <v-autocomplete
                  label="R.Atendimento"
                  v-model="payload.guiasSpSadt.form.guia.regimeAtendimento"
                  required
                  dense
                  
                  :items = "regimeAtendimento"
                  item-text="key"
                  item-value="id"
                  @change="regimeAtendimentoOnChange"
                  @blur = "regimeAtendimentoOnChange"
                  type="text"
                  hide-no-data
                  maxlength="30"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-autocomplete>
              </v-col>

               
               <!--  -->       
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="guiaPayload.cols.saudeOcupacional.visible"
              >  
                <v-autocomplete
                  label="S.Ocupacional"
                  v-model="payload.guiasSpSadt.form.guia.saudeOcupacional"
                  required
                  dense
                  
                  :items = "saudeOcupacional"
                  item-text="key"
                  item-value="id"
                  type="text"
                  hide-no-data
                  maxlength="30"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-autocomplete>
              </v-col>

              
              <!--  -->
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="guiaPayload.cols.coberturaEspecial.visible"
              >  
                <v-autocomplete
                  label="C.Especial"
                  v-model="payload.guiasSpSadt.form.guia.coberturaEspecial"
                  required
                  dense
                  
                  :items = "coberturaEspecial"
                  item-text="key"
                  item-value="id"
                  type="text"
                  hide-no-data
                  maxlength="30"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-autocomplete>
              </v-col>              
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-autocomplete
                  label="Profissional"   
                  v-model="profissional"
                  required
                  :rules="[(v) => !!v.id  || 'Campo obrigatório']"
                  dense
                  return-object
                  :items = "profissionais"
                  item-text="key"
                  item-value="id"
                  @change="profissionalOnChange"
                  @blur = "profissionalOnChange"
                  type="text"
                  hide-no-data
                  maxlength="70"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-autocomplete>
                
              </v-col>    
              
              <!--   -->
              <v-col
                cols="12"
                sm="2"
                md="2"
                v-if="guiaPayload.cols.numeroConselhoProfissionalAuto.visible"
              >
                <v-text-field
                  label="Número Conselho"
                  v-model="payload.guiasSpSadt.form.guia.numeroConselhoProfissional"
                  required
                  class = "inputNumberNoSpin"
                  dense
                  type="number"
                  maxlength="15"
                  disabled
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>    
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  label="Conselho"
                  v-model="conselhoSelecionado"
                  required
                  dense
                  type="text"
                  maxlength="15"
                  disabled
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>   
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  label="UF Conselho"
                  v-model="uf_conselho"
                  required
                  dense
                  type="text"
                  disabled
                  maxlength="2"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>     
              <v-col
                cols="12"
                sm="2"
                md="2"
              >
                <v-text-field
                  label="CBO"
                  v-model="payload.guiasSpSadt.form.guia.CBOS"
                  required
                  dense
                  disabled
                  type="number"
                  maxlength="6"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
                
              </v-col>     
              
              
              <!--  -->
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="guiaPayload.cols.tipoConsulta.visible"
              >
                <v-select
                  label="Tipo Consulta"
                  v-model="payload.guiasSpSadt.form.guia.tipoConsulta"
                  :items = "tipoConsulta"
                  required
                  item-text="text"
                  item-value="id"

                  dense
                   
                ></v-select>
                
              </v-col>                     
              
              <!-- new -->
              <!--  -->
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="guiaPayload.cols.tipoConsulta.visible"
              >
                <v-select
                  label="Carater Atendimento"
                  v-model="payload.guiasSpSadt.form.guia.caraterAtendimento"
                  :items = "[{id: 1, text: 'Eletiva'}, {id: 2, text: 'Urgência/Emergência'}]"
                  required
                  item-text="text"
                  item-value="id"

                  dense
                   
                ></v-select>
                
              </v-col>                            

              <!-- new -->
              <!--  -->
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="guiaPayload.cols.tipoAtendimento.visible"
              >
                <v-select
                  label="Tipo Atendimento"
                  v-model="payload.guiasSpSadt.form.guia.tipoAtendimento"
                  :items = "tipoAtendimento"
                  required
                  item-text="text"
                  item-value="id"

                  dense
                   
                ></v-select>
                
              </v-col>                            

            <!--
            motivoEncerramento opt
						41 Óbito com declaração de óbito fornecida pelo médico assistente
						42 Óbito com declaração de Óbito fornecida pelo Instituto Médico Legal - IML
						43 Óbito com declaração de Óbito fornecida pelo Serviço de Verificação de Óbito - SVO. 
            -->
              <!-- new -->
              <!--  -->
              <v-col
                cols="12"
                sm="3"
                md="3"
                v-if="guiaPayload.cols.motivoEncerramento.visible"
              >
                <v-select
                  label="Motivo Encerramento"
                  v-model="payload.guiasSpSadt.form.guia.motivoEncerramento"
                  :items = "[{id: 0, text: 'N/A'}, {id: 41, text: 'Óbito/méd. assis.'}, {id: 42, text: 'Óbito/IML'}, {id: 43, text: 'Óbito/SVO'}]"
                  required
                  item-text="text"
                  item-value="id"

                  dense
                   
                ></v-select>
                
              </v-col>                            

              <!-- new -->
              <!--  -->                    
              <v-col
                cols="12"
                xs="8" sm="8" md="10" lg="10" xl="10"
                v-if="guiaPayload.cols.indicacaoClinica.visible"
              >
                <v-text-field
                  label="Indicação Clínica"
                  v-model="payload.guiasSpSadt.form.guia.indicacaoClinica"
                  required
                  dense
                  
                  type="text"
                  maxlength="500"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
              </v-col>         

              <!--  -->                    
              <v-col
                cols="12"
                xs="8" sm="8" md="10" lg="10" xl="10"
                v-if="guiaPayload.cols.observacao.visible"
              >
                <v-text-field
                  label="Observação"
                  v-model="payload.guiasSpSadt.form.guia.observacao"
                  required
                  dense
                  
                  type="text"
                  maxlength="500"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   
                ></v-text-field>
              </v-col>         
            </v-row>
            <v-row>

            <table cellpadding=0 cellspacing=0  class = "ma-0 pa-0 topics table">
            <thead>
              <tr>
                <th class="text-left" width= "13%">Data</th>
                <th class="text-left" >Procedimento</th>
                <th class="text-left" width= "5%">Qt.</th>
                <th class="text-left" width= "5%"
                  v-if="guiaPayload.cols.viaAcesso.visible"
                >Via</th>
                <th class="text-left" width= "5%" 
                  v-if="guiaPayload.cols.tecnicaUtilizada.visible"
                >Tec.
                </th>
                <th class="text-left" width= "5%"
                  v-if="guiaPayload.cols.reducaoAcrescimo.visible"
                >Red.</th>
                <th class="text-left" width= "5%">Part.</th>
                <th class="text-left" width= "10%">Prof.</th>
                

                
              </tr>
            </thead>
            <tbody>
              
              <tr v-for="(procedimento, index) in procedimentosList" :key="procedimento.index">
                
                <td   style="text-align: center">
                  <!--hide-details-->
                  <v-text-field
                    label=""
                    v-model="procedimento.dataExecucaoFormatada"
                    required
                    hide-details
                    type="text"
                    class = "inputNumberNoSpin pl-1"
                    v-mask="'##/##/####'"
                    maxlength="10"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    @change="dataExecucaoOnChange(procedimento)"
                    
                    dense
                  >
                  </v-text-field>                      
                        
                </td>
                <td class ="ma-0 pa-0">
                  <!--
                  <v-text-field
                    label=""
                    v-model="procedimento.codigoProcedimento"
                    required
                    hide-details
                    dense
                    type="text"
                    class = "ma-0 pa-0"
                  >
                  </v-text-field>

                  :search-input.sync="procedimento.searchProcedimento"
                  -->                      
                  <GuiaSpSadtFormProcedimento
                    :vmodel="procedimento"
                    :disabled="!procedimento.dataExecucaoFormatada" 
                    :payload = "payload"
                  />
                <!--    
                <v-autocomplete
                  label=""
                  v-model="procedimento.procedimento"
                  :disabled="!procedimento.dataExecucaoFormatada"
                  required
                  :rules="[(v) => !!(v.id && v.id !='undefined') || 'Campo obrigatório']"
                  dense
                  return-object
                  :items = "procedimentos"
                  item-text="text"
                  item-value="id"
                  hide-details
                  type="text"
                  
                  hide-no-data
                  @change="procedimentoOnChange"
                  @blur="procedimentoOnChange"
                  maxlength="70"
                  class = " pl-1"
                   
                ></v-autocomplete>
                -->
                </td>

                <td  class ="ma-0 pa-0">
                  <!--hide-details-->
                  <v-text-field
                    label=""
                    v-model="procedimento.quantidadeExecutada"
                    :disabled="!procedimento.procedimento.id"
                    required
                    hide-details
                    type="text"
                    class = "inputNumberNoSpin pl-1"
                    v-mask="'####'"
                    maxlength="4"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    dense
                  >
                  </v-text-field>                      
                        
                </td>

                <!-- opt 01- Única 2- Mesma Via 3- Diferentes vias return-object-->
                <td  class ="ma-0 pa-0"
                v-if="guiaPayload.cols.viaAcesso.visible"
                >
                  <!-- :rules="[(v) => !!v.id  || 'Campo obrigatório']" -->
                <v-autocomplete
                    label=""
                    v-model="procedimento.viaAcesso"
                    :disabled = "!procedimento.quantidadeExecutada || procedimento.quantidadeExecutada<1"
                    required
                    
                    dense
                    hide-details
                    append-icon=""
                    :items = "[{id:'01', key : '1 | Única'},{id :'02', key:'2 | Mesma Via' }, {id:'03', key: '3 | Diferentes vias'}]"
                    item-text="key"
                    item-value="id"
                    type="text"
                    hide-no-data
                    maxlength="2"
                    v-mask="'##'"
                    class="pl-1"
                    @blur = "newLine(index)"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    
                  ></v-autocomplete>

                <!--
                <td  class ="ma-0 pa-0">
                  <v-text-field
                    label=""
                    v-model="procedimento.viaAcesso"
                    :disabled = "!procedimento.quantidadeExecutada || procedimento.quantidadeExecutada<1"
                    required
                    hide-details
                    dense
                    type="text"
                    @blur = "newLine(index)"
                    class = "inputNumberNoSpin pl-1"
                    v-mask="'##'"
                    maxlength="2"
                    
                    
                  >
                  </v-text-field>       
                  -->
                </td>
                <td  class ="ma-0 pa-0"  
                  v-if="guiaPayload.cols.tecnicaUtilizada.visible"
                >
                  <v-autocomplete
                    label=""
                    v-model="procedimento.tecnicaUtilizada"
                    :disabled = "!procedimento.viaAcesso"
                    required
                    
                    dense
                    hide-details
                    append-icon=""

                    :items = "[{id:null, key : ''},{id :'1', key:'1 | Convencional' }, {id:'2', key:'2 | Videolaparoscopia'}, {id:3, key: '3 | Robótica'}]"
                    item-text="key"
                    item-value="id"
                    type="text"
                    hide-no-data
                    maxlength="2"
                    v-mask="'##'"
                    class="pl-1"

                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    
                  ></v-autocomplete>                
                  <!-- hide-details
                  <v-text-field
                    label=""
                    v-model="procedimento.tecnicaUtilizada"
                    :disabled = "!procedimento.viaAcesso"
                    required
                    hide-details
                    type="text"
                    class = "inputNumberNoSpin pl-1"
                    v-mask="'##'"
                    maxlength="2"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    dense
                  >
                  </v-text-field>                      
                  -->
                </td>
                
                <td  class ="ma-0 pa-0"
                  v-if="guiaPayload.cols.reducaoAcrescimo.visible"
                >
                  <!--hide-details-->
                  <v-text-field
                    label=""
                    v-model="procedimento.reducaoAcrescimo"
                    :disabled = "!procedimento.viaAcesso" 
                    required
                    hide-details
                    type="text"
                    class = "inputNumberNoSpin pl-1"
                    v-mask="'#.##'"
                    maxlength="4"
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    dense
                  >
                  </v-text-field>                      
                        
                </td>

                <td  class ="ma-0 pa-0">

                <!-- :rules="[(v) => !!v.id  || 'Campo obrigatório']" -->
                <v-autocomplete
                    label=""
                    v-model="procedimento.equipeSadt[0].grauParticipacao"
                    :disabled = "!procedimento.quantidadeExecutada"
                    required
                    
                    dense
                    hide-details
                    append-icon=""
                    :items = "[{id:'00', key : '00 | Cirurgião'},{id :'12', key:'12 | Clínico' }]"
                    item-text="key"
                    item-value="id"
                    type="text"
                    hide-no-data
                    maxlength="2"
                    v-mask="'##'"
                    class="pl-1"
                    @blur = "newLine(index)"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    
                  ></v-autocomplete>                  
                        
                </td>

                <td  class ="ma-0 pa-0">

                  <v-autocomplete
                    label=""
                    v-model="procedimento.equipeSadt[0].profissional"
                    :disabled = "!procedimento.equipeSadt[0].grauParticipacao"
                    required
                    :rules="[(v) => !!v.id  || 'Campo obrigatório']"
                    dense
                    hide-details
                    return-object
                    :items = "profissionais"
                    item-text="key"
                    item-value="id"
                    type="text"
                    append-icon=""
                    hide-no-data
                    maxlength="70"
                    class="pl-1"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    
                  ></v-autocomplete>

                </td>

              </tr>
            </tbody>
          </table>

<!--
              <v-simple-table
                
                dense
              >
                <template v-slot:default>
                <thead>
                  <tr>
                  <th class="text-left">
                    Data
                  </th>
                  <th class="text-left"  width= "20%">
                    Procedimento
                  </th>
                    <th class="text-left"  width= "15%">
                    Via
                  </th>

                  </tr>
                </thead>
                <tbody>

    
         codigoProcedimento: null,
         quantidadeExecutada: null,
         viaAcesso: null,
         tecnicaUtilizada : null,
         reducaoAcrescimo 
                  <tr v-for="procedimento in procedimentosList" :key="procedimento.index" >
                    <td>
                      <v-text-field
                        label=""
                        v-model="procedimento.dataExecucao"
                        required
                        dense
                        
                        type="text"
                        
                      >
                      </v-text-field>                      
                            
                    </td>
                    <td>
                      <v-text-field
                        label=""
                        v-model="procedimento.codigoProcedimento"
                        required
                        dense
                        
                        type="text"
                        
                      >
                      </v-text-field>                      

                      </td>
                    <td>
                      <v-text-field
                        label=""
                        v-model="procedimento.viaAcesso"
                        required
                        dense
                        
                        type="text"
                        @blur = "newLine()"
                      >
                      </v-text-field>                      

                      

                      
                      </td>
                  </tr>
                </tbody>
                </template>
              </v-simple-table>
              -->
            </v-row>
            </v-form>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          
          <!--  -->
          <v-btn
            color="blue darken-1"
            text
            @click="close"
            v-if="guiaPayload.cols.botaoFechar.visible"
          >
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveGuia"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
      
  <GuiaSpSadtFormPop1
    :payload="guiaPayload" 
    @close="setupFieldsClose" 
  />

  <v-snackbar
      v-model="alert"
      color="error"
  >
  <v-icon class="pr-3" dark large>error</v-icon>
      Campos obrigatórios devem ser preenchidos corretamente

      <template v-slot:action="{ attrs }">
        <v-btn
          color="grey"
          text
          v-bind="attrs"
          @click="alert = false"
        >
          Fechar
        </v-btn>
      </template>
  </v-snackbar>    

</div>
</template>

<script>
import { getAPI } from '../../axios-api'
import GuiaSpSadtFormPop1 from '@/components/GuiaSpSadtFormPop1'
import GuiaSpSadtFormProcedimento from '@/components/GuiaSpSadtFormProcedimento'
export default {
  props: ['payload'],
  components: {GuiaSpSadtFormPop1, GuiaSpSadtFormProcedimento},
  data() {
    return {
      templateProcedimento :  {
         dataExecucaoFormatada : null,
         dataExecucao : null,
         
         procedimento : {id: null, text: null},
         procedimento_id : null,
         codigoProcedimento: null,
         
         equipeSadt : [{ 
            profissional : {id: null, nome: null, key: null},
            grauParticipacao : null
          }],
         
         quantidadeExecutada: null,
         viaAcesso: null,
         tecnicaUtilizada : null,
         reducaoAcrescimo : null
      },
      
      procedimentosList : [],


      alert: false,
      dialog: false,
      tabela : null,
      
      // usado antes de criar o componente para procedimento na table
      //procedimentos : [],
      
      search : null,
      uf_conselho : null,
      conselhoSelecionado : null,
      profissional : {id: null, nome: ''},
      profissionais : [],
      dataAtendimentoError : [],
      menu1 : null,
      dataAtendimentoFormatted : null,
      indicacaoAcidente : [
        {id : 0, text: '0 | Trabalho'}, 
        {id : 1, text: '1 | Trânsito'}, 
        {id : 2, text: '2 | Outros Acidentes'}, 
        {id : 9, text: '9 | Não Acidentes'}
      ],
      tipoConsulta  :[
        {id: 1, text: '1 | Primeira'}, 
        {id: 2, text: '2 | Seguimento'}, 
        {id: 3, text: '3 | Pré-Natal'}, 
        {id: 4, text: '4 | Por encaminhamento'}
      ],

      conselho : [
            { id: 1,  text:'CRAS'	},
            { id: 2,  text:'COREN' },
            { id: 3,  text:'CRF'},	
            { id: 4,  text:'CRFA' },
            { id: 5,  text:'CREFITO'},
            { id: 6,  text:'CRM'	}	,
            { id: 7,  text:'CRN'	}	,
            { id: 8,  text:'CRO'	}	,
            { id: 9,  text:'CRP'	}	,
            { id: 10, text:'OUT'	}	,
            // v4.00.00
            { id: 11, text:'CRBio'}	,
            { id: 12, text:'CRBM' }	,
            { id: 13, text:'CREF' }	,
            { id: 14, text:'CRMV' }	,
            { id: 15, text:'CRTR'}	
                        
            ],
      uf : [
        {id : 11, estado : 'Rondônia', sigla:'RO'},
        {id : 12, estado : 'Acre', sigla:'AC'},
        {id : 13, estado : 'Amazonas', sigla:'AM'},
        {id : 14, estado : 'Roraima', sigla:'RR'},
        {id : 15, estado : 'Pará', sigla:'PA'},
        {id : 16, estado : 'Amapá', sigla:'AP'},
        {id : 17, estado : 'Tocantins', sigla:'TO'},
        {id : 21, estado : 'Maranhão', sigla:'MA'},
        {id : 22, estado : 'Piauí', sigla:'PI'},
        {id : 23, estado : 'Ceará', sigla:'CE'},
        {id : 24, estado : 'Rio Grande do Norte', sigla:'RN'},
        {id : 25, estado : 'Paraíba', sigla:'PB'},
        {id : 26, estado : 'Pernambuco', sigla:'PE'},
        {id : 27, estado : 'Alagoas', sigla:'AL'},
        {id : 28, estado : 'Sergipe', sigla:'SE'},
        {id : 29, estado : 'Bahia', sigla:'BA'},
        {id : 31, estado : 'Minas Gerais', sigla:'MG'},
        {id : 32, estado : 'Espírito Santo', sigla:'ES'},
        {id : 33, estado : 'Rio de Janeiro', sigla:'RJ'},
        {id : 35, estado : 'São Paulo', sigla:'SP'},
        {id : 41, estado : 'Paraná', sigla:'PR'},
        {id : 42, estado : 'Santa Catarina', sigla:'SC'},
        {id : 43, estado : 'Rio Grande do Sul', sigla:'RS'},
        {id : 50, estado : 'Mato Grosso do Sul', sigla:'MS'},
        {id : 51, estado : 'Mato Grosso', sigla:'MT'},
        {id : 52, estado : 'Goiás', sigla:'GO'},
        {id : 53, estado : 'Distrito Federal', sigla:'DF'},
        {id : 98, estado : 'Países Estrangeiros', sigla:'EX'}
      ],
      tabelas : [
        {id: 22,	nome: 'Procedimentos'},
        {id: 20,	nome: 'Medicamentos'},
        {id: 19,	nome: 'Materiais e OPME'},
        {id: 18,	nome: 'Diárias e Taxas'},
        {id: 0,	  nome: 'Própria'}
      ],

      // optional tab 75
      coberturaEspecial : [
        { id:null, key: '0 | Não se aplica'},
        { id:'01', key: '1 | Gestante'},
        { id:'02', key: '2 | Pré-operatório'},
        { id:'03', key: '3 | Pós-operatório'}

       ],

      // optional tab 77
      saudeOcupacional : [
        { id: null, key: '0 | Não se aplica'},
        { id: '01', key: '1 | Admissional'},
        { id: '02', key: '2 | Demissional'},
        { id: '03', key: '3 | Periódico'},
        { id: '04', key: '4 | Retorno ao trabalho'},
        { id: '05', key: '5 | Mudança de função'},
        { id: '06', key: '6 | Promoção à saúde'}
      ],

      // required - tab 76
      regimeAtendimento : [
        { id: '01', key: '1 | Ambulatorial'},
        { id: '02', key: '2 | Domiciliar' },
        { id: '03', key: '3 | Internação'},
        { id: '04', key: '4 | Pronto-socorro'},
        { id: '05', key: '5 | Telessaúde'}
      ],

      tipoAtendimento : [
        { id: '01', text:	'Remoção'},
        { id: '02', text:	'Pequena Cirurgia'},
        { id: '03', text:	'Terapias'},
        { id: '04', text:	'Consulta'},
        { id: '05', text:	'Exames (englobando exame radiológico)'},
        { id: '06', text:	'Atendimento Domiciliar'},
        { id: '07', text:	'Internação'},
        { id: '08', text:	'Quimioterapia'},
        { id: '09', text:	'Radioterapia'},
        { id: '10', text:	'Terapia Renal Substitutiva (TRS)'},
        { id: '11', text:	'Pronto Socorro'},
        { id: '12', text:	'Ocupacional - Inativado na versão 3.01.00'},
        { id: '13', text:	'Pequenos atendimentos'},
        { id: '14', text:	'Admissional'},
        { id: '15', text:	'Demissional'},
        { id: '16', text:	'Periódico'},
        { id: '17', text:	'Retorno ao trabalho'},
        { id: '18', text:	'Mudança de função'},
        // '*** Incluidos na versão 3.01.00
        { id: '14', text:	'Saúde Ocupacional - Admissional'},
        { id: '15', text:	'Saúde Ocupacional - Demissional'},
        { id: '16', text:	'Saúde Ocupacional - Periódico'},
        { id: '17', text:	'Saúde Ocupacional - Retorno ao trabalho'},
        { id: '18', text:	'Saúde Ocupacional - Mudança de função'},
        { id: '19', text:	'Saúde Ocupacional - Promoção a saúde'},
        { id: '20', text:	'Saúde Ocupacional - Beneficiário novo'},
        { id: '21', text:	'Saúde Ocupacional - Assistência a demitidos'},
        { id: '22', text:  'Telessaude'}
      ],

      guiaPayload : {
        popUp : {
          visible : false,
        },
        cols: {
            numeroCNS: {
                id: 1,
                title: 'NumeroCNS', 
                visible : false,
                dominio :[{id: null, key: 'manter vazio'}],
                valorDefault: null
            },
            observacao: {
                id: 2,
                title : 'Observação',
                visible : false,
                dominio :[{id: null, key: 'manter vazio'}],
                valorDefault: null
            },

            atendimentoRN: {
                id: 3,
                title : 'RN',
                visible : false,
                dominio :[
                    {id: 'N', key: 'N'},
                    {id: 'S', key: 'S'},
                    
                ],
                valorDefault: 'N'                            
            },

            indicacaoAcidente: {
                id: 4,
                title : 'Indicação Acidente',
                visible : false,
                dominio :[
                    {id : 0, key: '0 | Trabalho'}, 
                    {id : 1, key: '1 | Trânsito'}, 
                    {id : 2, key: '2 | Outros Acidentes'}, 
                    {id : 9, key: '9 | Não Acidentes'}
                ],
                valorDefault: 9                            

            },

            regimeAtendimento: {
                id: 5,
                title : 'Regime de atendimento',
                visible : false,
                dominio : [
                    { id: '01', key: '1 | Ambulatorial'},
                    { id: '02', key: '2 | Domiciliar' },
                    { id: '03', key: '3 | Internação'},
                    { id: '04', key: '4 | Pronto-socorro'},
                    { id: '05', key: '5 | Telessaúde'}
                ],
                valorDefault: '01'                            

            },

            saudeOcupacional: {
                id: 6,
                title : 'Saúde ocupacional',
                visible : false,
                dominio : [
                    { id: null, key: 'manter vazio'},
                    { id: '01', key: '1 | Admissional'},
                    { id: '02', key: '2 | Demissional'},
                    { id: '03', key: '3 | Periódico'},
                    { id: '04', key: '4 | Retorno ao trabalho'},
                    { id: '05', key: '5 | Mudança de função'},
                    { id: '06', key: '6 | Promoção à saúde'}
                ],
                valorDefault: null                            

            },

            coberturaEspecial: {
                id: 7,
                title : 'Cobertura especial',
                visible : false,
                dominio : [
                    { id:null, key: 'manter vazio'},
                    { id:'01', key: '1 | Gestante'},
                    { id:'02', key: '2 | Pré-operatório'},
                    { id:'03', key: '3 | Pós-operatório'}
                ],
                valorDefault: null                            

            },

            numeroConselhoProfissionalAuto: {
                id: 8,
                title : 'Número conselho profissional',
                visible : false,
                dominio : [{id: null, key: 'automático'}],
                valorDefault: null                            

            },

            tipoConsulta: {
                id: 9,
                title : 'Tipo consulta',
                visible : false,
                dominio : [
                    {id: 1, key: '1 | Primeira'}, 
                    {id: 2, key: '2 | Seguimento'}, 
                    {id: 3, key: '3 | Pré-Natal'}, 
                    {id: 4, key: '4 | Por encaminhamento'}
                    
                ],
                valorDefault: 1                            

            },

            indicacaoClinica: {
                id: 10,
                title : 'Indicação Clínica',
                visible : false,
                dominio :[{id: null, key: 'manter vazio'}],
                valorDefault: null
            },

            motivoEncerramento: {
                id: 11,
                title : 'Motivo Encerramento',
                visible : false,
                dominio :[{id: 0, key: 'N/A'}, {id: 41, key: 'Óbito/méd. assis.'}, {id: 42, key: 'Óbito/IML'}, {id: 43, key: 'Óbito/SVO'}],
                valorDefault: null
            },
            
            tipoAtendimento: {
                id: 12,
                title : 'Tipo Atendimento',
                visible : false,
                dominio :[],
                valorDefault: '04'
            },
            
            tecnicaUtilizada: {
                id: 13,
                title : 'Técnica Utilizada',
                visible : false,
                dominio :[
                  {id:null, key: 'manter vazio'}, 
                  {id:1, key: 'Convencional'}, 
                  {id: 2, key: 'Videolaparoscopia'}, 
                  {id: 3, key: 'Robótica'}
                ],
                valorDefault: null

            },
            viaAcesso : {
                id: 14,
                title : 'Via de Acesso',
                visible : false,
                dominio :[
                  {id:null, key: 'manter vazio'}, 
                  {id: '01', key: '1 | Única'}, 
                  {id: '02', key: '2 | Mesma Via'}, 
                  {id: '03', key: '3 | Diferentes vias'}
                ],
                valorDefault: null
            },
            reducaoAcrescimo : {
                id: 15,
                title : 'Redução / Acréscimo',
                visible : false,
                dominio :[{id:1.00, key:'1.00'}],
                valorDefault: 1.00
            },

            botaoFechar: {
                id: 16,
                title : 'Botão fechar',
                visible : false,
                dominio : [{id: null, key: 'não se aplica'}],
                valorDefault: null                            

            },


        }, // cols
      }, // payload
    } // data return
     
  }, // data



  watch: { 
    /*
    'payload.guiaConsultaForm.guia.dataAtendimento' () {
      //console.log('this.payload.guiasSpSadt.form.guia.dataAtendimento', this.payload.guiasSpSadt.form.guia.dataAtendimento)
      this.dataAtendimentoFormatted = this.formatDate(this.payload.guiasSpSadt.form.guia.dataAtendimento)
    },
    */

   /*
    search(val) {
      val && val !== this.procedimento.nome && this.searchVal(val);
    },
*/
    'payload.guiasSpSadt.form.visible' :{
      handler(newVal /*, oldVal*/) {
       
       if(newVal) {
       
         if(!this.payload.guiasSpSadt.form.guia.id) {
       
            this.defaultFieldValues()
       
            if (this.$refs['guiaForm']) {
              //this.$refs.form.reset();
              this.$refs.guiaForm.resetValidation()
            }
            
           
         }
         else {
           
            this.loadValues()
         }

         this.fillHiddenFields()
         
         this.dialog = true
         this.getFieldSetup()
         
         }
       else {
         this.$emit('close')
         this.dialog= false
        }
       

      },
      deep: true
    },
    
  },
  //beforeCreate: function() {
  Created: function() {
    
  },
  async mounted() {
    await this.GetProfissional()

    // get the full table
    //this.GetTabela(this.payload.lote.prestador_tabela) 
    //this.$nextTick(async () => {
    
    //});             

    
  },
  methods: {
    
    /*
    mounted() {
      this.inputFocus()
      this.$nextTick(function () {
          // Code that will run only after the
          // entire view has been rendered
            
      })
    },    
    
    inputFocus() {
      this.$nextTick(() => {
        const focusStartField = this.$refs.startField
        focusStartField.focus()
      });
    },
    */

      
    procedimentoOnChange() {   
    },
    /*
    GetTabela(tabela=1, page=1, descricao='') {
      let parameters = `tabela=${tabela}&page=${page}`
      if (descricao != '') {
          if (descricao.indexOf('|') > -1){
            descricao = descricao.substring(0, descricao.indexOf('|')  )
          }
          parameters += `&descricao=${descricao}`
      } 
      // console.log('descricao:', descricao)
      getAPI.get(`/api/tabela-detalhe/?${parameters}`,{'silentMode': 1})
      .then(response => {
        
        // {"count":5749,"next":"http://127.0.0.1:8000/api/tabela-detalhe/?page=2&tabela=1",
        // "previous":null,"results":[{"id":1,"procedimento":10101012,"descricao
        
        
        response.data.results.forEach(row => {
          row.text = row.procedimento + ' | ' + row.descricao
        }); 
                    
        if (descricao != '') {
          this.procedimentos= response.data.results.slice(0, 5)
        } else {
          this.procedimentos= response.data.results
        }
      })
      .catch(err => {
          console.log('guiaConsultaForm.GetTabela() exception: ', err)
      })
    },
    */
    isFuture(date) {
          if (!date) {
            return false;
          }

          let today = new Date().toISOString().split('T')[0]

          const day = date.slice(0, 2);
          const month = date.slice(3, 5);
          const year = date.slice(date.length - 4, date.length);
          if(!Date.parse(year+'-' + month + '-'+ day)) {
            return false;
          }

          let dateParsed = year+'-' + month + '-'+ day

          if (dateParsed > today){
            return true;
          }

          return false;
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    dataExecucaoOnChange(procedimento) {
      if (procedimento.dataExecucaoFormatada) {
        const date = procedimento.dataExecucaoFormatada;
        
        const day = date.slice(0, 2);
        const month = date.slice(3, 5);
        const year = date.slice(date.length - 4, date.length);
        if(!Date.parse(year+'-' + month + '-'+ day)) {
          //procedimento.dataExecucaoError = ['data inválida']

          //this.$nextTick(() => {
          
            //this.dataAtendimentoFormatted = date.replace('.','')
          //})             
        } 
        else {
          if (this.isFuture(procedimento.dataExecucaoFormatada)){
            //procedimento.dataExecucaoError = ['data futura não permitida']
          }
          else {
            //procedimento.dataExecucaoError = []
            this.$nextTick(() => {
              procedimento.dataExecucao = `${year}-${month}-${day}` 
              //this.dataAtendimentoFormatted = date.replace('.','')
            })   
          }
        }
      }
    },
    newLine(index) {
      
      if (this.procedimentosList.length<= (index+1)) { 
        this.procedimentosList.push( JSON.parse(JSON.stringify(this.templateProcedimento)));
      }
    },

    async loadValues() {
      this.payload.guiasSpSadt.form.guia.indicacaoAcidente = parseInt(this.payload.guiasSpSadt.form.guia.indicacaoAcidente)
      this.payload.guiasSpSadt.form.guia.tipoConsulta = parseInt(this.payload.guiasSpSadt.form.guia.tipoConsulta)
      this.payload.guiasSpSadt.form.guia.caraterAtendimento = parseInt(this.payload.guiasSpSadt.form.guia.caraterAtendimento)

      const ufprof = this.payload.guiasSpSadt.form.guia.UFConselhoProfissional
      var ufsel = this.uf.find(function(current) {
        
      if(current.id == ufprof)
            return true;
      });
      if(ufsel) {
        this.uf_conselho = ufsel.sigla
        
      }
      var numeroConselho = this.payload.guiasSpSadt.form.guia.numeroConselhoProfissional
      
      const profSel = this.profissionais.find(function(current) {
        
        if(current.numero_conselho == numeroConselho && current.uf_conselho == ufprof)
                return true;
      });
      if(profSel) {
        this.profissional= profSel
      }

      
      const conselhoPro = this.payload.guiasSpSadt.form.guia.conselhoProfissional
      const conselhoSel = this.conselho.find(function(current) {
        if(current.id == conselhoPro)
          return true;
      })

      if (conselhoSel) {
        this.conselhoSelecionado = conselhoSel.text
      }

      // load procedimentos from api
      this.procedimentosList = [];
      for (const procedimento of this.payload.guiasSpSadt.form.guia.procedimentos) {

        procedimento.dataExecucaoFormatada = this.formatDate(procedimento.dataExecucao);

        // usado para passar para o componente, para garantir que tenha o procedimento atual na list, senao fica em branco na tela
        procedimento.procedimentos = [];
        procedimento.procedimento = {
          id: procedimento.procedimento_id, 
          text: procedimento.codigoProcedimento + ' | ' + procedimento.descricaoProcedimento
        };
        procedimento.procedimentos.push(procedimento.procedimento);
        
        // usado antes de criar o componente para procedimento na table
        //this.procedimentos.push(procedimento.procedimento)

        procedimento.equipeSadt = [];

        if(procedimento.profissionais) {
          for (const profissionalTmp of procedimento.profissionais) {
            const equipeSadtTmp = {
              grauParticipacao : profissionalTmp.grauPart,
              profissional : {
                id: profissionalTmp.profissional_id, 
                nome: profissionalTmp.nome, 
                key: profissionalTmp.numeroConselhoProfissional + ' | ' + procedimento.profissionais[0].nomeProf            
              }
            }
            procedimento.equipeSadt.push(equipeSadtTmp);
          }
        } 

        for (let i = procedimento.equipeSadt.length; i<=15; i++){
          const equipeSadtTmp = { 
            profissional : {id: null, nome: null, key: null},
            grauParticipacao : null
          };

          procedimento.equipeSadt.push(equipeSadtTmp)

        }

        this.procedimentosList.push(procedimento);
      }
      
      // garante a ordem original
      this.procedimentosList.sort((a,b) => (a.sequencialItem > b.sequencialItem) ? 1 : ((b.sequencialItem > a.sequencialItem) ? -1 : 0))
      
      // blank line to be ready to input new procedimento
      this.procedimentosList.push( JSON.parse(JSON.stringify(this.templateProcedimento)))
      
    },

    close() {
      
      this.payload.guiasSpSadt.form.visible = false
    },

    /*
      searchVal(val) {
        
        this.GetTabela(this.payload.lote.prestador_tabela, 1, val) 
      },

      GetTabela(tabela=1, page=1, descricao='') {
            var parameters = `tabela=${tabela}&page=${page}`
            if (descricao != '') {
                if (descricao.indexOf('|') > -1){
                  descricao = descricao.substring(0, descricao.indexOf('|')  )
                }
                parameters += `&descricao=${descricao}`
            } 
            // console.log('descricao:', descricao)
            getAPI.get(`/api/tabela-detalhe/?${parameters}`,{'silentMode': 1})
            .then(response => {
            // this.$store.state.APIData = response.data
            
            //{"count":5749,"next":"http://127.0.0.1:8000/api/tabela-detalhe/?page=2&tabela=1",
            //"previous":null,"results":[{"id":1,"procedimento":10101012,"descricao
            
            
            response.data.results.forEach(row => {
              row.text = row.procedimento + ' | ' + row.descricao
              
              //if (this.isNumeric(descricao)) {
              //  row.text = String(row.procedimento )
              //}
              //else {
              //  row.text = row.descricao
              //}
              

            }); 
                       
            this.procedimentos= response.data.results.slice(0, 5)

            })
            .catch(err => {
                console.log('guiaConsultaForm.GetTabela() exception: ', err)
            })
      },
      */
      isNumeric(str) {
        
        if (typeof str != "string") return false // we only process strings!  
        
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
              !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
              
      },
      /*  
      procedimentoOnChange() {
        
        this.$nextTick(async  () => {
          
          if (this.procedimento.tabela) {
            this.tabela= await this.GetTabelas(this.procedimento.tabela)
            var valorProc = 0
            if (this.procedimento.ch) {valorProc += this.procedimento.ch* this.payload.lote.prestador_operadora_obj.valor_ch}
            if (this.procedimento.co) {valorProc += this.procedimento.co* this.payload.lote.prestador_operadora_obj.valor_co}
            if (this.procedimento.filme) {valorProc += this.procedimento.filme* this.payload.lote.prestador_operadora_obj.valor_filme}
            if (this.procedimento.outros) {valorProc += this.procedimento.outros* this.payload.lote.prestador_operadora_obj.valor_outros}

            this.payload.guiasSpSadt.form.guia.valorProcedimento = valorProc
          }
          
        })
        this.$forceUpdate()
      },
      */

      /*
      async GetTabelas(id) {
        try{
          if(id) {
            let response = await getAPI.get(`/api/tabela/${id}/`, {'silentMode': 1})
            var tabNome = ''
            const idTab = response.data.dominio_tuss
              var tabSel = this.tabelas.find(function(current) {
                
                if(current.id == idTab)
                    return true;
              });
              if(tabSel) {
                tabNome = tabSel.nome
                
              }


            return response.data.dominio_tuss + ' ' + tabNome  
          }       
        }catch(err){
          console.log('guiaConsultaForm.GetTabelas() exception: ', err)
        }    
          

      },
      */
      regimeAtendimentoOnChange(){

      },
      profissionalOnChange() {
        
        if(this.profissional.id) {
          this.$nextTick(() => {
            
            
            this.payload.guiasSpSadt.form.guia.numeroConselhoProfissional= String(this.profissional.numero_conselho)
            this.payload.guiasSpSadt.form.guia.nomeProfissional = this.profissional.nome
            this.conselhoSelecionado = this.conselho[this.profissional.conselho-1].text
            this.payload.guiasSpSadt.form.guia.conselhoProfissional =  this.profissional.conselho
            this.payload.guiasSpSadt.form.guia.CBOS = this.profissional.cbo
            this.payload.guiasSpSadt.form.guia.UFConselhoProfissional = this.profissional.uf_conselho
            const ufprof = this.profissional.uf_conselho
            
            

            var ufsel = this.uf.find(function(current) {
              
              if(current.id == ufprof)
                  return true;
            });
            
            if(ufsel) {
              this.uf_conselho = ufsel.sigla
              
            }
            
            this.$forceUpdate()
            //this.$refs.dataAtendimento.focus()
          })
        }
      },

      /*
      validaData(date) {
        if (!date) {
          return false;
        }

        let today = new Date().toISOString().split('T')[0]

        const day = date.slice(0, 2);
        const month = date.slice(3, 5);
        const year = date.slice(date.length - 4, date.length);
        if(!Date.parse(year+'-' + month + '-'+ day)) {
          return false;
        }

        let dateParsed = year+'-' + month + '-'+ day

        if (dateParsed > today){
          return false;
        }

        return true;
      },
      dataAtendimentoOnChange(){
        
        if (this.dataAtendimentoFormatted) {
          const date = this.dataAtendimentoFormatted;
          
          const day = date.slice(0, 2);
          const month = date.slice(3, 5);
          const year = date.slice(date.length - 4, date.length);
          if(!Date.parse(year+'-' + month + '-'+ day)) {
            this.dataAtendimentoError = ['data inválida']

            //this.$nextTick(() => {
            
              //this.dataAtendimentoFormatted = date.replace('.','')
            //})             
          } 
          else {
            if (!this.validaData(this.dataAtendimentoFormatted)){
              this.dataAtendimentoError = ['data futura não permitida']
            }
            else {
              this.dataAtendimentoError = []
              this.$nextTick(() => {
                this.payload.guiasSpSadt.form.guia.dataAtendimento = `${year}-${month}-${day}` 
                this.dataAtendimentoFormatted = date.replace('.','')
              })   
            }
          }
          
        }
        
        //this.$nextTick(() => {
          
        //  this.dataAtendimentoFormatted = date.replace('.','')
        //})   
        
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month,  year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      */
      save() {

          this.$emit('save');
      },
      setupFields() {

        this.guiaPayload.cols.tipoAtendimento.dominio = []
        for (const tipoAtendimentoObj of this.tipoAtendimento) {

          tipoAtendimentoObj.key = tipoAtendimentoObj.id + ' | ' + tipoAtendimentoObj.text
          this.guiaPayload.cols.tipoAtendimento.dominio.push(tipoAtendimentoObj)
        }
        
        // this.tipoAtendimento.slice(0);

        this.guiaPayload.popUp.visible = true
        
        
        //this.payload.guiasSpSadt.form.GuiaSpSadtFormPop1.visible = true;
        
      },
      setupFieldsClose() {
        this.guiaPayload.popUp.visible= false;
        // set default for invisible fields
        
        Object.keys(this.payload.guiasSpSadt.form.guia).forEach( (key) => {
            if (Object.keys(this.guiaPayload.cols).includes(key)) {
              if(!this.guiaPayload.cols[key].visible) {
                this.payload.guiasSpSadt.form.guia[key] = this.guiaPayload.cols[key].valorDefault
                
              }          

            }
                        
        });

       
        this.saveFieldsSetup();
        
      },
      saveFieldsSetup() {
        let config = []
        Object.keys(this.guiaPayload.cols).forEach( (key) => {
          config[this.guiaPayload.cols[key].id] = [this.guiaPayload.cols[key].visible, this.guiaPayload.cols[key].valorDefault]
                        
        }, this);
        //console.log('config:', config)
        this.$store.dispatch('updateGuiaSadtConfigList', config)       
        
      },
      getFieldSetup() {
        
        let config = this.$store.getters.getGuiaSadtConfigList;
        
          Object.keys(this.guiaPayload.cols).forEach( (key) => {
            if (config.length>this.guiaPayload.cols[key].id) {
              this.guiaPayload.cols[key].visible = config[this.guiaPayload.cols[key].id][0];
              this.guiaPayload.cols[key].valorDefault = config[this.guiaPayload.cols[key].id][1]
            }                            
          });
        
      },
      async saveGuia() {
         
          if (!this.payload.guiasSpSadt.form.guia.numeroGuiaOperadora ||
              !this.payload.guiasSpSadt.form.guia.numeroCarteiraBeneficiario ||
              !this.payload.guiasSpSadt.form.guia.numeroCarteiraBeneficiario || 
              !this.payload.guiasSpSadt.form.guia.nomeBeneficiario ||
              !this.profissional.id
              
              ) {
            this.alert = true;
            return
          }
          
          this.payload.guiasSpSadt.form.guia.numeroGuiaPrestador = this.payload.guiasSpSadt.form.guia.numeroGuiaOperadora 
          if(this.payload.guiasSpSadt.form.guia.numeroCNS){
            this.payload.guiasSpSadt.form.guia.numeroCNS= String(this.payload.guiasSpSadt.form.guia.numeroCNS)
          }
          else {
            this.payload.guiasSpSadt.form.guia.numeroCNS = null
          }

          // procedimentos
          this.payload.guiasSpSadt.form.guia.procedimentos = []
          let sequencialItem = 0
          let procedimentos = JSON.parse(JSON.stringify(this.procedimentosList));
          for (const procedimento of procedimentos){
            
            if (!procedimento.dataExecucaoFormatada) {
              continue
            }
            sequencialItem++;
            procedimento.sequencialItem = sequencialItem;

            // TBD default when not in screen
            procedimento.horaInicial = null; // optional '00:00:00'
            procedimento.horaFinal= null;    // optional '00:00:00'
            // procedimento.tecnicaUtilizada = '1';
            procedimento.codigoTabela = '22';

            const [day, month, year] = procedimento.dataExecucaoFormatada.split('/'); // on screen DD/MM/YYYY

            if(Date.parse(year+'-' + month + '-'+ day)) {
              procedimento.dataExecucao = `${year}-${month}-${day}`
            } else {
              procedimento.dataExecucao = null
            }
            delete procedimento.dataExecucaoFormatada
            delete procedimento.procedimentos

            procedimento.procedimento_id = procedimento.procedimento.id;
            [procedimento.codigoProcedimento, procedimento.descricaoProcedimento] = procedimento.procedimento.text.split(' | ');
            delete procedimento.procedimento;

            if (!procedimento.viaAcesso) {
              procedimento.viaAcesso = this.guiaPayload.cols.viaAcesso.valorDefault
            }
            if(!procedimento.reducaoAcrescimo) {
              procedimento.reducaoAcrescimo = this.guiaPayload.cols.reducaoAcrescimo.valorDefault
            }
            if(!procedimento.tecnicaUtilizada) {
              procedimento.tecnicaUtilizada = this.guiaPayload.cols.tecnicaUtilizada.valorDefault
            }

            procedimento.profissionais = [];
            for(const profissionalTmp of procedimento.equipeSadt) {
              const profissional_id =profissionalTmp.profissional.id;
              if(!profissional_id) {
                break;
              }
              let profissionalDetalhes = this.profissionais.find( function(current) {
                
                if(current.id == profissional_id) {
                    return true;
                }

              });

              if(profissionalDetalhes) {
                
                const profissional = {
                  grauPart : profissionalTmp.grauParticipacao , 

                  profissional_id: profissional_id,
                  conselho : profissionalDetalhes.conselho , 
                  numeroConselhoProfissional : profissionalDetalhes.numero_conselho,
                  nomeProf : profissionalDetalhes.nome,
                  UF : profissionalDetalhes.uf_conselho,
                  cpfContratado : profissionalDetalhes.cpf,
                  CBOS : profissionalDetalhes.cbo,
                  codigoPrestadorNaOperadora : null  // TBD 

                }
                
                procedimento.profissionais.push(profissional);
                
              }            

            }
            delete procedimento.equipeSadt;

            
            this.payload.guiasSpSadt.form.guia.procedimentos.push(procedimento)

          } 
          
          try{
              if(this.payload.guiasSpSadt.form.guia.id) {
                console.log('PUT')
                await getAPI.put(`/api/guia-sp-sadt/${this.payload.guiasSpSadt.form.guia.id}/`, JSON.stringify(this.payload.guiasSpSadt.form.guia))
                this.payload.guiasSpSadt.form.visible = false
              }
              else {
                console.log('POST')
                await this.fillHiddenFields()
                
                await getAPI.post('/api/guia-sp-sadt/', JSON.stringify(this.payload.guiasSpSadt.form.guia))

                // reset
                this.payload.guiasSpSadt.form.guia = {}
                this.defaultFieldValues()
                await this.fillHiddenFields()
                if (this.$refs['guiaForm']) {
                  //this.$refs.form.reset();
                  this.$refs.guiaForm.resetValidation()
                }
                
                this.$forceUpdate()            
                this.$refs.startField.focus()
              }
          }catch(err){
              console.log('guiaSpSadtForm.saveGuia() exception: ', err,this.payload.guiasSpSadt.form.guia)
          }            
          
      },
      defaultFieldValues() {
        this.payload.guiasSpSadt.form.guia.lote = this.payload.lote.id  //numero_lote
        this.payload.guiasSpSadt.form.guia.indicacaoAcidente = 9 // {id: 9}
        this.payload.guiasSpSadt.form.guia.atendimentoRN = 'N'
        this.payload.guiasSpSadt.form.guia.tipoConsulta = 1
        this.payload.guiasSpSadt.form.guia.numeroCNS = null
        this.payload.guiasSpSadt.form.guia.observacao = null
        this.payload.guiasSpSadt.form.guia.indicacaoClinica = null
        this.payload.guiasSpSadt.form.guia.regimeAtendimento = '01'
        this.payload.guiasSpSadt.form.guia.saudeOcupacional = null
        this.payload.guiasSpSadt.form.guia.coberturaEspecial = null
        this.payload.guiasSpSadt.form.guia.caraterAtendimento = 1
        this.payload.guiasSpSadt.form.guia.motivoEncerramento = 0
        
        // tipoAtendimento =models.CharField(max_length=2) # 01 Remoção, 02 Pequena Cirurgia, 03 Terapias, 04 Consulta, 05	Exames (englobando exame radiológico), 06	Atendimento Domiciliar  ...
        this.payload.guiasSpSadt.form.guia.tipoAtendimento = '04'

        this.tabela = null
        this.profissional = {id: null, nome: ''};
        this.procedimento = {id: null, text: ''};
        this.dataAtendimentoFormatted = null;

        this.setupFieldsClose()  
        this.procedimentosList = [];
        this.procedimentosList.push( JSON.parse(JSON.stringify(this.templateProcedimento)));

        
        
      },

      fillHiddenFields() {
        /*
        if(this.tabela) {
          this.payload.guiasSpSadt.form.guia.codigoTabela = parseInt(this.tabela.substring(0, this.tabela.indexOf(' ')  ) , 10)
        }
        */
        this.payload.guiasSpSadt.form.guia.RegistroANS = String(this.payload.lote.operadora_registro_ans)
        this.payload.guiasSpSadt.form.guia.nomeContratado =  this.payload.lote.prestador_nomeContratado
        this.payload.guiasSpSadt.form.guia.CNES = String(this.payload.lote.prestador_cnes)
        //this.payload.guiasSpSadt.form.guia.tipoConsulta= String(this.payload.guiasSpSadt.form.guia.tipoConsulta)
        //this.payload.guiasSpSadt.form.guia.indicacaoAcidente= String(this.payload.guiasSpSadt.form.guia.indicacaoAcidente)
        if(this.payload.lote.codigo_prestador_na_operadora) {
            this.payload.guiasSpSadt.form.guia.codigoPrestadorNaOperadora = this.payload.lote.codigo_prestador_na_operadora
        }
        else {
            this.payload.guiasSpSadt.form.guia.codigoPrestadorNaOperadora = null  // xml will fill  cnpjContratado with this.payload.lote.prestador_cnpj
        }

      },
      async GetProfissional() {
        
        let response = await getAPI.get(`/api/profissionais/?prestador=${this.$store.state.idPrestadorSelecionado}`)
        try {
          this.profissionais= response.data;
          this.profissionais.forEach(element => element.key = element.numero_conselho + ' | ' + element.nome);
          
        }
        catch(err) {
            console.log('guiaSpSadtForm.GetProfissional() exception: ',err)
        }


      },
      


  },
  /*
  computed: {
    dialog: {
      get () {
        return this.payload.guiasSpSadt.form.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    },
    */
    /*
    numeroGuiaOperadora: {
      get() {
        return this.payload.guiasSpSadt.form.guia.numeroGuiaOperadora;
      },
      set(val) {
        this.payload.guiasSpSadt.form.guia.numeroGuiaOperadora = '';
        this.$nextTick(() => {
          this.payload.guiasSpSadt.form.guia.numeroGuiaOperadora = val.replace('.', '');
        });
      }
    }
    */
  //}

}

/*

table.v-table tbody td {
    height: 40px;
    border: none;
}
.theme--light.v-table tbody tr:not(:last-child) {
    border: none;
}
*/
</script>


<style scoped>
.inputNumberNoSpin >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputNumberNoSpin >>> input::-webkit-outer-spin-button,
.inputNumberNoSpin >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.input-upper-case input{
  text-transform: uppercase
}

.topics tr { height: 5px; border-spacing: 0px; border-collapse: collapse; padding-top: 0; }
tr:nth-child(4) td {
    padding-top: 0;
}
/*
th {
    border-bottom: thin solid grey;
}
*/*
 .table td {
                /*border-right: 1px solid grey;
                border-bottom: none;*/
                border: 1px solid lightgrey;
                margin: 0;
                padding: 0;
                border-spacing: 0px; 
                border-collapse: collapse
            }
</style>
